import { pick } from 'lodash';
import { useTranslation } from 'react-i18next';

import { AutoComplete } from '@openx/components/core';
import { GeographicOption } from '@openx/types';

import { useCustomOptionsContext } from '../../../utils';

type LocationTypeProps = {
  onChange: (type: GeographicOption) => void;
  value: GeographicOption;
};

export const LocationType = (props: LocationTypeProps) => {
  const { onChange, value } = props;
  const { t } = useTranslation();
  const customOptions = useCustomOptionsContext<GeographicOption>();

  let labels: Record<string, string> = {
    [GeographicOption.BOUNDING_BOX]: t('Bounding Box'),
    [GeographicOption.INCLUDES]: t('Location'),
    [GeographicOption.CIRCLES]: t('Location Radius'),
    [GeographicOption.SOURCE]: t('Location Source'),
  };

  if (customOptions) {
    labels = pick(labels, customOptions.availableOptions);
  }

  return (
    <AutoComplete
      textFieldProps={{ label: t('Location Type') }}
      options={Object.keys(labels)}
      renderOptions={{
        dataTest: 'location-type-option',
      }}
      getOptionLabel={option => labels[option]}
      textFieldReadOnly
      disableClearable
      filterOptions={option => option}
      onChange={(_, value) => onChange(value as GeographicOption)}
      value={value}
      data-test="location-type"
    />
  );
};
