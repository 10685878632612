export const Criteria = {
  ALL: 'AND',
  ANY: 'OR',
} as const;

export type CriteriaValue = (typeof Criteria)[keyof typeof Criteria];

export enum ComparisonType {
  EQUALS = '==',
  DOES_NOT_EQUAL = '!=',
  ENDS_WITH = '=$',
  CONTAINS = '=?',
  BEGINS_WITH = '=^',
  DOES_NOT_END_WITH = 'NOT =$',
  DOES_NOT_CONTAIN = 'NOT =?',
  DOES_NOT_BEGIN_WITH = 'NOT =^',
  LESS_THAN = '<',
  LESS_THAN_OR_EQUAL_TO = '<=',
  GREATER_THAN = '>',
  GREATER_THAN_OR_EQUAL_TO = '>=',
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
  INTERSECTS = 'INTERSECTS',
  INVERSE_IN = 'INVERSE IN',
  EQ = 'EQ',
  NOT_INTERSECTS = 'NOT INTERSECTS',
}

export enum Intersect {
  INTERSECTS = 'INTERSECTS',
  NOT_INTERSECTS = 'NOT INTERSECTS',
}

export type ComparisonList = { op: ComparisonType; val: string }[];
export type ComparisonMap = {
  [K in ComparisonType]?: string[];
};

export type OptionEntriesProps = [string, string[]];
