export enum ColumnKey {
  NAME = 'name',
  DEVICE_TYPE = 'device_type',
  DISTRIBUTION_CHANNEL = 'distribution_channel',
  AD_PLACEMENT = 'ad_placement',
}

export enum ColumnTitle {
  NAME = 'Name',
  DEVICE_TYPE = 'Device Type',
  DISTRIBUTION_CHANNEL = 'Distribution Channel',
  AD_PLACEMENT = 'Ad Placement',
}
