import { HighlightPhraseProps } from '@openx/components/core/HighlightedPhrase';

export interface HighlightRule {
  fields: string[];
  phrase?: HighlightPhraseProps;
}

export type HighlightRules = HighlightRule | HighlightRule[];

export function findHighlightPhraseForField(rules: HighlightRules, field: string): HighlightPhraseProps | undefined {
  const rulesArray = Array.isArray(rules) ? rules : [rules];

  const matchingDefinition = rulesArray.find(definition => definition.fields.includes(field));

  return matchingDefinition && matchingDefinition.phrase;
}
