import { ComparisonType, Intersect, TechnologyAndDevicesOption } from '@openx/types';

export const CookiesOptions = {
  accepted: '==',
  notAccepted: '!=',
} as const;

export const DeviceIdOptions = {
  enabled: '==',
  limited: '!=',
} as const;

export const HttpsOptions = {
  notServed: '!=',
  served: '==',
} as const;

export const MobileCarrierOptions = {
  isAnyOf: Intersect.INTERSECTS,
  isNotAnyOf: Intersect.NOT_INTERSECTS,
  isWifiOnly: 'NO_VALUE',
} as const;

export type MobileCarrierOption = (typeof MobileCarrierOptions)[keyof typeof MobileCarrierOptions];

export const MobileCarrierWifiOption = {
  wifi: 'WIFI',
} as const;

export const optionsToFetch = {
  [TechnologyAndDevicesOption.API_FRAMEWORK]: true,
  [TechnologyAndDevicesOption.BROWSER]: true,
  [TechnologyAndDevicesOption.CONNECTION_SPEED]: true,
  [TechnologyAndDevicesOption.CONNECTION_TYPE]: true,
  [TechnologyAndDevicesOption.DEVICE]: true,
  [TechnologyAndDevicesOption.DEVICE_TYPE]: true,
  [TechnologyAndDevicesOption.MOBILE_CARRIER]: true,
  [TechnologyAndDevicesOption.LANGUAGE]: true,
  [TechnologyAndDevicesOption.OS]: true,
  [TechnologyAndDevicesOption.SCREEN_RESOLUTION]: true,
};

export const comparisonOptions = [
  ComparisonType.EQUALS,
  ComparisonType.DOES_NOT_EQUAL,
  ComparisonType.CONTAINS,
  ComparisonType.DOES_NOT_CONTAIN,
  ComparisonType.BEGINS_WITH,
  ComparisonType.DOES_NOT_BEGIN_WITH,
  ComparisonType.ENDS_WITH,
  ComparisonType.DOES_NOT_END_WITH,
];

export const technographicDefaultOptions = {
  availableOptions: [
    TechnologyAndDevicesOption.API_FRAMEWORK,
    TechnologyAndDevicesOption.BROWSER,
    TechnologyAndDevicesOption.DEVICE_TYPE,
    TechnologyAndDevicesOption.LANGUAGE,
    TechnologyAndDevicesOption.OS,
  ],
  defaultOption: TechnologyAndDevicesOption.DEVICE_TYPE,
};
