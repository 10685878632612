import { has, isEmpty, omit } from 'lodash';

import {
  type ContentObjectApi,
  type ContentObjectProps,
  ContentObjectOptions,
  Intersect,
  type AllowBlockApi,
  type Option,
  Criteria,
  ContentObjectOption,
  OptionsMap,
  AllowBlockFormState,
} from '@openx/types';

import { apiUnmatchedOption, stateUnmatchedOption } from '../constants';
import { mapEpisodeApiValuesToState } from '../ContentObjectTopBox/OptionDropdowns/Episode';
import { getChildren, prepareOptionsForHierarchy } from '../ContentObjectTopBox/OptionDropdowns/Hierarchy/utils';

type OptionItem = { loading: boolean; options: OptionsMap };
export const isOptionItem = (item: boolean | OptionItem | undefined): item is OptionItem => {
  return typeof item === 'object' && 'loading' in item && 'options' in item;
};

export const isDropdown = (type: ContentObjectOption): type is 'language' | 'contentrating' => {
  return type === ContentObjectOptions.LANGUAGE || type === ContentObjectOptions.CONTENT_RATING;
};

export const isStatic = (type: ContentObjectOption): type is 'livestream' => {
  return type === ContentObjectOptions.LIVESTREAM;
};

export const isFreeText = (type: ContentObjectOption): type is 'network' | 'channel' | 'series' => {
  return (
    type === ContentObjectOptions.NETWORK ||
    type === ContentObjectOptions.CHANNEL ||
    type === ContentObjectOptions.SERIES
  );
};

export const isEpisode = (type: ContentObjectOption): type is 'episode' => {
  return type === ContentObjectOptions.EPISODE;
};

export const isGenre = (type: ContentObjectOption): type is 'genre' => {
  return type === ContentObjectOptions.GENRE;
};

const getDefaultParams = (): ContentObjectProps => ({
  [ContentObjectOptions.GENRE]: { allow: [], apiAllow: [], apiBlock: [], block: [] },
  [ContentObjectOptions.CONTENT_RATING]: { op: Intersect.INTERSECTS, val: new Set<string>() },
  [ContentObjectOptions.LIVESTREAM]: null,
  [ContentObjectOptions.LENGTH]: null,
  [ContentObjectOptions.LANGUAGE]: { op: Intersect.INTERSECTS, val: new Set<string>() },
  [ContentObjectOptions.SERIES]: { op: Intersect.INTERSECTS, val: new Set<string>() },
  [ContentObjectOptions.EPISODE]: { op: Intersect.INTERSECTS, val: [] },
  [ContentObjectOptions.CHANNEL]: { op: Intersect.INTERSECTS, val: new Set<string>() },
  [ContentObjectOptions.NETWORK]: { op: Intersect.INTERSECTS, val: new Set<string>() },
  [ContentObjectOptions.CONTENT_INTER_DIMENSION_OPERATOR]: Criteria.ALL,
});

export function mapGenreToFormState(leveledOptionsMap: OptionsMap, genreValues: AllowBlockFormState) {
  const { allow, block } = genreValues ?? {};

  if (isEmpty(leveledOptionsMap)) return genreValues;

  const allowWithChildren = appendChildren(allow, leveledOptionsMap);
  const blockWithChildren = appendChildren(block, leveledOptionsMap);

  blockWithChildren.forEach(item => {
    const repeatedItemIdx = allowWithChildren.indexOf(item);

    if (repeatedItemIdx !== -1) {
      allowWithChildren.splice(repeatedItemIdx, 1);
    }
  });

  return {
    allow: allowWithChildren,
    block: blockWithChildren,
  };
}

export const appendChildren = (options: string[], optionsMap: OptionsMap): string[] => {
  const getOptionId = (optionName: string) =>
    String(
      Object.keys(optionsMap)
        .map(key => optionsMap[key])
        .find(hierarchyOption => hierarchyOption.name === optionName)?.id
    ) || null;

  return [
    ...new Set(
      (options ?? [])
        .map(option => {
          const optionId = getOptionId(option);
          return optionId ? getChildren(optionsMap, optionId) : [option];
        })
        .flatMap(x => x)
    ),
  ];
};

export function mapContentObjectToFormState(
  contentObject: ContentObjectApi | null,
  options?: OptionsMap
): ContentObjectProps {
  const state = getDefaultParams();

  Object.values(ContentObjectOptions).forEach(key => {
    switch (key) {
      case ContentObjectOptions.CONTENT_INTER_DIMENSION_OPERATOR:
        state[key] = contentObject?.[key] || Criteria.ALL;
        break;

      case ContentObjectOptions.CONTENT_RATING:
      case ContentObjectOptions.LANGUAGE:
      case ContentObjectOptions.SERIES:
        state[key] = contentObject?.[key]
          ? { op: contentObject[key]?.op as Intersect, val: new Set(contentObject[key]?.val) }
          : { op: Intersect.INTERSECTS, val: new Set<string>() };
        break;

      case ContentObjectOptions.LIVESTREAM:
        if (!contentObject?.[key]) {
          state[key] = null;
          break;
        }

        state[key] = contentObject?.[key] === '1' ? { id: '1', name: 'True' } : { id: '0', name: 'False' };
        break;

      case ContentObjectOptions.GENRE:
        if (isEmpty(options)) {
          state[key] = {
            allow: contentObject?.[key]?.allow ?? [],
            block: contentObject?.[key]?.block ?? [],
          };
          break;
        }

        state[key] = mapGenreToFormState(
          prepareOptionsForHierarchy(options).leveledOptionsMap,
          contentObject?.[key] as AllowBlockFormState
        );
        break;

      case ContentObjectOptions.EPISODE:
        state[key] = contentObject?.[key]
          ? {
              op: contentObject[key]?.op as Intersect,
              val: mapEpisodeApiValuesToState(contentObject[key]?.val),
            }
          : { op: Intersect.INTERSECTS, val: [] };
        break;

      case ContentObjectOptions.NETWORK:
      case ContentObjectOptions.CHANNEL:
        state[key] = contentObject?.[key]
          ? {
              op: contentObject[key]?.name.op as Intersect,
              val: new Set(contentObject[key]?.name.val),
            }
          : { op: Intersect.INTERSECTS, val: new Set<string>() };
        break;

      case ContentObjectOptions.LENGTH:
      default:
        state[key] = null;
        break;
    }
  });

  return state;
}

export const mapContentObjectStateToApi = (formState: ContentObjectProps): ContentObjectApi | null => {
  const apiValues = {} as ContentObjectApi;
  const filteredFormState = {
    ...filterEmptyParams(formState),
    [ContentObjectOptions.CONTENT_INTER_DIMENSION_OPERATOR]:
      formState[ContentObjectOptions.CONTENT_INTER_DIMENSION_OPERATOR],
  };
  const filteredKeys = Object.keys(filteredFormState);

  if (
    !formState ||
    filteredKeys.length === 0 ||
    isEmpty(omit(filteredFormState, ContentObjectOptions.CONTENT_INTER_DIMENSION_OPERATOR))
  ) {
    return null;
  }

  filteredKeys.forEach(key => {
    switch (key) {
      case ContentObjectOptions.CONTENT_INTER_DIMENSION_OPERATOR:
        apiValues[key] = filteredFormState[key];
        break;

      case ContentObjectOptions.CONTENT_RATING:
      case ContentObjectOptions.LANGUAGE:
      case ContentObjectOptions.SERIES:
        apiValues[key] = {
          op: filteredFormState[key]?.op,
          val: [...(filteredFormState[key]?.val || [])],
        };
        break;

      case ContentObjectOptions.LIVESTREAM:
        apiValues[key] = filteredFormState[key]?.name === 'True' ? '1' : '0';
        break;

      case ContentObjectOptions.GENRE:
        apiValues[key] = Object.keys(filteredFormState[key]).reduce((acc, allowBlockKey) => {
          if (filteredFormState?.[key]?.[allowBlockKey]?.length > 0) {
            acc[allowBlockKey] = filteredFormState[key][allowBlockKey];
          }

          return acc;
        }, {} as AllowBlockApi);

        break;

      case ContentObjectOptions.NETWORK:
      case ContentObjectOptions.CHANNEL:
        apiValues[key] = {
          name: {
            op: filteredFormState[key]?.op,
            val: [...(filteredFormState[key]?.val || [])],
          },
        };
        break;

      case ContentObjectOptions.EPISODE:
        apiValues[key] = {
          op: filteredFormState[key]?.op,
          val: filteredFormState[key]?.val
            .reduce((acc, key) => [...acc, ...key.eps], [] as number[])
            .map(ep => ep.toString()),
        };
        break;

      case ContentObjectOptions.LENGTH:
      default:
        break;
    }
  });

  return apiValues;
};

export const filterEmptyParams = (targeting: ContentObjectProps): ContentObjectProps => {
  return Object.keys(targeting).reduce((acc, key) => {
    if (targeting[key] && typeof targeting[key] === 'object') {
      const isValueNotEmpty = !isEmpty(targeting[key]?.val);
      const isOption = has(targeting[key], 'id') && has(targeting[key], 'name');
      const isAllowBlockType =
        (has(targeting[key], 'allow') || has(targeting[key], 'block')) &&
        (targeting[key]?.allow.length > 0 || targeting[key]?.block.length > 0);

      if (isOption || isValueNotEmpty || isAllowBlockType) {
        acc[key] = targeting[key];
      }
    }

    return acc;
  }, {} as ContentObjectProps);
};

const sortUnmatchedOption = (keys: string[], option: Option): void => {
  const optionIndex = keys.indexOf(option.name);

  if (optionIndex !== -1) {
    keys.splice(optionIndex, 1);
    keys.unshift(option.name);
  }
};

export const sortGenreOptions = (
  options: Record<
    string,
    {
      name: string;
    }
  >
): string[] => {
  const keys = Object.keys(options).sort((a, b) =>
    (options[a]?.name.toLowerCase() || '') > (options[b]?.name.toLowerCase() || '') ? 1 : -1
  );

  sortUnmatchedOption(keys, apiUnmatchedOption);
  return keys;
};

export const sortGenreItems = (items: string[]): string[] => {
  sortUnmatchedOption(items, stateUnmatchedOption);
  return items;
};
