import { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoCompleteVirtualize, Ellipsis, HighlightedPhrase } from '@openx/components/core';
import { GeoLocation, GeoLocationTypes, GeoLocationsList, GeographicTargetingState } from '@openx/types';
import { getGeoLocationDisplayName } from '@openx/utils/state/geoLocationSearch';

import { useTargetingContext } from '../../../utils/context';
import { geographicPlaceholder } from '../constants';
import { useExtractAudienceExportType } from '../hooks';
import { convertGeoObjToArray } from '../utils';

type GeographicAutoCompleteProps = {
  targetingParams: GeographicTargetingState;
  onSelect: (item: GeoLocation) => void;
  excludeArea?: GeoLocationsList;
  includeArea?: GeoLocationsList;
  inputLabel?: string;
  geographicType?: GeoLocationTypes;
  excludeSubset?: boolean;
};

export const GeographicAutoComplete = ({
  targetingParams,
  onSelect,
  excludeArea = [],
  includeArea = [],
  inputLabel = '',
  geographicType,
  excludeSubset = false,
}: GeographicAutoCompleteProps) => {
  const { t } = useTranslation();
  const [inputPhrase, setInputPhrase] = useState('');
  const [searchPhrase, setSearchPhrase] = useState('');

  const { useFetchBySearchText } = useTargetingContext();
  const { useAudienceExportType } = useExtractAudienceExportType();

  const { isUsOnly: isAudienceUsOnly } = useAudienceExportType();

  const { isLoading, geoSearchData = [] } = useFetchBySearchText({
    excludeArea,
    geographicType,
    includeArea,
    searchText: searchPhrase,
  });

  const selectedLocationsIds = useMemo(() => {
    const includesArray = convertGeoObjToArray(targetingParams.includes) || [];
    const excludesArray = convertGeoObjToArray(targetingParams.excludes) || [];
    const excludesSubsetArray = targetingParams.excludes_subset || [];

    return new Set([
      ...includesArray.map(item => item.id),
      ...excludesArray.map(item => item.id),
      ...excludesSubsetArray.map(item => item.id),
    ]);
  }, [targetingParams.includes, targetingParams.excludes, targetingParams.excludes_subset]);

  const filterLocationOptions = useCallback(() => {
    return geoSearchData.filter(option => {
      return (
        !selectedLocationsIds.has(option.id) &&
        getGeoLocationDisplayName(option).toLowerCase().includes(searchPhrase.toLowerCase())
      );
    });
  }, [geoSearchData, searchPhrase, selectedLocationsIds]);

  const noOptionsText = useCallback(() => {
    if (!searchPhrase || isLoading) {
      return t('Type to search');
    }
    return t('Nothing found');
  }, [searchPhrase, isLoading, t]);

  const onLocationAdd = useCallback(
    (e, item) => {
      if (item) {
        onSelect(item);
      }
    },
    [onSelect]
  );

  const onLocationClose = useCallback(() => {
    setSearchPhrase('');
    setInputPhrase('');
  }, []);

  const renderOption = useCallback(
    (state, option: GeoLocation) => (
      <li {...state} key={option.id}>
        <Ellipsis tooltip data-test="geo-location-option" width="100%">
          <HighlightedPhrase searchPhrase={searchPhrase}>
            {getGeoLocationDisplayName(option, excludeSubset)}
          </HighlightedPhrase>
        </Ellipsis>
      </li>
    ),
    [excludeSubset, searchPhrase]
  );

  const getOptionLabel = useCallback((option: GeoLocation) => getGeoLocationDisplayName(option) || '', []);
  const onDebounceChange = useCallback(({ target }) => setSearchPhrase(target.value), []);
  const onInputChange = useCallback(
    (e, value, reason) => (reason === 'input' || reason === 'clear') && setInputPhrase(value),
    []
  );

  return (
    <AutoCompleteVirtualize
      options={geoSearchData}
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
      onDebounceChange={onDebounceChange}
      onInputChange={onInputChange}
      debounced
      textFieldProps={{
        label: inputLabel,
        placeholder: excludeSubset
          ? t('Enter country, state/region, city or postal code')
          : geographicType && geographicPlaceholder[geographicType],
      }}
      onChange={onLocationAdd}
      onClose={onLocationClose}
      disableCloseOnSelect
      filterOptions={filterLocationOptions}
      getOptionDisabled={option =>
        isAudienceUsOnly && !option.name.includes('united states') && option.country !== 'united states'
      }
      loading={isLoading}
      disableClearable
      noOptionsText={noOptionsText()}
      inputValue={inputPhrase}
      value={{} as GeoLocation} // keep empty object as selected so Autocomplete will not have in internal state previous value as selected
    />
  );
};
