import { useTranslation } from 'react-i18next';
import { number, object } from 'yup';

import { NumberFilterFields } from './types';

const emptyToNull = (value, originalValue) => (String(originalValue).trim() === '' ? null : value);

export const useNumberFilterValidationSchema = (min: number, max: number, maxFractionDigits) => {
  const { t } = useTranslation();

  return object().shape({
    [NumberFilterFields.VALUE]: number()
      .nullable()
      .transform(emptyToNull)
      .typeError(t('Entered value must be a number.'))
      .min(min, t('Entered value must be greater than or equal to {min}.', { min }))
      .max(max, t('Entered value must be less than or equal to {max}.', { max }))
      .test(
        'maxDigitsAfterDecimal',
        t('Price can have up to {maxFractionDigits} decimal places.', { maxFractionDigits }),
        number => {
          if (!number) {
            return true;
          }
          number = Math.abs(number);
          const regex = `^\\d+(\\.\\d{0,${maxFractionDigits}})?$`;
          const r = new RegExp(regex, 'i');
          return !!number.toString().match(r);
        }
      ),
    [NumberFilterFields.VALUE_TO]: number()
      .nullable()
      .transform(emptyToNull)
      .typeError(t('Entered value must be a number.'))
      .min(min, t('Entered value must be greater than or equal to {min}.', { min }))
      .max(max, t('Entered value must be less than or equal to {max}.', { max }))
      .test(
        'maxDigitsAfterDecimal',
        t('Price can have up to {maxFractionDigits} decimal places.', { maxFractionDigits }),
        number => {
          if (!number) {
            return true;
          }
          number = Math.abs(number);
          const regex = `^\\d+(\\.\\d{0,${maxFractionDigits}})?$`;
          const r = new RegExp(regex, 'i');
          return !!number.toString().match(r);
        }
      )
      .when(NumberFilterFields.VALUE, (val, schema) => {
        return schema.moreThan(val, t('Entered value must be greater than from value'));
      }),
  });
};
