import { styled } from '@mui/material/styles';

export const StyledBoxContainer = styled('div', { shouldForwardProp: prop => prop !== 'isSubsection' })<{
  isSubsection?: boolean;
}>`
  background-color: ${({ theme, isSubsection }) => (isSubsection ? theme.palette.error.light : theme.palette.divider)};
  height: 1px;
  width: 100%;
  margin: ${({ theme }) => theme.spacing(3)} auto;
  text-align: center;

  & > *:first-of-type {
    transform: translateY(-50%);
  }
`;
