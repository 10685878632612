import { useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import type { AclFlag, AclMapping, EnrichedAccount, InstanceType, RealUser, User } from '@openx/types';

import { SessionState } from '../state/session/types';

export interface PermissionsCheckParams {
  accessUser?: User;
  accessAccount?: EnrichedAccount;
  aclMapping?: AclMapping;
  userAcl?: AclFlag[];
  accountAcl?: AclFlag[];
  aclList?: AclFlag[];
  instance?: InstanceType;
  realUser?: RealUser;
}

export type PermissionsCheck = (params: PermissionsCheckParams) => boolean;
export type IsAllowedCheck = (check: PermissionsCheck) => boolean;
export type UseIsAllowedReturnType = PermissionsCheckParams & {
  isAllowed: IsAllowedCheck;
  aclList: AclFlag[];
  aclMapping: AclMapping;
};

interface StateType {
  session: SessionState;
}

export function usePermissionsCheckParams(): PermissionsCheckParams {
  return useSelector<StateType, PermissionsCheckParams>(
    state => ({
      accessAccount: state.session.details?.account,
      accessUser: state.session.details?.user,
      accountAcl: state.session.acl?.account,
      aclList: state.session.acl?.list,
      aclMapping: state.session.acl?.mapping,
      instance: state.session.details?.instance,
      realUser: state.session.details?.real_user,
      userAcl: state.session.acl?.user,
    }),
    shallowEqual
  );
}

export function useIsAllowed(): UseIsAllowedReturnType {
  const params = usePermissionsCheckParams();

  const isAllowed = useCallback((check: PermissionsCheck): boolean => check(params), [params]);

  return {
    ...params,
    aclList: params.aclList ?? [],
    aclMapping: params.aclMapping ?? {},
    isAllowed,
  };
}
