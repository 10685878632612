import { Box } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { GeographicOption, Intersect, type GeographicTargetingState, OptionsMap } from '@openx/types';

import { useTargetingContext } from '../../../utils';
import { SelectedItems, Switch, FiltersTitle } from '../../shared';
import { getEmptyLocationSourceState } from '../utils';

type LocationSourceItemsProps = {
  onChange: (newData: GeographicTargetingState) => void;
  readonly: boolean;
  targetingParams: GeographicTargetingState;
};

export const LocationSourceItems = ({ onChange, readonly, targetingParams }: LocationSourceItemsProps) => {
  const { useLocationSourceOptionsFetch } = useTargetingContext();

  if (useLocationSourceOptionsFetch) {
    return (
      <RenderLocationSourceItems
        onChange={onChange}
        readonly={readonly}
        targetingParams={targetingParams}
        useLocationSourceOptionsFetch={useLocationSourceOptionsFetch}
      />
    );
  }

  return null;
};

const RenderLocationSourceItems = ({
  onChange,
  readonly,
  targetingParams,
  useLocationSourceOptionsFetch,
}: LocationSourceItemsProps & {
  useLocationSourceOptionsFetch: () => { isLoading: boolean; locationSourceOptions: OptionsMap };
}) => {
  const { t } = useTranslation();
  const { location_source } = targetingParams;
  const locationSourceCount = location_source.val.size;
  const locationSourceSwitchState = location_source.op === Intersect.NOT_INTERSECTS;
  const locationSourceTypeLabel = locationSourceSwitchState ? t('Excluded') : t('Included');
  const locationSourceFiltersTitle = locationSourceCount ? locationSourceTypeLabel : '';

  const selectedItems = useMemo(() => [...location_source.val], [location_source]);
  const { locationSourceOptions } = useLocationSourceOptionsFetch();

  const onLocationSourceClear = useCallback(() => {
    onChange({ ...targetingParams, [GeographicOption.SOURCE]: getEmptyLocationSourceState() });
  }, [onChange, targetingParams]);

  const onLocationSourceRemove = useCallback(
    (values: string[]) => {
      const selected = new Set(values);
      const op = targetingParams[GeographicOption.SOURCE].op;

      onChange({
        ...targetingParams,
        [GeographicOption.SOURCE]: { op, val: selected },
      });
    },
    [targetingParams, onChange]
  );

  const handleLocationSourceSwitchChange = useCallback(() => {
    const val = targetingParams[GeographicOption.SOURCE].val;
    const op = !locationSourceSwitchState ? Intersect.NOT_INTERSECTS : Intersect.INTERSECTS;

    onChange({
      ...targetingParams,
      [GeographicOption.SOURCE]: { op, val },
    });
  }, [targetingParams, locationSourceSwitchState, onChange]);

  return (
    <Box data-test="sub-section">
      <FiltersTitle
        onClear={onLocationSourceClear}
        title={t('Location Source [ {count} ]', { count: locationSourceCount })}
        readonly={readonly}
      />

      {!readonly && (
        <Switch
          switchState={locationSourceSwitchState}
          onSwitchChange={() => handleLocationSourceSwitchChange()}
          switchOffLabel={t('include')}
          switchOnLabel={t('exclude')}
          groupTitle={t('the following:')}
        />
      )}

      <SelectedItems
        filtersTitle={locationSourceFiltersTitle}
        isAddIcon={!locationSourceSwitchState}
        selectedItems={selectedItems}
        readonly={readonly}
        handleChange={onLocationSourceRemove}
        isRemovingAll
        getItemLabel={item => locationSourceOptions[item]?.name || item}
        dataTest="location-source"
      />
    </Box>
  );
};
