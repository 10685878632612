import { useQuery } from '@tanstack/react-query';

import { QueryKeys } from '@openx/utils/api';
import { searchInventoriesMarket } from '@openx/utils/state/packages';

const DEFAULT_MARKET = { name: 'All Markets' };
const DEFAULT_MARKETS = [DEFAULT_MARKET];

type MarketProps = {
  name: string;
  id?: string;
};

export const useFetchInventoryMarket = () => {
  const { data } = useQuery<MarketProps[]>({
    meta: {
      errorMessage: 'Failed to fetch markets',
    },
    queryFn: async () => {
      const response = await searchInventoriesMarket();
      if (response.length === 0) {
        return DEFAULT_MARKETS;
      }

      return [DEFAULT_MARKET, ...response];
    },
    queryKey: [QueryKeys.MARKET_OPERATORS],
  });

  return {
    markets: data || DEFAULT_MARKETS,
  };
};
