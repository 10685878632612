import { Grid, styled } from '@mui/material';
import { useState, type KeyboardEvent } from 'react';

import { TextField } from '@openx/components/core';
import type { ContentObjectAction, ContentObjectOption, ContentObjectProps } from '@openx/types';

import { getOptionName, inputPlaceholder } from '../../constants';

import { validateTextField } from './../utils';

const StyledTextField = styled(TextField)`
  & .MuiInputBase-input {
    height: 34px;
  }
`;

type Field = {
  value: string;
  error?: string;
};

export const FreeTextOptions = (props: {
  dispatch: (action: ContentObjectAction) => void;

  formState: Pick<ContentObjectProps, 'network' | 'channel' | 'series'>;
  type: ContentObjectOption;
}) => {
  const { dispatch, formState, type } = props;

  const selectedItems = formState[type]?.val || new Set<string>();

  const [field, setField] = useState<Field>({ value: '' });
  const isValidInput = !field.error && !!field.value;

  const handleChange = (value: string) => {
    const { error } = validateTextField(value, selectedItems);

    setField({ error, value });
  };

  const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && isValidInput) {
      dispatch({ type, values: field.value.trim() });
      setField({ value: '' });
    }
  };
  return (
    <Grid item xs={9}>
      <StyledTextField
        data-test={'content-object-text-value'}
        label={getOptionName(type)}
        fullWidth
        placeholder={inputPlaceholder(type)}
        value={field.value}
        onChange={e => handleChange(e.target.value)}
        onKeyUp={onKeyPress}
        error={!!field.error}
        helperText={field.error}
      />
    </Grid>
  );
};
