import { styled } from '@mui/material';

export const HeaderImage = styled('img')`
  position: absolute;
  z-index: 0;
  margin: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;
