import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Audience,
  Category,
  ContentObject,
  Domains,
  FormatType,
  Geographic,
  InventoryAndContent,
  Metacategories,
  TargetingFields,
  TechnologyAndDevices,
  Video,
  TargetingConfig,
  Viewability,
  ViewThroughRate,
  useTargetingContext,
  AdvancedTargeting,
} from '@openx/components/targeting';

export const useTargeting = (): TargetingConfig[] => {
  const { t } = useTranslation();
  const { useContentObjectOptionsConfig, isOa } = useTargetingContext();

  const conditional = (element, condition: boolean) => (condition ? [element] : []);

  return useMemo(() => {
    const targeting = [
      {
        TargetingComponent: Audience,
        field: TargetingFields.AUDIENCE,
        name: t('Audience'),
      },
      {
        TargetingComponent: InventoryAndContent,
        field: TargetingFields.INVENTORY_AND_CONTENT,
        name: t('Inventory and Content'),
      },
      {
        TargetingComponent: Domains,
        field: TargetingFields.URL_TARGETING,
        name: t('Domains'),
      },
      {
        TargetingComponent: Geographic,
        field: TargetingFields.GEOGRAPHIC,
        name: t('Current Location'),
      },
      {
        TargetingComponent: TechnologyAndDevices,
        field: TargetingFields.TECHNOLOGY_AND_DEVICES,
        name: t('Technology and Devices'),
      },
      {
        TargetingComponent: Category,
        field: TargetingFields.CATEGORY,
        name: t('Category'),
      },
      {
        TargetingComponent: Metacategories,
        field: TargetingFields.METACATEGORY,
        name: isOa ? t('Additional OpenX Categories') : t('Additional OpenX Categories and Keywords'),
      },
      {
        TargetingComponent: FormatType,
        field: TargetingFields.FORMAT_TYPE,
        name: t('Format Type'),
      },
      {
        TargetingComponent: Viewability,
        field: TargetingFields.VIEWABILITY,
        name: t('Viewability'),
      },
      {
        TargetingComponent: Video,
        field: TargetingFields.VIDEO,
        name: t('Video Attributes'),
      },
      {
        TargetingComponent: ViewThroughRate,
        field: TargetingFields.VIEW_THROUGH_RATE,
        name: t('View Through Rate'),
      },
      ...conditional(
        {
          TargetingComponent: ContentObject,
          field: TargetingFields.CONTENT_OBJECT,
          name: t('Content Object'),
        },
        !!useContentObjectOptionsConfig
      ),
      {
        TargetingComponent: AdvancedTargeting,
        field: TargetingFields.CUSTOM_VARIABLES,
        name: t('Advanced Targeting'),
      },
    ];
    return targeting;
  }, [t, useContentObjectOptionsConfig, isOa]);
};
