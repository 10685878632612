import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import { IconButton, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState, MouseEvent, useMemo, useRef, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { SwitchField } from '@openx/components/core';
import { FiltersConfig, ChipFilterValue } from '@openx/types';

import { ChipContainer } from './ChipContainer';
import { FilterCategoryMenu } from './FilterCategoryMenu';
import { FilterItemsMenu } from './FilterItemsMenu';

const StyledAddNewFilterTypography = styled(Typography)`
  cursor: pointer;
  margin-left: ${({ theme }) => theme.spacing(1)};
  white-space: nowrap;
  line-height: 32px;
` as typeof Typography;

const StyledFilterContainerBox = styled(Box)`
  flex: 1;
`;

const StyledFilterIconButton = styled(IconButton)`
  margin-left: ${({ theme }) => theme.spacing(1)};
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

const StyledFilterContainerDiv = styled('div')`
  display: flex;
  align-items: flex-start;
`;

interface ChipFiltersProps<FiltersValue extends {}> {
  filtersConfig: FiltersConfig;
  filters: FiltersValue;
  onFilterChange: (newFilters: { [categoryName: string]: ChipFilterValue }) => void;
}

export function ChipFiltersView<FiltersValue extends {}>({
  filtersConfig,
  onFilterChange,
  filters,
}: ChipFiltersProps<FiltersValue>) {
  const { t } = useTranslation();
  const filterTextRef = useRef(null);
  const { myDeals, ...chipFiltersConfig } = filtersConfig;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [currentCategory, setCurrentCategory] = useState<string | null>(null);
  const [showMyDeals, setShowMyDeals] = useState<boolean>(false);

  const clearAllFilters = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setShowMyDeals(false);
    onFilterChange(
      Object.keys(chipFiltersConfig).reduce((acc, categoryName) => {
        acc[categoryName] = null;
        return acc;
      }, {})
    );
  };

  const deleteFilter = categoryName => {
    if (categoryName === 'createdBy') {
      setShowMyDeals(false);
    }
    onFilterChange({ [categoryName]: null });
  };

  const editFilter = (element: HTMLElement, category: string) => {
    setAnchorEl(element);
    setCurrentCategory(category);
  };

  const openFiltersClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closeFilterWindow = () => {
    setAnchorEl(null);
    setCurrentCategory(null);
  };

  const selectFilterCategory = (categoryName: string) => {
    setCurrentCategory(categoryName);
    const { fetchOptions } = chipFiltersConfig[categoryName];
    fetchOptions && fetchOptions();
  };

  const applyFilter = (categoryName: string, value: ChipFilterValue): void => {
    onFilterChange({ [categoryName]: value });
    closeFilterWindow();
  };

  const onSwitchChange = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setShowMyDeals(checked);
    if (checked) {
      onFilterChange({ createdBy: myDeals.options?.[0].value ?? '' });
      return;
    }

    onFilterChange({ createdBy: null });
  };

  const activeFilters: FiltersValue = useMemo((): FiltersValue => {
    return Object.entries(filters).reduce((acc, [categoryName, value]) => {
      if (value && categoryName in chipFiltersConfig) {
        acc[categoryName] = value;
      }
      return acc;
    }, {} as FiltersValue);
  }, [filters, chipFiltersConfig]);

  const isAnyFilterActive = !!Object.keys(activeFilters).length;

  return (
    <StyledFilterContainerDiv>
      <StyledFilterIconButton
        data-test="filter-icon-button"
        onClick={openFiltersClick}
        size="large"
        sx={{ zIndex: 10 }}
      >
        <FilterListIcon />
      </StyledFilterIconButton>
      <StyledFilterContainerBox>
        {myDeals && (
          <SwitchField
            label={myDeals.name}
            type="checkbox"
            onChange={onSwitchChange}
            checked={showMyDeals}
            data-test="show-my-deals"
          />
        )}
        <ChipContainer
          filters={activeFilters}
          filtersConfig={chipFiltersConfig}
          onEdit={editFilter}
          onDelete={deleteFilter}
        />
        <StyledAddNewFilterTypography
          data-test="filter-button"
          ref={filterTextRef}
          variant="body1"
          component="span"
          color="textSecondary"
          onClick={() => {
            !anchorEl && filterTextRef && setAnchorEl(filterTextRef.current);
          }}
        >
          {t('Add new filter')}
        </StyledAddNewFilterTypography>
        <FilterCategoryMenu
          filtersConfig={chipFiltersConfig}
          anchorEl={anchorEl}
          onSelect={selectFilterCategory}
          onClose={closeFilterWindow}
          open={Boolean(anchorEl && !currentCategory)}
          activeFilters={activeFilters}
        />
        {currentCategory && (
          <FilterItemsMenu
            category={currentCategory}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            currentValue={filters[currentCategory]}
            onApplyFilter={applyFilter}
            onClose={closeFilterWindow}
            filterConfig={chipFiltersConfig[currentCategory]}
          />
        )}
      </StyledFilterContainerBox>

      {isAnyFilterActive && (
        <IconButton onClick={clearAllFilters} data-test="clean-filters-button" size="large">
          <CloseIcon />
        </IconButton>
      )}
    </StyledFilterContainerDiv>
  );
}
