import { useTranslation } from 'react-i18next';

import { type OptionsMap, OptionsType, type UseQueryOptions } from '@openx/types';

import { useOptionsFetch } from './generics';

export function useConnectionTypeOptionsFetch(queryOptions?: UseQueryOptions<OptionsMap>) {
  const { t } = useTranslation();
  const {
    isLoading: isConnectionTypeLoading,
    data: connectionTypeOptions,
    refetch: refetchOptions,
  } = useOptionsFetch({
    errorMessage: t('Failed to fetch Connection Type options'),
    optionType: OptionsType.CONNECTION_TYPE,
    queryOptions,
  });

  return {
    connectionTypeOptions,
    isConnectionTypeLoading,
    refetchOptions,
  };
}
