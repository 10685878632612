export const produceSortedOptionsKeys = <T extends { name: string }>(
  options: Record<string, T | undefined> = {}
): string[] => {
  return Object.keys(options).sort((a, b) => {
    return (options[a]?.name.toLowerCase() || '') > (options[b]?.name.toLowerCase() || '') ? 1 : -1;
  });
};

export const produceSortedOptions = <T extends { name: string }>(
  options: Record<string, T> = {}
): Record<string, T> => {
  return produceSortedOptionsKeys(options).reduce((acc, current) => {
    return {
      ...acc,
      [current]: options[current],
    };
  }, {});
};
