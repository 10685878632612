import { styled, css } from '@mui/material/styles';

import { BaseRow, RowActionButtonBaseProps, RowActionProps } from '@openx/types';

import { IconCell } from './IconCell';
import MenuButton from './MenuButton';
import { RowActions } from './RowActions';

const StyledMenuContainerSpan = styled('span')`
  display: flex;
  justify-content: flex-end;
  padding-right: ${({ theme }) => theme.spacing(2.5)};
`;

interface StylesProps {
  hasDropdownActions: boolean;
  hasButtonActionsAvailable: boolean;
}

const StyledIconCell = styled(IconCell)<StylesProps>`
  position: relative;

  ${({ hasDropdownActions, hasButtonActionsAvailable }) =>
    !hasDropdownActions &&
    css`
      border-left: 0;
      display: ${hasButtonActionsAvailable ? 'table-cell' : 'none'};
      margin: 0;
      padding: 0;
      width: 0;
    `}
`;

export interface ActionsCellProps<RowT extends BaseRow> {
  rowData: RowT;
  primaryAction?: RowActionButtonBaseProps<RowT>;
  secondaryAction?: RowActionButtonBaseProps<RowT>;
  dangerAction?: RowActionButtonBaseProps<RowT>;
  defaultAction?: RowActionButtonBaseProps<RowT>;
  actions?: RowActionProps<RowT>[];
  customRowActions?: RowActionButtonBaseProps<RowT>[];
}

export function ActionsCell<RowT extends BaseRow>({
  rowData,
  primaryAction,
  secondaryAction,
  dangerAction,
  defaultAction,
  actions,
  customRowActions,
}: ActionsCellProps<RowT>): JSX.Element {
  const hasButtonActionsAvailable = !!(
    primaryAction ||
    secondaryAction ||
    dangerAction ||
    defaultAction ||
    customRowActions
  );
  const hasDropdownActions = !!actions;

  return (
    <StyledIconCell
      stopClickPropagation
      hasButtonActionsAvailable={hasButtonActionsAvailable}
      hasDropdownActions={hasDropdownActions}
    >
      {hasButtonActionsAvailable && (
        <RowActions
          primaryAction={primaryAction}
          secondaryAction={secondaryAction}
          defaultAction={defaultAction}
          dangerAction={dangerAction}
          customRowActions={customRowActions}
          rowData={rowData}
        />
      )}

      {actions && (
        <StyledMenuContainerSpan>
          <MenuButton menuItems={actions} rowData={rowData} data-test="row-actions" />
        </StyledMenuContainerSpan>
      )}
    </StyledIconCell>
  );
}
