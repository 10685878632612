import { Grid } from '@mui/material';
import { useCallback } from 'react';

import { AutoComplete } from '@openx/components/core';
import type { ContentObjectOption, OptionsMap, Option, ContentObjectAction } from '@openx/types';

import { getOptionName, inputPlaceholder } from '../../constants';

export const StaticOptions = (props: {
  loading;
  options: OptionsMap;
  dispatch: (action: ContentObjectAction) => void;
  type: ContentObjectOption;
}) => {
  const { loading, options, dispatch, type } = props;
  const tempOptions = Object.keys(options).reverse();
  const onChange = useCallback(
    (value: Option | null) => {
      dispatch({ type, values: value });
    },
    [dispatch, type]
  );

  return (
    <Grid item xs={9}>
      <AutoComplete
        loading={loading}
        options={tempOptions}
        getOptionLabel={chosenOption => options[chosenOption]?.name || ''}
        onChange={(_, value) => (value ? onChange(options[value]) : onChange(null))}
        textFieldProps={{ label: getOptionName(type), placeholder: inputPlaceholder(type) }}
        data-test="content-object-input"
      />
    </Grid>
  );
};
