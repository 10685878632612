import axios, { type AxiosResponse } from 'axios';

import { logoutOnError } from '../api/logoutOnError';
import { asyncSleep } from '../lib/asyncSleep';
import { redirectToForbiddenPage } from '../routes/redirectToForbiddenPage';

export async function fetchApiResponseHandler<T>(
  apiCall: Promise<AxiosResponse<T>>,
  selector?: string,
  getOnlyFirstItem?: boolean
): Promise<T> {
  try {
    const { data } = await apiCall;

    const isSingleArrayItem = Array.isArray(data) && data.length === 1;
    const object = getOnlyFirstItem && isSingleArrayItem ? data[0] : data;

    if (selector && typeof object === 'object') {
      const selectorsArray = selector.split('.');

      return selectorsArray.reduce((currentData, currentSelector) => {
        const value = currentData?.[currentSelector];

        return value || currentData;
      }, object);
    }

    return object;
  } catch (error) {
    const is401Error =
      (axios.isAxiosError(error) && Number(error.response?.status) === 401) || error.message?.includes('401');
    const is403Error =
      (axios.isAxiosError(error) && Number(error.response?.status) === 403) || error.message?.includes('403');

    if (is401Error) {
      logoutOnError();

      // we have to wait until the browser redirects the sso-redirect page
      await asyncSleep(1000);
    } else if (is403Error) {
      redirectToForbiddenPage();

      // we have to wait until the browser redirects the forbidden page
      await asyncSleep(1000);
    }

    throw error;
  }
}

export function prepareSortParameter(sortField: string, sortDirection: 'asc' | 'desc' = 'asc'): string {
  if (sortDirection === 'desc') {
    return `-${sortField}`;
  }

  return sortField;
}
