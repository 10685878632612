import { ReactElement } from 'react';

import { BaseRow } from '@openx/types';

import { TableRowPropsWithData } from './TableRow';

type CustomTableRowProps<RowT extends BaseRow> = {
  isForcedExpanded?: (data: RowT) => boolean;
  renderCustomRow: (data: TableRowPropsWithData<RowT>, isForcedExpanded?: (data: RowT) => boolean) => ReactElement;
  rowProps: TableRowPropsWithData<RowT>;
};

export const CustomTableRow = <RowT extends BaseRow>({
  isForcedExpanded,
  renderCustomRow,
  rowProps,
}: CustomTableRowProps<RowT>) => renderCustomRow(rowProps, isForcedExpanded);
