import { styled } from '@mui/material/styles';
import { KeyboardEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TextField, Button } from '@openx/components/core';
import { bigNumberWithCommas } from '@openx/utils';

import { DrawerTopBox, BulkAddDrawer, InvalidItemsError, useBulkAdd } from '../shared';

import { DOMAINS_LIMIT, useBulkAddPlaceholder } from './constants';
import { useValidateDomain } from './useValidateDomain';

const StyledBulkAddButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

type DomainsTopBoxProps = {
  items: string[];
  onEnter: (domains: string[]) => void;
  handleReset: () => void;
  shouldDisplayResetButton: boolean;
};

export const DomainsTopBox = ({ items, onEnter, handleReset, shouldDisplayResetButton }: DomainsTopBoxProps) => {
  const { t } = useTranslation();
  const [errorText, setErrorText] = useState('');
  const { isBulkOpen, onBulkAdd, onBulkClose, onBulkOpen } = useBulkAdd({ onAdd: onEnter });
  const bulkPlaceholder = useBulkAddPlaceholder();
  const validateDomain = useValidateDomain();

  const onKeyPress = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      const targetElement = e.target as HTMLInputElement;
      const { value } = targetElement;

      const errorText = value ? validateDomain(value) : '';

      if (e.key === 'Enter' && value && !errorText) {
        const alreadyExists = items.includes(value);
        if (alreadyExists) {
          setErrorText(t('The domain is already on the list.'));
          return;
        }
        onEnter([value]);
        targetElement.value = '';
      }

      setErrorText(errorText);
    },
    [items, onEnter, t, validateDomain]
  );

  const validateBulkDomains = useCallback(
    async (domainsList: string[]) => {
      const added = new Set(items);

      const duplicates: string[] = [];
      const invalid: string[] = [];
      const validItems: string[] = [];

      domainsList.forEach(domain => {
        if (validateDomain(domain)) {
          invalid.push(domain);
        } else if (added.has(domain)) {
          duplicates.push(domain);
        } else {
          validItems.push(domain);
          added.add(domain);
        }
      });

      const errors: InvalidItemsError[] = [];
      if (duplicates.length) errors.push({ error: t('Duplicates.'), invalidItems: duplicates });
      if (invalid.length) errors.push({ error: t('Invalid Domains.'), invalidItems: invalid });

      return { errors, validItems };
    },
    [items, t, validateDomain]
  );

  const isLimitReached = items.length >= DOMAINS_LIMIT;
  const currentLimit = DOMAINS_LIMIT - items.length;

  return (
    <>
      <DrawerTopBox>
        <TextField
          label={t('Page URL')}
          fullWidth
          onKeyUp={onKeyPress}
          margin="dense"
          data-test="domains-input"
          placeholder={
            isLimitReached
              ? t('The limit has been reached ({domainsLimit}).', { domainsLimit: DOMAINS_LIMIT })
              : t('Insert a domain address and press enter. You can add up to {domainsLimit} domains.', {
                  domainsLimit: DOMAINS_LIMIT,
                })
          }
          error={!!errorText}
          helperText={errorText}
          disabled={isLimitReached}
        />

        <StyledBulkAddButton disabled={isLimitReached} onClick={onBulkOpen}>
          {t('Bulk Add')}
        </StyledBulkAddButton>

        {shouldDisplayResetButton && (
          <Button
            data-test="reset-to-account-settings"
            onClick={handleReset}
            variant={'text'}
            color={'primary'}
            sx={{ marginLeft: '1em', marginTop: theme => theme.spacing(1) }}
          >
            {t("Reset to account's settings")}
          </Button>
        )}
      </DrawerTopBox>

      {isBulkOpen && (
        <BulkAddDrawer
          onApply={onBulkAdd}
          onClose={onBulkClose}
          placeholder={bulkPlaceholder}
          validate={validateBulkDomains}
          helperText={t('Enter up to {limit} domains, subdomains, or specific page URLs.', {
            limit: bigNumberWithCommas(currentLimit),
          })}
          limit={currentLimit}
        />
      )}
    </>
  );
};
