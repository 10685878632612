import { useTranslation } from 'react-i18next';

import { InventoryContentOption } from '@openx/types';

export const INVENTORY_LIMITS = {
  [InventoryContentOption.APP_BUNDLE_ID]: 2000,
  [InventoryContentOption.PAGE_URL]: 2000,
  [InventoryContentOption.KEYWORDS]: 2000,
};

export const useInventoryAndContentLabels = () => {
  const { t } = useTranslation();
  return {
    [InventoryContentOption.APP_BUNDLE_ID]: {
      label: t('App Bundle ID'),
    },
    [InventoryContentOption.PAGE_URL]: {
      label: t('Page URL'),
    },
    [InventoryContentOption.INVENTORY]: {
      label: t('Inventory'),
    },
    [InventoryContentOption.ADUNIT]: {
      label: t('Ad Unit ID'),
    },
    [InventoryContentOption.ADUNIT_SIZE]: {
      label: t('Ad Unit Size'),
    },
    [InventoryContentOption.PUBLISHER_ID]: {
      label: t('Publisher ID'),
    },
    [InventoryContentOption.INSTANCE_HASH]: {
      label: t('Instance Hash'),
    },
    [InventoryContentOption.SITE]: {
      label: t('Site ID'),
    },
    [InventoryContentOption.KEYWORDS]: {
      label: t('Keywords'),
    },
    [InventoryContentOption.LINE_ITEM_APP_BUNDLE_ID]: {
      label: t('App Bundle ID'),
    },
  };
};

export function getBulkPlaceHolder(t, filter) {
  return t(
    `{filter} may be entered as either comma, semicolon, or new line separated values.

Examples:
example.com,site.eu
or
example.com;site.eu
or
example.com
site.eu
`,
    { filter }
  );
}

export const ID_REGEX = /^[0-9]*$/;
export const INSTANCE_HASH_REGEX = /^[0-9a-f]{8}-?([0-9a-f]{4}-?){3}([0-9a-f]{12}|[0-9a-f]{6}|[0-9a-f]{20})$/;
export const INVENTORY_IDS_DISPLAY_LIMIT = 5;

export const inventoryAndContentDefaultOptions = {
  availableOptions: [
    InventoryContentOption.APP_BUNDLE_ID,
    InventoryContentOption.INVENTORY,
    InventoryContentOption.PAGE_URL,
    InventoryContentOption.ADUNIT_SIZE,
    InventoryContentOption.PUBLISHER_ID,
    InventoryContentOption.INSTANCE_HASH,
    InventoryContentOption.CONTENT_INTER_DIMENSION_OPERATOR,
    InventoryContentOption.KEYWORDS,
  ],
  defaultOption: InventoryContentOption.APP_BUNDLE_ID,
};
