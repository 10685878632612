import { useCallback } from 'react';

import { useConfirmationDialog } from '@openx/components/core';

import { DealActivationModalContent } from './DealActivationModalContent';

export function useDealActivationModal() {
  const { open } = useConfirmationDialog();

  const openDealActivationModal = useCallback(
    (dealName: string): void => {
      open({
        cancelLabel: 'Close',
        confirmationDisabled: true,
        content: <DealActivationModalContent dealName={dealName} />,
        customContent: true,
        hideConfirm: true,
        showExtraBtn: false,
        title: 'Deal Activation',
      });
    },
    [open]
  );

  return { openDealActivationModal };
}
