import CloseIcon from '@mui/icons-material/Close';
import { Chip as MaterialChip, ChipProps, IconButton } from '@mui/material';
import { styled, darken } from '@mui/material/styles';

const StyledMaterialChip = styled(MaterialChip)`
  max-width: 550px;
  &:hover,
  &:focus {
    background: ${({ theme }) => darken(theme.palette.text.secondary, 0.16)};
  }
`;

export const Chip = (props: ChipProps) => {
  return (
    <StyledMaterialChip
      {...props}
      deleteIcon={
        <IconButton size="large">
          <CloseIcon data-test="chip-delete" />
        </IconButton>
      }
    />
  );
};
