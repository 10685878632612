import { RowDescription, Tags } from 'components';

import { NameCell } from '@openx/components/core';

import { StyledTagsSection } from '../styled';

export const NameCellContainer = (
  { tags, id, name, description },
  phrase: string,
  onSearchChange: (filters: { phrase: string }) => void
): JSX.Element | null => (
  <NameCell name={name} pathTo={`/details/${id}`} searchPhrase={phrase}>
    {description && <RowDescription description={description} phrase={phrase} />}
    {tags.length > 0 && (
      <StyledTagsSection>
        <Tags tags={tags} id={id} handleTagClick={onSearchChange} phrase={phrase} />
      </StyledTagsSection>
    )}
  </NameCell>
);
