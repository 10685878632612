import { createContext, useContext, useState, type ReactNode } from 'react';

import { type TargetingCustomOptions } from '@openx/types';

type CustomOptionsProviderType<T> = {
  children: ReactNode;
  value?: TargetingCustomOptions<T>;
};

export const CustomOptionsContext = createContext<TargetingCustomOptions<any> | undefined>(undefined);

export function CustomOptionsProvider<T>({ children, value }: CustomOptionsProviderType<T>) {
  const [customOptions] = useState<TargetingCustomOptions<T> | undefined>(value);

  return <CustomOptionsContext.Provider value={customOptions}>{children}</CustomOptionsContext.Provider>;
}

export function useCustomOptionsContext<T>() {
  return useContext<TargetingCustomOptions<T> | undefined>(CustomOptionsContext);
}
