import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { ReactQueryStaleTime } from '@openx/constants/react-query';
import { type OptionsMap, ParameterizedOptionsType } from '@openx/types';

import { prepareMapBy } from '../lib/prepareMapBy';
import { fetchOptions } from '../state/parameterizedOptions';

export function useDMPSegmentsOptionsFetch(accountUid: string) {
  const { t } = useTranslation();

  const {
    isLoading: isDmpSegmentsLoading,
    data: dmpSegments,
    refetch: refetchDMPSegments,
  } = useQuery<OptionsMap>({
    enabled: false,
    meta: {
      errorMessage: t('Failed to fetch DMP Segments'),
    },
    queryFn: async () => {
      if (!accountUid) {
        return {};
      }
      const list = await fetchOptions({
        params: { account_uid: accountUid, dmp: 1 },
        type: ParameterizedOptionsType.DMP_SEGMENTS,
      });
      return prepareMapBy(list, 'id');
    },
    queryKey: [ParameterizedOptionsType.DMP_SEGMENTS, accountUid],
    staleTime: ReactQueryStaleTime.MINUTES_15,
  });

  return {
    dmpSegments: dmpSegments || {},
    isDmpSegmentsLoading,
    refetchDMPSegments,
  };
}
