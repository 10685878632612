import { styled } from '@mui/material/styles';

import { TextField } from '@openx/components/core';

export const StyledTextField = styled(TextField)`
  -moz-appearance: textfield;
  margin-top: -6px !important;

  & .MuiInputBase-root {
    height: 34px !important;
  }

  & .MuiInputBase-input.MuiOutlinedInput-input {
    height: 30px !important;
    background-color: transparent !important;
  }
`;
