import { Grid, styled } from '@mui/material';

export const TagContainer = styled(Grid)`
  display: flex;
  align-items: center;
`;

export const Tag = styled('span', { shouldForwardProp: prop => prop !== 'filled' })<{ filled: boolean }>`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.info.main};
  background-color: ${({ theme, filled }) => (filled ? theme.palette.info.light : 'transparent')};
  padding: ${({ theme, filled }) => (filled ? theme.spacing(0.75, 1.75) : theme.spacing(0.25))};
  margin-right: ${({ theme }) => theme.spacing(1)};
  line-height: normal;
  cursor: pointer;

  ${({ filled }) => filled && 'border-radius: 50px;'}

  ${({ theme }) => theme.breakpoints.down(760)} {
    margin-bottom: ${({ theme }) => theme.spacing(0.25)};
  }
`;

export const TagLabel = styled('span')`
  margin-right: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: ${({ theme }) => theme.spacing(1.5)};
  line-height: 1;
`;
