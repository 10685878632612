import { Grid, InputAdornment } from '@mui/material';
import { useMemo, useCallback, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { RHFAutoComplete } from '@openx/components/core/AutoComplete';
import { RHFTextField } from '@openx/components/core/TextField';
import { ChipFilterValue } from '@openx/types';

import { NumberFilterData, NumberFilterFields, RangeOptions } from './types';
import { getOptionParam } from './utils';

const onKeyDown = e => {
  if (e.target.type !== 'textarea' && e.key === 'Enter') {
    // disable submit form on enter
    e.preventDefault();
  }
};

type NumberFilterInputsProps = {
  setCurrentOption: (val: ChipFilterValue) => void;
  setError: (val: boolean) => void;
  currencySymbol?: string;
};

export const NumberFilterInputs = ({
  setCurrentOption,
  currencySymbol = '',
  setError,
}: NumberFilterInputsProps): JSX.Element => {
  const {
    setValue,
    control,
    formState: { isValid },
  } = useFormContext<NumberFilterData>();
  const { t } = useTranslation();
  const optionValue = useWatch({
    control,
    name: NumberFilterFields.OPTION,
  });
  const value = useWatch({
    control,
    name: NumberFilterFields.VALUE,
  });
  const valueTo = useWatch({
    control,
    name: NumberFilterFields.VALUE_TO,
  });

  const isRangeOption = optionValue === RangeOptions.RANGE;

  useEffect(() => {
    setCurrentOption(getOptionParam(optionValue, value, valueTo));
    setError(!isValid || value === '');
  }, [optionValue, value, valueTo, setCurrentOption, setError, isValid]);

  const labels = useMemo(
    () => ({
      [RangeOptions.GT]: t('Greater than'),
      [RangeOptions.LT]: t('Less than'),
      [RangeOptions.RANGE]: t('In range'),
      [RangeOptions.EQUAL]: t('Equal to'),
    }),
    [t]
  );

  const clearAllValues = useCallback(() => {
    setValue(NumberFilterFields.VALUE, '');
    setValue(NumberFilterFields.VALUE_TO, '');
  }, [setValue]);

  const onRangeSelectChange = useCallback(
    (_, option: RangeOptions | null) => {
      clearAllValues();
      setValue(NumberFilterFields.OPTION, option);
    },
    [clearAllValues, setValue]
  );

  return (
    <form data-test="ad-units-form" autoComplete="off" onKeyDown={onKeyDown} noValidate>
      <RHFAutoComplete
        name={NumberFilterFields.OPTION}
        textFieldProps={{ placeholder: t('Select option') }}
        getOptionLabel={option => labels[option]}
        options={Object.keys(RangeOptions) as RangeOptions[]}
        ListboxProps={{ 'data-test': 'list-of-items' }}
        data-test="filter-list"
        onChange={onRangeSelectChange}
      />
      <Grid container spacing={1}>
        {optionValue && (
          <Grid item xs>
            <RHFTextField
              name={NumberFilterFields.VALUE}
              placeholder={isRangeOption ? t('From') : t('Value')}
              data-test="chip-filter-number-value-input"
              InputProps={{
                endAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
              }}
              variant="outlined"
              margin="dense"
              fullWidth={!isRangeOption}
            />
          </Grid>
        )}
        {isRangeOption && (
          <Grid item xs>
            <RHFTextField
              name={NumberFilterFields.VALUE_TO}
              placeholder={t('To')}
              data-test="chip-filter-number-value-input"
              InputProps={{
                endAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
              }}
              variant="outlined"
              margin="dense"
            />
          </Grid>
        )}
      </Grid>
    </form>
  );
};
