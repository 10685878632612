import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { TargetableInventoryList } from '@openx/types';
import { ACL_TYPE, isAllowedByCompiledAcl, useIsAllowed } from '@openx/utils/permission';
import { searchInventories } from '@openx/utils/state/packages';

import { getExtendQuery } from '../../../Targeting/shared/InventoryAndContent/utils';
import { useTargetingContext } from '../../../utils/context';

interface UseFetchInventoriesBySearchTextProps {
  accountId: string;
  searchPhrase: string;
  includes: TargetableInventoryList;
  instanceUid?: string;
}

export const useFetchInventoriesBySearchText = ({
  accountId,
  includes,
  instanceUid,
  searchPhrase,
}: UseFetchInventoriesBySearchTextProps) => {
  const { t } = useTranslation();
  const { isAllowed } = useIsAllowed();

  const { useCurrentInstanceState } = useTargetingContext();
  const { isBefInstance } = useCurrentInstanceState();

  const { isLoading, data, refetch } = useQuery<TargetableInventoryList>({
    enabled: !!searchPhrase,
    meta: {
      errorMessage: t('Failed to fetch Inventories'),
    },
    queryFn: () => {
      const isUserAllowed =
        isAllowed(isAllowedByCompiledAcl(ACL_TYPE.ROOT_WORKAS_ANYINSTANCE)) ||
        isAllowed(isAllowedByCompiledAcl(ACL_TYPE.INTERNAL_FIELD_WRITE));

      return searchInventories(
        isUserAllowed && isBefInstance,
        {},
        instanceUid,
        accountId,
        false,
        searchPhrase,
        getExtendQuery(includes)
      );
    },
    queryKey: [instanceUid, searchPhrase, includes],
  });

  return {
    data,
    isLoading,
    refetch,
  };
};
