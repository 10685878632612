import { isEqual } from 'lodash';
import { useCallback, useMemo, useState, memo } from 'react';

import {
  Criteria,
  AdvancedTargetingType,
  type CustomVariablesData,
  AdvancedTargetingData,
  TargetingFields,
} from '@openx/types';

import { TargetingDrawer, useDrawer } from '../shared';
import type { TargetingProps } from '../types';

import { AdvanceTargetingItems } from './AdvancedTargetingItems';
import { AdvancedTargetingReadOnly } from './AdvancedTargetingReadOnly';
import { AdvancedTargetingTopBox } from './AdvancedTargetingTopBox';
import { getCurrentType } from './helper';

const defaultState = {
  contextual: [],
  custom: [],
  op: Criteria.ALL,
};

export const AdvancedTargeting = memo(function AdvancedTargeting({
  targetingParams,
  onFieldUpdate = () => {},
  name,
  readonly,
  field,
  isDisabled,
}: TargetingProps<AdvancedTargetingData>): JSX.Element {
  const [type, setType] = useState<AdvancedTargetingType | undefined>(getCurrentType(targetingParams));
  const initState = useMemo(
    () => ({
      ...defaultState,
      ...(type && {
        op: targetingParams?.op ?? defaultState.op,
        [type]: targetingParams ? targetingParams[type] : [],
      }),
    }),
    [targetingParams, type]
  );
  const [params, updateParams] = useState<AdvancedTargetingData>(initState);

  const isContextualUpdated = useMemo(() => {
    const contextual = params.contextual ?? [];
    const contextualSegmentsWithIds = contextual.length
      ? contextual.filter(contextualSegment => contextualSegment.val)
      : [];
    const hasEmptySegmentIdsInputs = contextual.length !== contextualSegmentsWithIds.length;
    return !hasEmptySegmentIdsInputs && !isEqual(initState, params);
  }, [initState, params]);

  const isCustomUpdated = useMemo(() => !isEqual(initState, params), [params, initState]);

  const isDataUpdated = {
    [TargetingFields.CONTEXTUAL_SEGMENTS]: isContextualUpdated,
    [TargetingFields.CUSTOM_VARIABLES]: isCustomUpdated,
  };

  const { isOpen, onDrawerClose, onDrawerOpen, onDrawerApply } = useDrawer({
    onApply: () => type && onFieldUpdate(type, { ...params, op: params.op, [type]: params[type] }),
    restoreParams: () => updateParams(initState),
  });

  const onRemoveAll = useCallback(() => {
    onFieldUpdate(field, null);
    updateParams(defaultState);
  }, [onFieldUpdate, field]);

  const handleTypeChange = (type: AdvancedTargetingType) => {
    setType(type);
    updateParams(defaultState);
  };

  const upsertVariable = useCallback(
    (items: CustomVariablesData) => {
      const val = items.val;

      type && updateParams(prevParams => ({ ...prevParams, [type]: val }));
    },
    [type]
  );

  return (
    <>
      <AdvancedTargetingReadOnly
        targetingParams={initState.contextual?.length || initState.custom?.length ? initState : null}
        name={name}
        readonly={readonly}
        onOpenDrawerClick={onDrawerOpen}
        onRemoveClick={onRemoveAll}
        isDisabled={isDisabled}
      />
      {isOpen && (
        <TargetingDrawer
          name={name}
          onClose={onDrawerClose}
          onApply={onDrawerApply}
          isDataUpdated={(type && isDataUpdated[type]) ?? false}
        >
          <AdvancedTargetingTopBox
            items={params}
            onEnter={upsertVariable}
            onTypeChange={handleTypeChange}
            type={type}
          />
          <AdvanceTargetingItems params={params} updateParams={updateParams} onAdd={upsertVariable} type={type} />
        </TargetingDrawer>
      )}
    </>
  );
});
