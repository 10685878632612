import { UrlTargeting } from '@openx/types';

import { TargetingReadOnly } from '../shared';
import { TargetingReadOnlyProps } from '../types';

import { DomainsItems } from './DomainsItems';

type InfoComponentProps = { infoComponent: JSX.Element | null };

export const DomainsReadOnly = ({
  name,
  onOpenDrawerClick,
  onRemoveClick,
  targetingParams,
  isDisabled,
  readonly,
  infoComponent,
}: TargetingReadOnlyProps<UrlTargeting> & InfoComponentProps) => {
  return (
    <TargetingReadOnly
      name={name}
      isEmpty={!targetingParams}
      onOpenDrawerClick={onOpenDrawerClick}
      onRemove={onRemoveClick}
      readonly={readonly}
      isDisabled={isDisabled}
    >
      {infoComponent}
      {targetingParams && <DomainsItems targetingParams={targetingParams} />}
    </TargetingReadOnly>
  );
};
