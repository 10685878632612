import { cloneDeep, isEqual } from 'lodash';
import { useState, useCallback, useMemo, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { OptionsType, SelectRateFormState } from '@openx/types';

import { TargetingProps } from '../../types';
import { EmptyFilterMessage } from '../EmptyFilterMessage';
import { TargetingDrawer, useDrawer } from '../TargetingDrawer';

import { defaultParams } from './constants';
import { Items } from './Items';
import { SelectRateReadOnly } from './SelectRateReadOnly';
import { TopBox } from './TopBox';

interface SelectRateProps extends TargetingProps<SelectRateFormState> {
  placeholder: string;
  filterTitle: string;
  dataTest?: string;
  optionsFetch: OptionsType.VIEWABILITY | OptionsType.VIEW_THROUGH_RATE;
}

export const SelectRate = memo(function SelectRate({
  targetingParams,
  onFieldUpdate = () => {},
  name,
  readonly,
  field,
  isDisabled,
  filterTitle,
  placeholder,
  optionsFetch,
  dataTest,
}: SelectRateProps) {
  const { t } = useTranslation();

  const initState = useMemo(
    () => (targetingParams ? cloneDeep(targetingParams) : { ...defaultParams }),
    [targetingParams]
  );
  const [params, updateParams] = useState<SelectRateFormState>(initState);
  const isDataUpdated = useMemo(() => !isEqual(initState, params), [initState, params]);

  const { isOpen, onDrawerClose, onDrawerOpen, onDrawerApply } = useDrawer({
    onApply: () => onFieldUpdate(field, params.val ? params : null),
    restoreParams: () => updateParams({ ...initState }),
  });

  const onRemoveAll = useCallback(() => {
    onFieldUpdate(field, null);
    updateParams({ ...params, val: '' });
  }, [onFieldUpdate, params, field]);

  const onChange = useCallback((val: string | null) => updateParams(params => ({ op: params.op, val })), []);

  const isEmpty = !params.val;

  return (
    <>
      <SelectRateReadOnly
        name={name}
        readonly={readonly}
        targetingParams={initState}
        onOpenDrawerClick={onDrawerOpen}
        onRemoveClick={onRemoveAll}
        isDisabled={isDisabled}
        filterTitle={filterTitle}
        optionsFetch={optionsFetch}
        dataTest={dataTest}
      />
      {isOpen && (
        <TargetingDrawer name={name} onClose={onDrawerClose} onApply={onDrawerApply} isDataUpdated={isDataUpdated}>
          <TopBox value={params.val} onChange={onChange} placeholder={placeholder} optionsFetch={optionsFetch} />
          {isEmpty && <EmptyFilterMessage text={t('No filters applied.')} />}
          {!isEmpty && (
            <Items
              title={filterTitle}
              targetingParams={params}
              readonly={false}
              onChange={updateParams}
              field={field}
              optionsFetch={optionsFetch}
              dataTest={dataTest}
            />
          )}
        </TargetingDrawer>
      )}
    </>
  );
});
