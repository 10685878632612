import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button, theme } from '@openx/components/core';

type ClearAllOptionDropdownItemProps = {
  onClear: () => void;
};

export const ClearAllOptionDropdownItem = ({ onClear }: ClearAllOptionDropdownItemProps) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        ':hover': {
          cursor: 'default',
        },
        alignItems: 'center',
        background: theme.palette.background.boxLight,
        display: 'flex',
        justifyContent: 'space-between',
        padding: 0.8,
        width: '100%',
      }}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <Button onClick={onClear} variant="text" color="error" data-test="hierarchy-clear-all">
        {t('Clear all')}
      </Button>
      <Typography variant="body2" color="textSecondary" textTransform="uppercase">
        {t('allow/block')}
      </Typography>
    </Box>
  );
};
