import { pick } from 'lodash';
import { useTranslation } from 'react-i18next';

import { AudienceOption } from '@openx/types';

import { type EidsOptionsFetchResponse, useTargetingContext } from '../../../utils/';
import { OptionsDropdown } from '../../shared';

type EidsDropdownProps = {
  eidsAllowedByAccount?: Record<number, boolean>;
  onChange: (value: string | Set<string>, type?: AudienceOption) => void;
  selectedEids?: Set<string>;
};

type TrafficFilterEidsOptionsFetch = {
  useTrafficFilterEidsOptionsFetch: () => EidsOptionsFetchResponse;
};

type RenderEidsDropdownProps = EidsDropdownProps & TrafficFilterEidsOptionsFetch;

export const EidsDropdown = ({
  eidsAllowedByAccount,
  onChange,
  selectedEids = new Set<string>(),
}: EidsDropdownProps) => {
  const { useTrafficFilterEidsOptionsFetch } = useTargetingContext();

  if (useTrafficFilterEidsOptionsFetch) {
    return (
      <RenderEidsDropdown
        onChange={onChange}
        eidsAllowedByAccount={eidsAllowedByAccount}
        selectedEids={selectedEids}
        useTrafficFilterEidsOptionsFetch={useTrafficFilterEidsOptionsFetch}
      />
    );
  }

  return null;
};

const RenderEidsDropdown = ({
  eidsAllowedByAccount,
  onChange,
  selectedEids = new Set<string>(),
  useTrafficFilterEidsOptionsFetch,
}: RenderEidsDropdownProps) => {
  const { t } = useTranslation();
  const { isLoading, trafficFilterEids } = useTrafficFilterEidsOptionsFetch();

  const filteredEids = eidsAllowedByAccount ? pick(trafficFilterEids, Object.keys(eidsAllowedByAccount)) : {};

  const handleChange = (value: Set<string>) => {
    onChange(value, AudienceOption.TRAFFIC_FILTER_EIDS);
  };

  return (
    <OptionsDropdown
      loading={isLoading}
      onChange={handleChange}
      options={filteredEids}
      selectedOptions={selectedEids}
      placeholder={t('[ Select Eids Users ]')}
      dataTest="eids-options"
      margin={'dense'}
    />
  );
};
