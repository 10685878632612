import { Typography } from '@mui/material';
import { Alert } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InvalidItemsError } from '../types';

import { AlertAction, ErrorDetails } from './components';

interface InvalidItemsProps {
  errorText?: string;
  errors: InvalidItemsError[];
}

export function InvalidItems(props: InvalidItemsProps) {
  const { errorText, errors } = props;
  const { t } = useTranslation();
  const [showErrors, setShowErrors] = useState(false);

  const nonValidItems = useMemo(() => errors.reduce((acc, error) => acc + error.invalidItems.length, 0), [errors]);

  const itemText = nonValidItems > 1 ? t('items were') : t('item was');

  return (
    <>
      <Alert
        severity="error"
        action={<AlertAction showErrors={showErrors} setShowErrors={setShowErrors} />}
        data-test="bulk-message"
      >
        <Typography variant="body1">
          <b>{t('{num} {itemText} removed', { itemText, num: nonValidItems })}</b>
        </Typography>
        {showErrors && errorText ? <Typography variant="body1">{errorText}</Typography> : null}
      </Alert>
      {showErrors && <ErrorDetails errors={errors} />}
    </>
  );
}
