import React, { createContext, useContext, useMemo } from 'react';

import {
  Account,
  CategoryOptionsMap,
  GeoDataApi,
  GeoLocationsList,
  InstanceProps,
  InstancesByIdObject,
  MetacategoryOptionsMap,
  OptionsMap,
  OptionsType,
  TargetableInventoryEntities,
  TargetableInventoryList,
  TechnologyAndDevicesOption,
  VideoOption,
  inventoryTargetingContent,
} from '@openx/types';

import {
  InstanceInfo,
  InventoryFetchResponse,
  MarketProps,
  OptionsFetchResponse,
  UseFetchBySearchTextProps,
  UseFetchInventoriesBySearchTextProps,
  UseAudienceOptionsDetailsFetchFn,
  UseContentObjectOptionsConfig,
  UseAudienceOptionsFetchFn,
  UseAccountOptionsFetch,
} from './TargetingHookTypes';

export interface TargetingProviderConfig {
  isOa?: boolean;
  useAccountUid: () => string;
  useAccountOptionsFetch: UseAccountOptionsFetch;
  useCurrentInstanceState: () => InstanceInfo;
  useAudienceOptionsFetch: UseAudienceOptionsFetchFn;
  useAudienceOptionsDetailsFetch: UseAudienceOptionsDetailsFetchFn;
  useSelectRateOptionsFetch: (optionType: OptionsType.VIEWABILITY | OptionsType.VIEW_THROUGH_RATE) => {
    isSelectRateLoading: boolean;
    optionsList: string[];
    selectRate: OptionsMap;
  };
  useVideoOptionsFetch: (type: VideoOption, isUsed?: boolean) => OptionsFetchResponse;
  useAdPlacementOptions: () => { adPlacement: OptionsMap; isAdPlacementLoading: boolean };
  useDistributionChannelOptions: () => { distributionChannel: OptionsMap; isDistributionChannelLoading: boolean };
  useCategoriesFetch: () => {
    categories: CategoryOptionsMap;
    isCategoriesLoading: boolean;
    refetchCategories: (props?: any) => any | void;
  };
  usePmpSizesFetch: () => {
    sizes: OptionsMap;
    isSizesLoading: boolean;
  };
  useTrafficFilterEidsOptionsFetch?: () => {
    isLoading: boolean;
    trafficFilterEids: OptionsMap;
  };
  useMetacategoriesFetch: () => {
    metacategories: MetacategoryOptionsMap;
    areMetacategoriesLoading: boolean;
    refetchMetacategories: () => void | NonNullable<unknown>;
  };
  useContentObjectOptionsConfig?: UseContentObjectOptionsConfig;
  useTechnologyOptionsFetch: (type: TechnologyAndDevicesOption, isUsed: boolean) => OptionsFetchResponse;
  useFetchInventoriesBySearchText: (props: UseFetchInventoriesBySearchTextProps) => InventoryFetchResponse;
  useFetchInventoryInstances: (marketId?: null | string) => {
    instances: InstanceProps[] | undefined;
    loading?: boolean;
  };
  useFetchInventoryMarket: () => {
    markets: MarketProps[];
    loading?: boolean;
  };
  useInventoriesFetch: (
    inventoryPayload: inventoryTargetingContent<TargetableInventoryEntities>,
    idByInstances?: InstancesByIdObject
  ) => {
    inventories: inventoryTargetingContent<TargetableInventoryList> | null | undefined;
    loading: boolean;
  };
  useCountriesOptionsFetch: () => {
    countries: OptionsMap;
    isCountriesLoading: boolean;
    refetchCountries?: (param?: any) => Promise<any>;
  };
  useFetchByGeoData: (isBulk?: boolean) => {
    fetchByGeoData: (geoData: GeoDataApi) => never[] | Promise<GeoLocationsList>;
  };
  useFetchBySearchText: ({
    searchText,
    excludeArea,
    includeArea,
    radius,
    geographicType,
  }: UseFetchBySearchTextProps) => {
    geoSearchData: GeoLocationsList | undefined;
    isLoading: boolean;
    refetch: (options?: any) => Promise<any>;
  };
  useExchangeSizeFetch?: () => {
    sizes: OptionsMap;
    isSizesLoading: boolean;
  };
  useKeywordsOptionsFetch: (k: string) => {
    keywordsOptions: OptionsMap;
    keywordsOptionsLoading: boolean;
  };
  usePostalCodes: () => {
    fetchPostalCodes: (param: { country: string; postal_codes: string[] }) => Promise<GeoLocationsList> | never[];
  };
  useLocationSourceOptionsFetch?: () => {
    isLoading: boolean;
    locationSourceOptions: OptionsMap;
  };
  useAccountFetch?: (accountUid?: string) => {
    account: Account | null;
    isAccountLoading: boolean;
  };
}

/* End Temp Section */

export interface TargetingProviderProps {
  children: React.ReactNode;
  targetingConfig: TargetingProviderConfig;
}

export type TargetingContext = TargetingProviderConfig;

const targetingContext = createContext<TargetingContext>({
  isOa: false,
  useAccountFetch: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useAccountOptionsFetch: () => {
    throw new Error('useAccountOptionsFetch: Not implemented. Ensure you are inside the DealContext');
  },
  useAccountUid: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useAdPlacementOptions: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useAudienceOptionsDetailsFetch: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useAudienceOptionsFetch: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useCategoriesFetch: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useContentObjectOptionsConfig: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useCountriesOptionsFetch: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useCurrentInstanceState: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useDistributionChannelOptions: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useFetchByGeoData: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useFetchBySearchText: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useFetchInventoriesBySearchText: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useFetchInventoryInstances: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useFetchInventoryMarket: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useInventoriesFetch: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useKeywordsOptionsFetch: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useLocationSourceOptionsFetch: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useMetacategoriesFetch: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  usePmpSizesFetch: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  usePostalCodes: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useSelectRateOptionsFetch: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useTechnologyOptionsFetch: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useTrafficFilterEidsOptionsFetch: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useVideoOptionsFetch: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
});

export const useTargetingContext = () => useContext(targetingContext);

export function TargetingProvider({ children, targetingConfig }: TargetingProviderProps) {
  const value: TargetingContext = useMemo(
    () => ({
      ...targetingConfig,
    }),
    [targetingConfig]
  );

  return <targetingContext.Provider value={value}>{children}</targetingContext.Provider>;
}
