import { OptionsObject } from 'notistack';
import { Action } from 'redux';

export interface Notification {
  message: string;
  options: OptionsObject;
}

export enum NotificationActionType {
  ENQUEUE_NOTIFICATION = 'ENQUEUE_NOTIFICATION',
  REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION',
}

export interface NotificationAction extends Action<NotificationActionType> {
  notification: Notification;
}

export function enqueueNotification(notification): NotificationAction {
  return {
    notification,
    type: NotificationActionType.ENQUEUE_NOTIFICATION,
  };
}

export function removeNotification(notification): NotificationAction {
  return {
    notification,
    type: NotificationActionType.REMOVE_NOTIFICATION,
  };
}

/* Display error message and returns error ready for dispatch */
export const enqueueErrorNotification = (msg: string, err?: unknown) => {
  err && console.error(msg, err);

  return enqueueNotification({
    message: msg,
    options: {
      variant: 'error',
    },
  });
};
