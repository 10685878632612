import { SortDirection } from '@openx/components/table';

import type { DealLibraryItem } from 'types';

import { ColumnKey } from '../constants';

export const sortList = (
  direction: SortDirection,
  sortColumn: ColumnKey,
  dealTemplates: DealLibraryItem[]
): DealLibraryItem[] => {
  if (sortColumn === ColumnKey.DISTRIBUTION_CHANNEL) {
    return direction === SortDirection.ASC
      ? dealTemplates.sort((a, b) => (a.distribution_channel || '').localeCompare(b.distribution_channel || ''))
      : dealTemplates.sort((a, b) => (b.distribution_channel || '').localeCompare(a.distribution_channel || ''));
  }

  if (sortColumn === ColumnKey.AD_PLACEMENT) {
    return direction === SortDirection.ASC
      ? dealTemplates.sort((a, b) => (a.ad_placement || '').localeCompare(b.ad_placement || ''))
      : dealTemplates.sort((a, b) => (b.ad_placement || '').localeCompare(a.ad_placement || ''));
  }

  return direction === SortDirection.ASC
    ? dealTemplates.sort((a, b) => a.name.localeCompare(b.name))
    : dealTemplates.sort((a, b) => b.name.localeCompare(a.name));
};
