import { isEqual } from 'lodash';
import { useState, useCallback, useMemo, memo, useEffect } from 'react';

import {
  type FormatTypeData,
  AdPlacement,
  Criteria,
  DistributionChannel,
  InventoryFormatTypeOption,
} from '@openx/types';

import { type CtvValidationReturnType, TargetingDrawer, useDrawer } from '../shared';
import type { TargetingProps } from '../types';

import { FormatTypeReadOnly } from './FormatTypeReadOnly';
import { Items } from './Items';
import { TopBox } from './TopBox';
import { checkForEmptyParams } from './utils';

type CtvValidationMethods = Omit<
  CtvValidationReturnType,
  'setTechnographic' | 'isTechnographicOpen' | 'shouldDisplayWarningInTechnographic'
>;

const defaultCustomValidationMethods: CtvValidationMethods = {
  isFormatTypeOpen: false,
  setFormatType: () => {},
  setIsFormatTypeOpen: () => {},
  setIsTechnographicOpen: () => {},
  shouldDisplayWarningInFormatType: false,
};

export const FormatType = memo(function FormatType({
  targetingParams,
  onFieldUpdate = () => {},
  name,
  readonly,
  field,
  customValidationMethods = defaultCustomValidationMethods,
}: TargetingProps<FormatTypeData, CtvValidationMethods>) {
  const initState = useMemo(() => structuredClone(targetingParams), [targetingParams]);
  const [params, setParams] = useState<FormatTypeData | null>(initState);
  const [distributionChannel, setDistributionChannel] = useState<DistributionChannel | null>(null);
  const [adPlacement, setAdPlacement] = useState<AdPlacement | null>(null);

  const { setFormatType, setIsFormatTypeOpen, isFormatTypeOpen, ...methods } = customValidationMethods;

  useEffect(() => {
    setFormatType(params);
  }, [params, setFormatType]);

  const areParamsEmpty = useMemo(() => checkForEmptyParams(params), [params]);

  const isDataUpdated = useMemo(
    () =>
      !(
        isEqual(targetingParams, params) ||
        (targetingParams?.op === Criteria.ANY && params === null) ||
        (targetingParams === null && areParamsEmpty)
      ),
    [areParamsEmpty, params, targetingParams]
  );

  const { isOpen, onDrawerClose, onDrawerOpen, onDrawerApply } = useDrawer({
    onApply: () => {
      return onFieldUpdate(field, areParamsEmpty ? null : params);
    },
    restoreParams: () => setParams(initState && { ...initState }),
  });

  const onRemoveAll = useCallback(() => {
    onFieldUpdate(field, null);
    setParams(null);
  }, [onFieldUpdate, field]);

  useEffect(() => {
    if (isOpen) {
      setDistributionChannel(targetingParams?.[InventoryFormatTypeOption.DISTRIBUTION_CHANNEL]?.val || null);
      setAdPlacement(targetingParams?.[InventoryFormatTypeOption.AD_PLACEMENT]?.val || null);
    }
  }, [isOpen, targetingParams, setDistributionChannel, setAdPlacement]);

  return (
    <>
      <FormatTypeReadOnly
        name={name}
        readonly={readonly}
        targetingParams={targetingParams}
        onOpenDrawerClick={() => {
          onDrawerOpen();
          setIsFormatTypeOpen(true);
        }}
        onRemoveClick={onRemoveAll}
        customValidationMethods={methods}
      />

      {(isOpen || isFormatTypeOpen) && (
        <TargetingDrawer
          name={name}
          onClose={() => {
            onDrawerClose();
            setIsFormatTypeOpen(false);
          }}
          onApply={() => {
            onDrawerApply();
            setIsFormatTypeOpen(false);
          }}
          isDataUpdated={isDataUpdated}
        >
          <TopBox
            distributionChannel={distributionChannel}
            adPlacement={adPlacement}
            targetingParams={params}
            setDistributionChannel={setDistributionChannel}
            setAdPlacement={setAdPlacement}
            updateParams={setParams}
          />
          <Items
            targetingParams={params}
            readonly={false}
            updateParams={setParams}
            setDistributionChannel={setDistributionChannel}
            setAdPlacement={setAdPlacement}
          />
        </TargetingDrawer>
      )}
    </>
  );
});
