import { Grid } from '@mui/material';

import { StatusIndicator } from '@openx/components/core';

import { Item, type SelectedItemComponent } from '../../shared';

export const AudienceItemWithStatus: SelectedItemComponent = props => {
  const { children, option, testId } = props;

  return (
    <Grid gap={1} wrap="nowrap" container>
      <Grid paddingRight={2} item xs>
        <Item {...props}>{children}</Item>
      </Grid>
      <Grid xs={2} item>
        <Grid paddingTop={0.25} justifyContent="flex-end" container>
          <StatusIndicator status={option?.status} data-test={testId?.replace('-item', '-status')} />
        </Grid>
      </Grid>
    </Grid>
  );
};
