import { styled } from '@mui/material/styles';

export const StyledTopBarContainer = styled('div')`
  .MuiGrid-container.MuiGrid-item.MuiGrid-grid-xs-4 {
    ${({ theme }) => theme.breakpoints.down(760)} {
      flex-basis: 66%;
      max-width: 100%;
    }
  }
`;
