import { styled } from '@mui/material';

export const TitleContainer = styled('div', { shouldForwardProp: prop => prop !== 'isDetailsPage' })<{
  isDetailsPage?: boolean;
}>`
  overflow: hidden;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: ${({ isDetailsPage }) => (isDetailsPage ? 'flex-start' : 'center')};
  justify-content: center;
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing(4)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  z-index: 1;
`;
