import { useTranslation } from 'react-i18next';

import { type TechnologyAndDevicesFormState, TechnologyAndDevicesOption } from '@openx/types';

import {
  CtvAlert,
  type CtvValidationReturnType,
  DimensionChipLine,
  TargetingReadOnly,
  useGetFilteredFields,
  isEmptyState,
} from '../shared';
import type { TargetingReadOnlyProps } from '../types';

import { Items } from './Items';

type CtvValidationMethods = Pick<
  CtvValidationReturnType,
  'setIsFormatTypeOpen' | 'shouldDisplayWarningInTechnographic'
>;

const defaultCustomValidationMethods: CtvValidationMethods = {
  setIsFormatTypeOpen: () => {},
  shouldDisplayWarningInTechnographic: false,
};

export const TechnologyAndDevicesReadOnly = ({
  name,
  onOpenDrawerClick,
  onRemoveClick,
  targetingParams,
  readonly,
  isDisabled,
  customValidationMethods = defaultCustomValidationMethods,
}: TargetingReadOnlyProps<TechnologyAndDevicesFormState, CtvValidationMethods>) => {
  const isEmpty = isEmptyState(targetingParams);
  const targetingParamsFiltered = useGetFilteredFields(targetingParams || {});
  const { t } = useTranslation();
  const { shouldDisplayWarningInTechnographic, setIsFormatTypeOpen } = customValidationMethods;

  return (
    <TargetingReadOnly
      name={name}
      readonly={readonly}
      isEmpty={isEmpty}
      onOpenDrawerClick={onOpenDrawerClick}
      onRemove={onRemoveClick}
      isDisabled={isDisabled}
    >
      {targetingParams &&
        !isEmpty &&
        targetingParamsFiltered.map((type, index) => {
          const shouldAddDimensionChipLine = targetingParamsFiltered.length - 1 > index;
          const shouldDisplayWarning =
            shouldDisplayWarningInTechnographic && type === TechnologyAndDevicesOption.DEVICE_TYPE;

          return (
            <div key={type}>
              <Items targetingParams={targetingParams} type={type} />

              {shouldDisplayWarning && (
                <CtvAlert
                  invalidField={t('Format Type')}
                  invalidParams={[t('Ad Placement = Video'), t('Distribution Channel = Application')]}
                  onOpen={setIsFormatTypeOpen}
                  dataTest={'ctv-alert-in-technology-and-devices'}
                />
              )}

              {shouldAddDimensionChipLine && <DimensionChipLine />}
            </div>
          );
        })}
    </TargetingReadOnly>
  );
};
