import { useFetchByGeoData } from './useSearchGeoLocations';

export const usePostalCodes = () => {
  const { fetchByGeoData } = useFetchByGeoData(true);

  const fetchPostalCodes = (param: { country: string; postal_codes: string[] }) => {
    const { country, postal_codes } = param;

    return fetchByGeoData({ country, postal_code: postal_codes.join(',') });
  };

  return { fetchPostalCodes };
};
