import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoComplete } from '@openx/components/core';
import { TechnologyAndDevicesOption } from '@openx/types';

import { CookiesOptions, DeviceIdOptions, HttpsOptions } from '../constants';
import { getCookiesLabelsMap, getDeviceIdLabelsMap, getHttpsLabelsMap } from '../utils';

type AudienceTypeDropdownProps = {
  onChange: (options: Set<string>, op?: string) => void;
  selectedType: TechnologyAndDevicesOption;
  value?: string;
};

const cookiesOptions = [CookiesOptions.accepted, CookiesOptions.notAccepted];
const deviceIdOptions = [DeviceIdOptions.enabled, DeviceIdOptions.limited];
const httpsOptions = [HttpsOptions.served, HttpsOptions.notServed];

export const SingleSelectDropdown = ({ onChange, selectedType, value }: AudienceTypeDropdownProps) => {
  const { t } = useTranslation();
  const cookiesOptionsLabels = getCookiesLabelsMap(t);
  const deviceIdOptionsLabels = getDeviceIdLabelsMap(t);
  const httpsOptionsLabels = getHttpsLabelsMap(t);
  const [selectedOption, setOption] = useState<string>(value ?? '');

  const optionsMap = {
    [TechnologyAndDevicesOption.DEVICE_ID]: {
      labels: deviceIdOptionsLabels,
      options: deviceIdOptions,
      placeholder: t('[ Select Device ID option ]'),
    },
    [TechnologyAndDevicesOption.HTTPS]: {
      labels: httpsOptionsLabels,
      options: httpsOptions,
      placeholder: t('[ Select Https option ]'),
    },
    [TechnologyAndDevicesOption.MKT_COOKIES]: {
      labels: cookiesOptionsLabels,
      options: cookiesOptions,
      placeholder: t('[ Select Cookies option ]'),
    },
  };

  const handleChange = useCallback(
    (val: string) => {
      onChange(new Set('1'), val);
    },
    [onChange]
  );

  // reset of the selected option in dropdown when the selectedType value changes
  useEffect(() => {
    setOption('');
  }, [selectedType]);

  return (
    <AutoComplete
      data-test="single-select-option"
      getOptionLabel={option => optionsMap[selectedType]?.labels[option]?.name ?? ''}
      onChange={(_, val) => {
        handleChange(val);
        setOption(val);
      }}
      options={optionsMap[selectedType].options}
      textFieldProps={{ placeholder: optionsMap[selectedType].placeholder }}
      value={selectedOption}
      disableClearable
    />
  );
};
