import { ITEMS_ROWS_LIMIT_READ_ONLY } from './constants';

export const calculateItemsMaxRows = (height: number): number => {
  const minRows = 10;
  const maxRows = 40;
  const otherDrawerElementsHeight = 380;
  const textRowHeight = 30;

  const rows = Math.floor((height - otherDrawerElementsHeight) / textRowHeight);

  return Math.min(Math.max(minRows, rows), maxRows);
};

/**
 * Calculates maxRows value for TextAreas which is a workaround for it not showing all text unless maxRows is passed.
 *
 * @param readonly - whether view is read only
 * @returns maxRows value based on window height so the text area fits
 *
 */
export const getItemsMaxRows = (readonly: boolean): number => {
  const rows = calculateItemsMaxRows(window.innerHeight);

  return readonly ? Math.min(ITEMS_ROWS_LIMIT_READ_ONLY, rows) : rows;
};
