export const BackgroundIcons = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 264.9755859 38.9282417"
    style={{
      left: '50%',
      margin: 'auto',
      maxWidth: '1204px',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%) translateX(-50%)',
      width: '100%',
      zIndex: 0,
    }}
  >
    <g>
      <g style={{ opacity: 0.15 }}>
        <rect x="2.7470703" y="4.2670898" style={{ fill: '#FFFFFF' }} width="2" height="1.5" />
        <g>
          <path
            style={{ fill: '#FFFFFF' }}
            d="M3,22.7641602h27.4975586V10.2670898H3V22.7641602z M28.9975586,11.7670898v9.4970703H4.5v-9.4970703
				H28.9975586z"
          />
          <rect x="16.359375" y="33.2641602" style={{ fill: '#FFFFFF' }} width="14.1381836" height="1.5" />
          <rect x="16.359375" y="30.2641602" style={{ fill: '#FFFFFF' }} width="14.1381836" height="1.5" />
          <rect x="16.359375" y="27.2641602" style={{ fill: '#FFFFFF' }} width="14.1381836" height="1.5" />
          <rect x="16.359375" y="24.2641602" style={{ fill: '#FFFFFF' }} width="7.8188477" height="1.5" />
          <path
            style={{ fill: '#FFFFFF' }}
            d="M0,8.7670898v28.9970703h33.4975586V8.7670898v-7.5H0V8.7670898z M1.5,8.7670898h30.4975586v27.4970703H1.5
				V8.7670898z M1.5,7.2670898v-4.5h30.4975586v4.5H1.5z"
          />
          <rect x="5.7470703" y="4.2670898" style={{ fill: '#FFFFFF' }} width="2" height="1.5" />
          <rect x="8.7470703" y="4.2670898" style={{ fill: '#FFFFFF' }} width="2" height="1.5" />
          <path
            style={{ fill: '#FFFFFF' }}
            d="M14.859375,32.0585938v-7.7944336H3v10.5h11.859375v-2.5014648l0.1020508-0.1020508L14.859375,32.0585938z
				 M13.359375,30.5582886l-1.2109375-1.2111206c-0.7788086-0.7773438-2.0439453-0.7773438-2.8217773,0l-1.0751953,1.0751953
				c-0.1875,0.1865234-0.5126953,0.1875-0.7006836,0c-0.777832-0.7763672-2.043457-0.7783203-2.8222656,0L4.5,30.6508789v-4.8867188
				h8.859375V30.5582886z M4.5,32.7719727l1.2890625-1.2890625c0.1875-0.1875,0.5136719-0.1875,0.7011719,0
				c0.7788086,0.7783203,2.0439453,0.7763672,2.8217773,0l1.0751953-1.0751953c0.1865234-0.1875,0.5131836-0.1875,0.7006836,0
				l2.2714844,2.2718506v0.5845947H4.5V32.7719727z"
          />
          <circle style={{ fill: '#FFFFFF' }} cx="7.8725586" cy="28.0688477" r="1" />
          <path
            style={{ fill: '#FFFFFF' }}
            d="M12.3193359,18.5634766h2.1621094l0.4326172,1.2280273h1.5693359l-2.3320312-6.5185547h-1.4936523
				l-2.3320312,6.5185547h1.5615234L12.3193359,18.5634766z M12.7724609,17.2592773l0.6308594-1.7890625l0.6259766,1.7890625
				H12.7724609z"
          />
          <path
            style={{ fill: '#FFFFFF' }}
            d="M20.7729492,19.4067383c0.5253906-0.2617188,0.9355469-0.6425781,1.2197266-1.1313477
				c0.2841797-0.4858398,0.4277344-1.0634766,0.4277344-1.7172852c0-0.652832-0.1435547-1.234375-0.4267578-1.7280273
				c-0.2851562-0.4985352-0.6943359-0.8867188-1.21875-1.1533203c-0.5146484-0.262207-1.1181641-0.3950195-1.7949219-0.3950195
				h-2.2128906v6.5097656h2.2128906C19.6538086,19.7915039,20.2573242,19.6621094,20.7729492,19.4067383z M18.9799805,14.5859375
				c0.6376953,0,1.1074219,0.1669922,1.4365234,0.5102539c0.3261719,0.3427734,0.4921875,0.8344727,0.4921875,1.4619141
				c0,0.6206055-0.1650391,1.1025391-0.4902344,1.4331055c-0.3242188,0.3291016-0.8085938,0.4960938-1.4384766,0.4960938h-0.7275391
				v-3.9013672H18.9799805z"
          />
        </g>
      </g>
      <g style={{ opacity: 0.2 }}>
        <path
          style={{ fill: '#FFFFFF' }}
          d="M109.919838,1.1669922H77.6449356c-1.284668,0-2.3295898,1.0449219-2.3295898,2.3295898v32.2744141
			c0,1.2841797,1.0449219,2.3291016,2.3295898,2.3291016h32.2749023c1.284668,0,2.3295898-1.0449219,2.3295898-2.3291016V3.496582
			C112.2494278,2.2119141,111.2045059,1.1669922,109.919838,1.1669922z M109.919838,36.6616211H77.6449356
			c-0.4907227,0-0.8896484-0.3994141-0.8896484-0.890625V8.152832h34.0546875v27.6181641
			C110.8099747,36.262207,110.4105606,36.6616211,109.919838,36.6616211z M77.6449356,2.6064453h32.2749023
			c0.4907227,0,0.8901367,0.3994141,0.8901367,0.8901367v3.2163086H76.7552872V3.496582
			C76.7552872,3.0058594,77.154213,2.6064453,77.6449356,2.6064453z"
        />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M80.1796036,5.3803711c0.1982422,0,0.3901367-0.0830078,0.4931641-0.2016602
			c0.144043-0.1298828,0.2265625-0.3188477,0.2265625-0.5180664c0-0.199707-0.0805664-0.3867188-0.2070312-0.4995117
			c-0.128418-0.1357422-0.3149414-0.2138672-0.5107422-0.2148438h-0.0019531c-0.1967773,0-0.3818359,0.0761719-0.4931641,0.1953125
			c-0.144043,0.1293945-0.2265625,0.3183594-0.2265625,0.519043c0,0.1977539,0.0820312,0.3886719,0.199707,0.4921875
			C79.7894669,5.2973633,79.97892,5.3803711,80.1796036,5.3803711z"
        />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M83.6922989,5.3803711c0.1982422,0,0.3896484-0.0830078,0.4921875-0.2011719
			c0.1445312-0.1298828,0.2275391-0.3188477,0.2275391-0.5185547c0-0.1928711-0.0756836-0.3754883-0.2138672-0.5141602
			c-0.1289062-0.1289062-0.3134766-0.2026367-0.5063477-0.2026367c0,0-0.0004883,0-0.0009766,0
			c-0.1928711,0.0004883-0.3774414,0.074707-0.5058594,0.2036133c-0.137207,0.1376953-0.2124023,0.3198242-0.2124023,0.5131836
			c0,0.1977539,0.0820312,0.3886719,0.199707,0.4921875C83.3021622,5.2973633,83.4916153,5.3803711,83.6922989,5.3803711z"
        />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M91.1498184,5.3793945h14.3266602c0.3969727,0,0.7202148-0.3227539,0.7202148-0.7197266
			s-0.3232422-0.7197266-0.7202148-0.7197266H91.1498184c-0.3969727,0-0.7202148,0.3227539-0.7202148,0.7197266
			S90.7528458,5.3793945,91.1498184,5.3793945z"
        />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M105.2787247,22.8793945c0.2348633-0.2695312,0.2348633-0.6757812,0.0004883-0.9443359
			c-0.2089844-0.2402344-5.184082-5.8862305-11.496582-5.8862305c-6.315918,0-11.2875977,5.6450195-11.4960938,5.8852539
			c-0.2348633,0.2695312-0.2348633,0.6757812-0.0004883,0.9453125c0.2080078,0.2402344,5.1708984,5.8847656,11.496582,5.8847656
			C100.0965958,28.7641602,105.0702286,23.1196289,105.2787247,22.8793945z M93.7826309,17.4882812
			c4.6196289,0,8.6088867,3.5610352,9.9609375,4.918457c-1.3500977,1.3583984-5.3349609,4.9189453-9.9609375,4.9189453
			c-4.6181641,0-8.6088867-3.5615234-9.9614258-4.9189453C85.1713028,21.0483398,89.1561661,17.4882812,93.7826309,17.4882812z"
        />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M96.997963,22.4106445c0-1.7749023-1.4443359-3.2192383-3.2192383-3.2192383
			c-1.7709961,0-3.2119141,1.4443359-3.2119141,3.2192383c0,1.7705078,1.440918,3.2109375,3.2119141,3.2109375
			C95.553627,25.621582,96.997963,24.1811523,96.997963,22.4106445z M93.7787247,24.1831055
			c-0.9770508,0-1.7719727-0.7949219-1.7719727-1.7724609c0-0.9814453,0.7949219-1.7797852,1.7719727-1.7797852
			c0.9814453,0,1.7797852,0.7983398,1.7797852,1.7797852C95.5585098,23.3881836,94.76017,24.1831055,93.7787247,24.1831055z"
        />
        <polygon
          style={{ fill: '#FFFFFF' }}
          points="80.0077286,11.2670898 84.07267,11.2670898 84.07267,9.7670898 78.5077286,9.7670898
			78.5077286,15.0859375 80.0077286,15.0859375 		"
        />
        <polygon
          style={{ fill: '#FFFFFF' }}
          points="108.2972794,15.2089844 109.7972794,15.2089844 109.7972794,9.644043 104.4784317,9.644043
			104.4784317,11.144043 108.2972794,11.144043 		"
        />
        <polygon
          style={{ fill: '#FFFFFF' }}
          points="80.1307755,29.7641602 78.6307755,29.7641602 78.6307755,35.3295898 83.9496231,35.3295898
			83.9496231,33.8295898 80.1307755,33.8295898 		"
        />
        <polygon
          style={{ fill: '#FFFFFF' }}
          points="108.4203262,33.706543 104.3553848,33.706543 104.3553848,35.206543 109.9203262,35.206543
			109.9203262,29.887207 108.4203262,29.887207 		"
        />
      </g>
      <g style={{ opacity: 0.25 }}>
        <path
          style={{ fill: '#FFFFFF' }}
          d="M170.815506,2.2495117c-9.5205078,0-17.265625,7.7456055-17.265625,17.2661133
			c0,9.5209961,7.7451172,17.2661133,17.265625,17.2661133h0.25h0.5V20.265625h16.5166016v-0.75
			C188.0821075,9.9951172,180.3360138,2.2495117,170.815506,2.2495117z M177.0102692,10.2615967
			c-1.6226196,0.7067871-3.4923096,1.1218262-5.4447632,1.2111816V3.8256836
			C173.810257,4.279541,175.7578278,6.7250366,177.0102692,10.2615967z M170.065506,11.4744873
			c-1.9661865-0.0872192-3.8317871-0.4961548-5.4485474-1.199646c1.2520142-3.5436401,3.2010498-5.994751,5.4485474-6.4491577
			V11.4744873z M164.1698151,11.7033081c1.7702637,0.7485962,3.7836914,1.1832886,5.8956909,1.2702026v5.7921143h-6.8977051
			C163.2268829,16.2102661,163.5822906,13.8087158,164.1698151,11.7033081z M178.8306427,11.0322876
			c1.2441406-0.6924438,2.3156128-1.5490723,3.1680908-2.5465088c2.6532593,2.6860962,4.3768921,6.2945557,4.5657959,10.2798462
			h-6.602478C179.900528,15.9469604,179.5033112,13.3217163,178.8306427,11.0322876z M178.351944,9.583252
			c-0.7843628-2.1312256-1.8179321-3.8977051-3.0300293-5.1625366c2.0635376,0.6279297,3.9495239,1.6834106,5.572876,3.0463257
			C180.2111359,8.2736206,179.3604889,8.9932251,178.351944,9.583252z M163.2766876,9.5910034
			c-1.0250854-0.6027222-1.8936157-1.3405762-2.5881958-2.1781616c1.6340942-1.3656616,3.5445557-2.409668,5.6487427-3.0237427
			C165.1114655,5.6574097,164.0674591,7.4395142,163.2766876,9.5910034z M162.8009796,11.0326538
			c-0.6724243,2.2893066-1.069519,4.9144287-1.1310425,7.7329712h-6.602478
			c0.1890259-4.0536499,1.8818359-7.666626,4.5193481-10.3380737
			C160.4470978,9.4462891,161.5314484,10.3273926,162.8009796,11.0326538z M162.7979889,27.9891968
			c-1.2705688,0.7062378-2.3556519,1.5888062-3.2163086,2.6091309c-2.6345215-2.6709595-4.3253174-6.2817383-4.5142212-10.3327026
			h6.602478C161.7313995,23.0802002,162.1272125,25.7022095,162.7979889,27.9891968z M163.2732086,29.4314575
			c0.7910767,2.1556396,1.8365479,3.9406128,3.0640259,5.2106934c-2.1066284-0.6148071-4.019043-1.6605835-5.6543579-3.0285645
			C161.3777008,30.7744141,162.2472076,30.0352783,163.2732086,29.4314575z M164.6136627,28.7468872
			c1.6173706-0.7044067,3.4840698-1.1140747,5.4518433-1.201355v7.6610107
			C167.8159943,34.7514648,165.8654938,32.2958984,164.6136627,28.7468872z M164.1670685,27.3179932
			c-0.5858154-2.1031494-0.9403076-4.5010376-0.9992676-7.0523682h6.8977051v5.7808838
			C167.9524689,26.1334839,165.9380035,26.5686035,164.1670685,27.3179932z M171.565506,12.9729614
			c2.121521-0.0861206,4.130127-0.5231934,5.8963623-1.2697144c0.5877075,2.1054688,0.9432373,4.506958,1.0023193,7.0623779
			h-6.8986816V12.9729614z"
        />
        <rect x="176.6719513" y="25.7954102" style={{ fill: '#FFFFFF' }} width="1.5205078" height="6.7246094" />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M183.2891388,32.121582c0.5400391-0.2675781,0.9648438-0.6611328,1.2597656-1.1679688
			c0.2929688-0.5029297,0.4414062-1.0986328,0.4414062-1.7734375s-0.1484375-1.2753906-0.4404297-1.7861328
			c-0.2939453-0.5146484-0.7177734-0.9160156-1.2587891-1.1914062c-0.5332031-0.2705078-1.1572266-0.4072266-1.8554688-0.4072266
			H179.15242v6.7246094h2.2832031C182.1338654,32.5200195,182.7578888,32.3862305,183.2891388,32.121582z M181.4356232,27.128418
			c0.6630859,0,1.1513672,0.1738281,1.4941406,0.53125c0.3398438,0.3564453,0.5126953,0.8681641,0.5126953,1.5205078
			c0,0.6445312-0.171875,1.1464844-0.5107422,1.4912109c-0.3427734,0.3466797-0.8320312,0.515625-1.4960938,0.515625h-0.7626953
			V27.128418H181.4356232z"
        />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M173.3037872,36.7290039h14.7246094V22.003418h-14.7246094V36.7290039z M186.5283966,23.503418v11.7255859
			h-11.7246094V23.503418H186.5283966z"
        />
      </g>
      <g style={{ opacity: 0.3 }}>
        <path
          style={{ fill: '#FFFFFF' }}
          d="M248.6591797,20.3696289h-10.7578125v10.5h10.7578125V20.3696289z M239.4013672,29.3696289v-7.5h7.7578125
			v7.5H239.4013672z"
        />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M261.8310547,0.0004883l-2.3945312,2.3945312L257.0439453,0l-2.3935547,2.3950195l-2.3945312-2.3945312
			l-2.3945312,2.3945312L247.46875,0l-2.3935547,2.3950195l-2.3945312-2.3945312l-2.3925781,2.3945312l-2.3945312-2.3945312
			l-3.1445312,3.1445312v14.2241211h-4.0253906v18.7963867c0,0.7763672,0.3291016,1.5214844,0.9042969,2.0449219
			c0.5585938,0.5048828,1.3125,0.7675781,2.0556641,0.7099609l27.9023438,0.0078125
			c1.8691406,0,3.3896484-1.5205078,3.3896484-3.3896484V3.1450195L261.8310547,0.0004883z M261.5859375,37.4282227h-25.6699219
			c0.2167969-0.4140625,0.3330078-0.8798828,0.3330078-1.3496094v-32.3125l1.6445312-1.6445312l2.3945312,2.3945312
			l2.3925781-2.3945312l2.3945312,2.3945312l2.3935547-2.394043l2.3925781,2.394043l2.3945312-2.3945312l2.3945312,2.3945312
			l2.3935547-2.394043l2.3925781,2.394043l2.3945312-2.3945312l1.6445312,1.6445312v31.7724609
			C263.4755859,36.5805664,262.6279297,37.4282227,261.5859375,37.4282227z M232.6367188,37.1000977
			c-0.2626953-0.2392578-0.4130859-0.5800781-0.4130859-0.9345703V18.8691406h2.5253906v17.2094727
			c0,0.6923828-0.4990234,1.2822266-1.1376953,1.3427734C233.2529297,37.4614258,232.9042969,37.3432617,232.6367188,37.1000977z"
        />
        <rect x="250.4453125" y="23.3696289" style={{ fill: '#FFFFFF' }} width="11.3447266" height="1.5" />
        <rect x="250.4453125" y="26.3696289" style={{ fill: '#FFFFFF' }} width="11.3447266" height="1.5" />
        <rect x="250.4453125" y="29.3696289" style={{ fill: '#FFFFFF' }} width="11.3447266" height="1.5" />
        <rect x="237.9013672" y="32.3696289" style={{ fill: '#FFFFFF' }} width="23.8886719" height="1.5" />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M254.4208984,5.8662109c-2.3984375,0-4.6347656,1.1728516-6.0078125,3.144043h-10.5117188v1.5h9.7128906
			c-0.1621094,0.4125977-0.2871094,0.8417969-0.3720703,1.2822266h-9.3408203v1.5h9.1992188
			c0.0068359,0.4204102,0.0527344,0.8505859,0.1376953,1.2827148h-9.3369141v1.5h9.796875
			c0.1943359,0.4506836,0.4326172,0.8798828,0.7119141,1.2822266h-10.5087891v1.5h11.8955078
			c0.9462891,0.7730713,2.0701294,1.2844849,3.2564697,1.512207h-2.6080322v1.5h11.3447266v-1.5h-6.0015869
			c1.1863403-0.2277222,2.3101807-0.7391357,3.2564697-1.512207h2.7451172v-1.5h-1.3583984
			c0.2802734-0.402832,0.5185547-0.8320312,0.7128906-1.2822266h0.6455078v-1.5h-0.1855469
			c0.0849609-0.4321289,0.1308594-0.8623047,0.1376953-1.2827148h0.0478516v-1.5h-0.1894531
			c-0.0849609-0.4404297-0.2099609-0.8696289-0.3720703-1.2822266h0.5615234v-1.5h-1.3603516
			C259.0556641,7.0390625,256.8193359,5.8662109,254.4208984,5.8662109z M254.4208984,7.3662109
			c3.2099609,0,5.8222656,2.6118164,5.8222656,5.8217773s-2.6123047,5.8212891-5.8222656,5.8212891
			s-5.8212891-2.6113281-5.8212891-5.8212891S251.2109375,7.3662109,254.4208984,7.3662109z"
        />
        <polygon
          style={{ fill: '#FFFFFF' }}
          points="251.4560547,16.362793 252.8857422,16.362793 252.8857422,9.9682617 250.7041016,9.9682617
			250.7041016,11.3095703 251.4560547,11.3095703 		"
        />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M255.2519531,14.0825195h0.7685547c0.5039062,0,0.9277344-0.090332,1.2578125-0.2680664
			c0.3398438-0.1821289,0.5927734-0.4248047,0.75-0.7216797c0.1533203-0.2885742,0.2314453-0.6000977,0.2314453-0.9257812
			c0-0.355957-0.0869141-0.684082-0.2568359-0.9750977c-0.1728516-0.2944336-0.4335938-0.5302734-0.7753906-0.7001953
			c-0.3320312-0.1650391-0.7382812-0.2485352-1.2070312-0.2485352h-2.1904297v6.1196289h1.421875V14.0825195z
			 M255.2519531,11.4951172h0.7685547c0.6992188,0,0.7929688,0.3398438,0.7929688,0.671875
			c0,0.2202148-0.0585938,0.3808594-0.1777344,0.4912109c-0.1240234,0.1142578-0.3310547,0.1723633-0.6152344,0.1723633h-0.7685547
			V11.4951172z"
        />
      </g>
    </g>
  </svg>
);
