import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { type VideoFormState, VideoOption, type RewardedVideoPayload, RewardedVideoOption } from '@openx/types';

import { SelectedItems, FiltersTitle, DimensionChipLine } from '../../../shared';
import { getRewardedVideoOptions } from '../../utils';

type PrevStateCallback = (prevState: VideoFormState) => VideoFormState;

type Props = {
  readonly?: boolean;
  params: RewardedVideoPayload;
  shouldAddDimensionLine: boolean;
  onChange?: (newData: VideoFormState | PrevStateCallback) => void;
};

export const RewardedVideoItem = ({
  params,
  readonly = true,
  shouldAddDimensionLine,
  onChange = () => {},
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const videoOptions = useMemo(() => getRewardedVideoOptions(t), [t]);
  const { op } = params;

  const handleChange = useCallback(() => {
    onChange(state => ({
      ...state,
      [VideoOption.REWARDED_VIDEO]: null,
    }));
  }, [onChange]);

  return (
    <>
      <FiltersTitle onClear={handleChange} title={t('Rewarded Video')} readonly={readonly} />
      <SelectedItems
        isAddIcon={op === RewardedVideoOption.EQUALS}
        selectedItems={[op]}
        readonly={readonly}
        handleChange={handleChange}
        options={videoOptions}
        dataTest="rewarded-video"
      />
      {shouldAddDimensionLine && <DimensionChipLine />}
    </>
  );
};
