import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoComplete } from '@openx/components/core';

import { AudienceTypeOption } from '../constants';
import { getAudienceTypeLabelsMap } from '../utils';

type AudienceTypeDropdownProps = {
  onChange: (value: string) => void;
  value?: string;
};

const options = [
  AudienceTypeOption.Under_13,
  AudienceTypeOption.Under_18,
  AudienceTypeOption.Over_17,
  AudienceTypeOption.AllAudiences,
  AudienceTypeOption.Over_12,
];

export const AudienceTypeDropdown = ({ onChange, value }: AudienceTypeDropdownProps) => {
  const { t } = useTranslation();
  const optionsLables = getAudienceTypeLabelsMap(t);

  const handleChange = useCallback(
    (val: string) => {
      onChange(val);
    },
    [onChange]
  );

  return (
    <AutoComplete
      data-test="audience-type-options"
      getOptionLabel={option => optionsLables[option].name}
      onChange={(_, val) => val && handleChange(val)}
      options={options}
      textFieldProps={{ placeholder: t('[ Select Audience Type ]') }}
      value={value}
    />
  );
};
