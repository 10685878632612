import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

export const StyledInfoRoundedIcon = styled(InfoRoundedIcon)`
  color: ${({ theme }) => theme.palette.text.hint};
`;

export const StyledNoItemsWrapperDiv = styled('div')`
  display: flex;
  align-items: center;
  height: 100%;

  gap: ${({ theme }) => theme.spacing(1)};
`;

export const NoFiltersInfoAlert = () => {
  const { t } = useTranslation();

  return (
    <StyledNoItemsWrapperDiv data-test="empty-targeting">
      <StyledInfoRoundedIcon fontSize="small" />
      <Typography>
        {t('No')} <strong>{t('Targeting Criteria')}</strong> {t('were applied.')}
      </Typography>
    </StyledNoItemsWrapperDiv>
  );
};
