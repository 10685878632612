import { Box } from '@mui/material';
import { styled, css } from '@mui/material/styles';

export const StyledBox = styled(Box, { shouldForwardProp: prop => prop !== 'isInside' && prop !== 'isSubsection' })<{
  isInside: boolean;
  isSubsection?: boolean;
}>`
  background-color: ${({ theme, isSubsection }) => (isSubsection ? theme.palette.error.main : theme.palette.divider)};
  border-radius: 50px;
  color: ${({ theme, isSubsection }) =>
    isSubsection ? theme.palette.primary.contrastText : theme.palette.text.secondary};
  display: inline-block;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  margin-left: ${({ theme }) => theme.spacing(3)};
  padding: 0 10px;
  font-size: 13px;
  line-height: ${({ theme }) => theme.spacing(3.5)};

  ${({ theme, isInside }) =>
    isInside &&
    css`
      margin: ${theme.spacing(1, 0)};
    `}
`;
