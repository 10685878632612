import { Alert, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledAlert = styled(Alert)`
  margin-top: ${({ theme }) => theme.spacing(1)};
  width: 100%;

  .MuiAlert-message {
    width: 100%;
    flex-grow: 1;
  }
`;

export const StyledAlertContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
  width: 100%;
`;

export const StyledInfoContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
