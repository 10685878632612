import { AdvancedTargetingData } from '@openx/types';

import { TargetingReadOnly } from '../shared';
import { TargetingReadOnlyProps } from '../types';

import { ContextualSegmentsItems } from './ContextualSegments/ContextualSegmentsItems';
import { CustomVariablesItems } from './CustomVariables/CustomVariablesItems';

export const AdvancedTargetingReadOnly = ({
  name,
  onOpenDrawerClick,
  onRemoveClick,
  targetingParams,
  isDisabled,
  readonly,
}: TargetingReadOnlyProps<AdvancedTargetingData>) => {
  return (
    <TargetingReadOnly
      name={name}
      isEmpty={!targetingParams}
      onOpenDrawerClick={onOpenDrawerClick}
      onRemove={onRemoveClick}
      readonly={readonly}
      isDisabled={isDisabled}
    >
      {!!targetingParams?.contextual?.length && <ContextualSegmentsItems targetingParams={targetingParams} />}
      {!!targetingParams?.custom?.length && <CustomVariablesItems targetingParams={targetingParams} />}
    </TargetingReadOnly>
  );
};
