import moment, { MomentInput } from 'moment';

export const computeEndDate = (date: string | null | undefined) => {
  return date ? moment(date).set({ hour: 23, minutes: 59 }).format() : undefined;
};

export const computeStartDate = (date: string) => {
  const todayDate = moment();
  const selectedDate = moment(date);

  return selectedDate.isSame(todayDate, 'day')
    ? selectedDate.format()
    : selectedDate.set({ hour: 0, minutes: 0 }).format();
};

export function daysToDate(date: MomentInput) {
  if (!date) {
    return null;
  }

  const daysRemaining = Math.ceil(moment.duration({ from: moment(), to: date }).asDays());

  return daysRemaining < 0 ? 0 : daysRemaining;
}

export function isSoonToDate(date: MomentInput, soonWindow = 10) {
  if (!date) {
    return false;
  }

  const daysRemaining = moment.duration({ from: moment(), to: date }).asDays();

  return daysRemaining <= soonWindow;
}

export const toUTCDate = (inputDate: MomentInput) => {
  return toUTCString(inputDate, 'YYYY-MM-DD');
};

export const toUTCDateTime = (inputDate: MomentInput) => {
  return toUTCString(inputDate);
};

export const toUTCString = (inputDate: MomentInput, pattern = 'YYYY-MM-DD HH:mm:ss') => {
  return moment(inputDate).seconds(0).utc(true).format(pattern);
};
