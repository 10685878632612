import { isEmpty, pick } from 'lodash';
import { useCallback, useState, useMemo, type ReactElement } from 'react';

import { GeographicOption, type GeoLocationsList, type GeographicTargetingState, GeoLocationTypes } from '@openx/types';

import { useCustomOptionsContext } from '../../../utils/hooks';
import { DrawerTopBox } from '../../shared';
import type { TargetingItemsProps } from '../../types';

import { BoundingBox } from './BoundingBox';
import { Location } from './Location';
import { LocationRadius } from './LocationRadius';
import { LocationSource } from './LocationSource';
import { LocationType } from './LocationType';

export const GeographicTopBox = ({
  targetingParams,
  onChange = () => {},
}: TargetingItemsProps<GeographicTargetingState>) => {
  const customOptions = useCustomOptionsContext<GeographicOption>();
  const [option, setOption] = useState<GeographicOption>(customOptions?.defaultOption ?? GeographicOption.INCLUDES);

  let opt = option;
  if (
    option === GeographicOption.INCLUDES &&
    Object.values(targetingParams.includes).every(isEmpty) &&
    !Object.values(targetingParams.excludes).every(isEmpty)
  ) {
    // INCLUDES by default, in case when there are only excludes items we switch to EXCLUDES
    opt = GeographicOption.EXCLUDES;
  }

  const onBulkApplyPostalCode = useCallback(
    (items: GeoLocationsList) => {
      const postalCodeValue = targetingParams[opt][GeoLocationTypes.POSTAL_CODE]
        ? [...targetingParams[opt][GeoLocationTypes.POSTAL_CODE]]
        : [];

      onChange({
        ...targetingParams,
        [opt]: {
          ...targetingParams[opt],
          [GeoLocationTypes.POSTAL_CODE]: [...postalCodeValue, ...items],
        },
      });
    },
    [onChange, opt, targetingParams]
  );

  const onSelect = useCallback(
    item => {
      switch (opt) {
        case GeographicOption.CIRCLES: {
          const circlesValue = targetingParams[opt] ? [...targetingParams[opt]] : [];

          onChange({
            ...targetingParams,
            [opt]: [...circlesValue, item],
          });

          break;
        }

        case GeographicOption.SOURCE: {
          onChange(params => ({ ...params, [GeographicOption.SOURCE]: item }));
          break;
        }

        case GeographicOption.BOUNDING_BOX: {
          onChange(params => ({ ...params, [GeographicOption.BOUNDING_BOX]: item }));
          break;
        }

        default: {
          const sectionValue = targetingParams[opt][item.type] ? [...targetingParams[opt][item.type]] : [];

          onChange({
            ...targetingParams,
            [opt]: {
              ...targetingParams[opt],
              [item.type]: [...sectionValue, item],
            },
          });
        }
      }
    },
    [onChange, opt, targetingParams]
  );

  const optionDropdown = useMemo(() => {
    return <LocationType value={option} onChange={setOption} />;
  }, [option]);

  let optionsTypes: Record<string, ReactElement> = useMemo(
    () => ({
      [GeographicOption.CIRCLES]: (
        <LocationRadius onSelect={onSelect} circles={targetingParams.circles}>
          {optionDropdown}
        </LocationRadius>
      ),
      [GeographicOption.INCLUDES]: (
        <Location targetingParams={targetingParams} onBulkApply={onBulkApplyPostalCode} onSelect={onSelect}>
          {optionDropdown}
        </Location>
      ),
      [GeographicOption.SOURCE]: (
        <LocationSource locationSourceState={targetingParams[GeographicOption.SOURCE]} onSelect={onSelect}>
          {optionDropdown}
        </LocationSource>
      ),
      [GeographicOption.BOUNDING_BOX]: (
        <BoundingBox boundingBoxState={targetingParams[GeographicOption.BOUNDING_BOX]} onChange={onSelect}>
          {optionDropdown}
        </BoundingBox>
      ),
    }),
    [onBulkApplyPostalCode, onSelect, optionDropdown, targetingParams]
  );

  if (customOptions) {
    optionsTypes = pick(optionsTypes, customOptions.availableOptions);
  }

  return <DrawerTopBox>{optionsTypes[option]}</DrawerTopBox>;
};
