import { ArrowForwardIos } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledIconButton = styled(IconButton)`
  padding: ${({ theme }) => theme.spacing(1)};
`;

export const StyledArrowForward = styled(ArrowForwardIos)`
  color: ${({ theme }) => theme.palette.text.secondary};
  height: 12px;
  width: 12px;
`;
