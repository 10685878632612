import { ColumnKey } from '../constants';

export const displayCellText = (key: ColumnKey, value: string | null | undefined): string => {
  switch (key) {
    case ColumnKey.AD_PLACEMENT:
      return value || 'Any (Banner or Video)';
    case ColumnKey.DISTRIBUTION_CHANNEL:
      return value || 'Any (Web or Application)';
    default:
      return value || 'Any';
  }
};
