import {
  DISPATCHER_USER_SESSION_LOCALSTORAGE_KEY,
  DSP_USER_SESSION_LOCALSTORAGE_KEY,
  INTERNAL_USER_SESSION_LOCALSTORAGE_KEY,
  PUBLISHER_USER_SESSION_LOCALSTORAGE_KEY,
  UNITY_GO_BACK_URL_KEY,
  USER_LOGIN_TYPE_KEY,
  USER_SSO_CONFIG_KEY,
  WORK_AS_ENDED_KEY,
  WORK_AS_USER_SESSION_LOCALSTORAGE_KEY,
} from './constants';

export function cleanLocalStorage(): void {
  localStorage.removeItem(DISPATCHER_USER_SESSION_LOCALSTORAGE_KEY);
  localStorage.removeItem(INTERNAL_USER_SESSION_LOCALSTORAGE_KEY);
  localStorage.removeItem(PUBLISHER_USER_SESSION_LOCALSTORAGE_KEY);
  localStorage.removeItem(DSP_USER_SESSION_LOCALSTORAGE_KEY);
  localStorage.removeItem(WORK_AS_USER_SESSION_LOCALSTORAGE_KEY);
  localStorage.removeItem(UNITY_GO_BACK_URL_KEY);
  localStorage.removeItem(WORK_AS_ENDED_KEY);
  localStorage.removeItem(USER_LOGIN_TYPE_KEY);
  localStorage.removeItem(USER_SSO_CONFIG_KEY);
}
