import { useMemo } from 'react';

import type { AdPlacement, DistributionChannel, Intersect } from '@openx/types';
import { useAdPlacementOptionsFetch, useDistributionChannelFetch, useDeviceTypesOptionsFetch } from '@openx/utils';

import type { DealLibraryItem, MappedDeviceType } from 'types';

import { mapAdPlacementLabels, mapDeviceTypeIdsToNames, mapDistributionChannelLabels } from '../utils';

export const useMappedDealTemplates = (dealTemplates: DealLibraryItem[]): DealLibraryItem[] => {
  const { adPlacement } = useAdPlacementOptionsFetch();
  const { distributionChannel } = useDistributionChannelFetch();
  const { deviceTypes } = useDeviceTypesOptionsFetch();

  const sortedDeviceTypesArray = useMemo(() => {
    return Object.values(deviceTypes)
      .map(({ id, name }) => ({ id, name }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [deviceTypes]) as MappedDeviceType[];

  const mappedDealTemplates = useMemo(() => {
    return dealTemplates.map(dealTemplate => {
      const { targeting, ...rest } = dealTemplate;
      const technographic = targeting?.technographic ?? {};
      const inventory_format = targeting?.inventory_format;

      const device_type = {
        name: mapDeviceTypeIdsToNames(technographic.device_type?.val ?? '', sortedDeviceTypesArray),
        op: (technographic.device_type?.op as Intersect) ?? null,
      };

      const distribution_channel = mapDistributionChannelLabels(
        inventory_format?.distribution_channel?.val ?? ('' as DistributionChannel),
        distributionChannel
      );

      const ad_placement = mapAdPlacementLabels(
        inventory_format?.ad_placement?.val ?? ('' as AdPlacement),
        adPlacement
      );

      return { ...rest, ad_placement, device_type, distribution_channel };
    });
  }, [dealTemplates, sortedDeviceTypesArray, distributionChannel, adPlacement]);

  return mappedDealTemplates;
};
