import { useCallback, useState } from 'react';

export function useBulkAdd<ItemType>({ onAdd }) {
  const [isBulkOpen, setOpenBulkAdd] = useState(false);

  const onBulkOpen = useCallback(() => setOpenBulkAdd(true), []);

  const onBulkClose = useCallback(() => {
    setOpenBulkAdd(false);
  }, []);

  const onBulkAdd = useCallback(
    (items: ItemType) => {
      setOpenBulkAdd(false);
      onAdd(items);
    },
    [onAdd]
  );

  return { isBulkOpen, onBulkAdd, onBulkClose, onBulkOpen };
}
