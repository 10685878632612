import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { type InventoryAndContentState, Intersect, type InventoryContentIdsTypeOption } from '@openx/types';

import { SelectedItems, Switch, FiltersTitle, DimensionChipLine, getItemsMaxRows } from '../../shared';
import { INVENTORY_IDS_DISPLAY_LIMIT, useInventoryAndContentLabels } from '../../shared/InventoryAndContent/constants';
import {
  setContentCategoryOp,
  resetFilter,
  setComparisonOpValue,
} from '../../shared/InventoryAndContent/inventoryHelpers';
import type { TargetingItemsProps } from '../../types';

interface InventoryIDsProps<T> extends TargetingItemsProps<T> {
  option: InventoryContentIdsTypeOption;
}

export const InventoryIDs = ({
  dispatchInventory = () => {},
  readonly = true,
  targetingParams,
  option,
  isNotLastItem,
  switchState: chipLineSwitchState,
  dataTest,
}: InventoryIDsProps<InventoryAndContentState>) => {
  const { t } = useTranslation();

  const optionPayload = targetingParams[option];
  const { op: dimension, val } = optionPayload;

  const labels = useInventoryAndContentLabels();
  const label = labels[option].label;

  const switchState = dimension === Intersect.NOT_INTERSECTS;
  const filtersTitle = dimension === Intersect.NOT_INTERSECTS ? t('blocked') : t('allowed');
  const itemsCount = val.length;

  const itemsMaxRows = getItemsMaxRows(readonly);

  return (
    <Grid data-test="sub-section">
      <FiltersTitle
        onClear={() =>
          dispatchInventory({
            payload: {
              contentOption: option,
            },
            type: resetFilter,
          })
        }
        title={`${label} [ ${itemsCount} ]`}
        readonly={readonly}
      />
      {!readonly && (
        <Switch
          switchState={switchState}
          onSwitchChange={() =>
            dispatchInventory({
              payload: switchState ? Intersect.INTERSECTS : Intersect.NOT_INTERSECTS,
              type: setContentCategoryOp[option],
            })
          }
          switchOffLabel={t('allowed')}
          switchOnLabel={t('blocked')}
        />
      )}

      <Grid data-test="section-items">
        <SelectedItems
          filtersTitle={filtersTitle}
          isAddIcon={dimension === Intersect.INTERSECTS}
          selectedItems={val}
          readonly={readonly}
          handleChange={values => {
            dispatchInventory({
              payload: values,
              type: setComparisonOpValue[option],
            });
          }}
          isRemovingAll={true}
          customDisplayLimit={INVENTORY_IDS_DISPLAY_LIMIT}
          dataTest={dataTest}
          //Forcing maxRows constraint on InventoryIDs because due to a bug in MUI it was not showing all text without it.
          //Should be removed by https://openxtechinc.atlassian.net/browse/UIG-469
          maxRows={itemsMaxRows}
        />
      </Grid>
      {isNotLastItem && <DimensionChipLine switchState={chipLineSwitchState} />}
    </Grid>
  );
};
