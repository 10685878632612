import { useMemo } from 'react';

import { InventoryAndContentState, InventoryContentOption } from '@openx/types';

import { useTargetingContext } from '../../utils';
import { TargetingReadOnly } from '../shared';
import { TargetingReadOnlyProps } from '../types';

import { InventoryAndContentItems } from './InventoryAndContentItems/InventoryAndContentItems';

export const InventoryAndContentReadOnly = ({
  name,
  onOpenDrawerClick,
  onRemoveClick,
  targetingParams,
  readonly,
  isDisabled,
}: TargetingReadOnlyProps<InventoryAndContentState>) => {
  const { useCurrentInstanceState } = useTargetingContext();

  const isEmpty = useMemo(() => {
    // check if targetingParams contains any elements
    const result =
      !targetingParams ||
      !(
        Object.values(targetingParams).some(t => {
          return t.val && Object.keys(t.val).length;
        }) ||
        targetingParams[InventoryContentOption.INVENTORY].excludes.length ||
        targetingParams[InventoryContentOption.INVENTORY].includes.length
      );
    return result;
  }, [targetingParams]);

  const { isBefInstance } = useCurrentInstanceState();

  return (
    <TargetingReadOnly
      name={name}
      isEmpty={isEmpty}
      onOpenDrawerClick={onOpenDrawerClick}
      onRemove={onRemoveClick}
      readonly={readonly}
      isDisabled={isDisabled}
    >
      {!isEmpty && targetingParams && (
        <InventoryAndContentItems targetingParams={targetingParams} isBefInstance={isBefInstance} />
      )}
    </TargetingReadOnly>
  );
};
