import { createAction } from '@reduxjs/toolkit';

import {
  type InventoryAndContentState,
  InventoryContentOption,
  ComparisonType,
  Criteria,
  type CriteriaValue,
  Intersect,
  TargetableInventoryOption,
  type TargetableInventoryList,
} from '@openx/types';

const SET_CONTENT = 'SET_CONTENT';
const RESET_CONTENT = 'RESET_CONTENT';
const RESET_FILTER = 'RESET_FILTER';
const SET_COMPARISON_OP_VALUE = 'SET_COMPARISON_OP_VALUE';
const SET_CONTENT_CATEGORY_OP = 'SET_CONTENT_CATEGORY_OP';
const SET_INVENTORIES = 'SET_INVENTORIES';
const SET_SELECTED_INSTANCE = 'SET_SELECTED_INSTANCE';
const CLEAR_SELECTED_INSTANCE = 'CLEAR_SELECTED_INSTANCE';
const SET_CONTENT_INTER_DIMENTION_OPERATOR = 'SET_CONTENT_INTER_DIMENTION_OPERATOR';

export const setContent = createAction<InventoryAndContentState>(SET_CONTENT);
export const resetContent = createAction<InventoryAndContentState>(RESET_CONTENT);
export const resetFilter = createAction<{ contentOption: InventoryContentOption }>(RESET_FILTER);
export const setSelectedInstance = createAction<string>(SET_SELECTED_INSTANCE);
export const clearSelectedInstance = createAction<string>(CLEAR_SELECTED_INSTANCE);
export const setContentInterDimentionOperator = createAction<CriteriaValue>(SET_CONTENT_INTER_DIMENTION_OPERATOR);

export const setComparisonOpValue = {
  [InventoryContentOption.APP_BUNDLE_ID]: createAction<{ values: string[]; comparisonType: ComparisonType }>(
    `${SET_COMPARISON_OP_VALUE}/${InventoryContentOption.APP_BUNDLE_ID}`
  ),
  [InventoryContentOption.PAGE_URL]: createAction<{ values: string[]; comparisonType: ComparisonType }>(
    `${SET_COMPARISON_OP_VALUE}/${InventoryContentOption.PAGE_URL}`
  ),
  [InventoryContentOption.ADUNIT]: createAction<string[]>(
    `${SET_COMPARISON_OP_VALUE}/${InventoryContentOption.ADUNIT}`
  ),
  [InventoryContentOption.ADUNIT_SIZE]: createAction<string[]>(
    `${SET_COMPARISON_OP_VALUE}/${InventoryContentOption.ADUNIT_SIZE}`
  ),
  [InventoryContentOption.PUBLISHER_ID]: createAction<string[]>(
    `${SET_COMPARISON_OP_VALUE}/${InventoryContentOption.PUBLISHER_ID}`
  ),
  [InventoryContentOption.INSTANCE_HASH]: createAction<string[]>(
    `${SET_COMPARISON_OP_VALUE}/${InventoryContentOption.INSTANCE_HASH}`
  ),
  [InventoryContentOption.SITE]: createAction<string[]>(`${SET_COMPARISON_OP_VALUE}/${InventoryContentOption.SITE}`),
  [InventoryContentOption.KEYWORDS]: createAction<string[]>(
    `${SET_COMPARISON_OP_VALUE}/${InventoryContentOption.KEYWORDS}`
  ),
  [InventoryContentOption.LINE_ITEM_APP_BUNDLE_ID]: createAction<string[]>(
    `${SET_COMPARISON_OP_VALUE}/${InventoryContentOption.LINE_ITEM_APP_BUNDLE_ID}`
  ),
};

export const setContentCategoryOp = {
  [InventoryContentOption.APP_BUNDLE_ID]: createAction<CriteriaValue>(
    `${SET_CONTENT_CATEGORY_OP}/${InventoryContentOption.APP_BUNDLE_ID}`
  ),
  [InventoryContentOption.PAGE_URL]: createAction<CriteriaValue>(
    `${SET_CONTENT_CATEGORY_OP}/${InventoryContentOption.PAGE_URL}`
  ),
  [InventoryContentOption.ADUNIT]: createAction<Intersect>(
    `${SET_CONTENT_CATEGORY_OP}/${InventoryContentOption.ADUNIT}`
  ),
  [InventoryContentOption.ADUNIT_SIZE]: createAction<Intersect>(
    `${SET_CONTENT_CATEGORY_OP}/${InventoryContentOption.ADUNIT_SIZE}`
  ),
  [InventoryContentOption.PUBLISHER_ID]: createAction<Intersect>(
    `${SET_CONTENT_CATEGORY_OP}/${InventoryContentOption.PUBLISHER_ID}`
  ),
  [InventoryContentOption.INSTANCE_HASH]: createAction<Intersect>(
    `${SET_CONTENT_CATEGORY_OP}/${InventoryContentOption.INSTANCE_HASH}`
  ),
  [InventoryContentOption.SITE]: createAction<Intersect>(`${SET_CONTENT_CATEGORY_OP}/${InventoryContentOption.SITE}`),
  [InventoryContentOption.KEYWORDS]: createAction<Intersect>(
    `${SET_CONTENT_CATEGORY_OP}/${InventoryContentOption.KEYWORDS}`
  ),
  [InventoryContentOption.LINE_ITEM_APP_BUNDLE_ID]: createAction<Intersect>(
    `${SET_CONTENT_CATEGORY_OP}/${InventoryContentOption.LINE_ITEM_APP_BUNDLE_ID}`
  ),
};

export const setInventories = {
  [TargetableInventoryOption.INCLUDES]: createAction<TargetableInventoryList>(
    `${SET_INVENTORIES}/${TargetableInventoryOption.INCLUDES}`
  ),
  [TargetableInventoryOption.EXCLUDES]: createAction<TargetableInventoryList>(
    `${SET_INVENTORIES}/${TargetableInventoryOption.EXCLUDES}`
  ),
};

export const getEmptyInventoryState = (): InventoryAndContentState => ({
  [InventoryContentOption.APP_BUNDLE_ID]: {
    op: Criteria.ANY,
    val: {},
  },
  [InventoryContentOption.LINE_ITEM_APP_BUNDLE_ID]: {
    op: Intersect.INTERSECTS,
    val: [],
  },
  [InventoryContentOption.PAGE_URL]: {
    op: Criteria.ALL,
    val: {},
  },
  [InventoryContentOption.ADUNIT]: {
    op: Intersect.INTERSECTS,
    val: [],
  },
  [InventoryContentOption.ADUNIT_SIZE]: {
    op: Intersect.INTERSECTS,
    val: [],
  },
  [InventoryContentOption.INVENTORY]: {
    [TargetableInventoryOption.INCLUDES]: [],
    [TargetableInventoryOption.EXCLUDES]: [],
  },
  [InventoryContentOption.CONTENT_INTER_DIMENSION_OPERATOR]: Criteria.ALL,
  [InventoryContentOption.PUBLISHER_ID]: {
    op: Intersect.INTERSECTS,
    val: [],
  },
  [InventoryContentOption.INSTANCE_HASH]: {
    op: Intersect.INTERSECTS,
    val: [],
  },
  [InventoryContentOption.SITE]: {
    op: Intersect.INTERSECTS,
    val: [],
  },
  [InventoryContentOption.KEYWORDS]: {
    op: Intersect.INTERSECTS,
    val: [],
  },
});
