import { Typography, TypographyProps } from '@mui/material';

interface TitleProps extends TypographyProps {
  title: string;
  ['data-test']?: string;
}

export const Title = ({ title, sx, ...props }: TitleProps): JSX.Element => {
  return (
    <Typography
      {...props}
      data-test={props['data-test'] ?? ''}
      variant="overline"
      sx={{
        color: '#666666',
        fontWeight: 'fontWeightBold',
        lineHeight: '28px',
        ...sx,
      }}
    >
      {title}
    </Typography>
  );
};
