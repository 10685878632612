import { Grid } from '@mui/material';

import { HighlightedPhrase } from '@openx/components/core';

import { StyledDetails } from './RowDescription.styled';

export const RowDescription = ({ description, phrase }): JSX.Element => {
  return (
    <Grid container>
      <StyledDetails data-test="description">
        <HighlightedPhrase searchPhrase={phrase}>{description}</HighlightedPhrase>
      </StyledDetails>
    </Grid>
  );
};
