import { Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

export interface DrawerDividerProps {
  gutterTop?: boolean;
  gutterBottom?: boolean;
}

const StyledDivider = styled(Divider, {
  shouldForwardProp: prop => prop !== 'gutterTop' && prop !== 'gutterBottom',
})<DrawerDividerProps>`
  margin: ${({ gutterTop, gutterBottom, theme }) => theme.spacing(gutterTop ? 1.5 : 0, -3, gutterBottom ? 1.5 : 0, -3)};
`;

export function DrawerDivider({ gutterBottom, gutterTop }: DrawerDividerProps): JSX.Element {
  return <StyledDivider gutterBottom={gutterBottom} gutterTop={gutterTop} />;
}
