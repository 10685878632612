import { Grid, styled } from '@mui/material';

export const StyledContainerGrid = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.success.light};
  padding: ${({ theme }) => theme.spacing(2)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

export const StyledGrid = styled(Grid)`
  display: flex;

  & > svg {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;
