import { Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

import { FiltersConfig } from '@openx/types';

const StyledMenu = styled(Menu)`
  margin-top: ${({ theme }) => theme.spacing(3.25)};
`;

interface MenuProps<FiltersValue extends {}> {
  filtersConfig: FiltersConfig;
  anchorEl: HTMLElement | null;
  onSelect: (categoryName: string) => void;
  onClose: () => void;
  open: boolean;
  activeFilters: FiltersValue;
}

export function FilterCategoryMenu<FiltersValue extends {}>({
  filtersConfig,
  anchorEl,
  onSelect,
  onClose,
  open,
  activeFilters,
}: MenuProps<FiltersValue>) {
  return (
    <StyledMenu
      id="filter-categories"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={onClose}
      anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
    >
      {Object.keys(filtersConfig).map((categoryName, id) => (
        <MenuItem
          onClick={() => onSelect(categoryName)}
          key={id}
          data-test="chip-filter-menu-item"
          disabled={!!activeFilters[categoryName]}
        >
          {filtersConfig[categoryName].name}
        </MenuItem>
      ))}
    </StyledMenu>
  );
}
