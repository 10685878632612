import { Grid } from '@mui/material';
import { omit, pick } from 'lodash';
import { useState, useMemo, SetStateAction, Dispatch, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@openx/components/core';
import {
  InventoryContentOption,
  type InventoryAndContentState,
  ComparisonType,
  type InventoryAndContentOptionPayload,
  type IntersectOptionPayload,
  type InstanceProps,
} from '@openx/types';

import { useCustomOptionsContext } from '../../../utils/hooks';
import { DrawerTopBox, useBulkAdd } from '../../shared';
import { INVENTORY_LIMITS } from '../../shared/InventoryAndContent/constants';
import { setComparisonOpValue } from '../../shared/InventoryAndContent/inventoryHelpers';
import { validateBulkInventoryIds } from '../../shared/InventoryAndContent/utils';
import { KeywordsInput } from '../../shared/KeywordsInput/KeywordsInput';
import { useKeywordValidation } from '../../shared/KeywordsInput/useKeywordValidation';
import type { TargetingItemsProps } from '../../types';

import { InventoryContentOptionDropdown } from './InventoryContentOptionDropdown';
import { AdunitSize, BulkDrawer, Comparison, Inventory, InventoryIDs } from './partials';

interface inventoryTopboxProps extends TargetingItemsProps<InventoryAndContentState> {
  inputInstanceValue: InstanceProps | null;
  setInputInstanceValue: Dispatch<SetStateAction<InstanceProps | null>>;
  isBefInstance: boolean;
  parentAccountFeatures: Record<string, boolean>;
}

export const InventoryAndContentTopBox = ({
  targetingParams,
  dispatchInventory = () => {},
  dispatchInstance = () => {},
  inputInstanceValue,
  setInputInstanceValue,
  isBefInstance,
  parentAccountFeatures,
}: inventoryTopboxProps) => {
  const { t } = useTranslation();
  const customOptions = useCustomOptionsContext<InventoryContentOption>();
  const [comparisonType, setComparisonType] = useState<ComparisonType>(ComparisonType.EQUALS);
  const [contentOption, setContentOption] = useState<InventoryContentOption>(
    customOptions?.defaultOption ?? InventoryContentOption.APP_BUNDLE_ID
  );

  const { validateBulkKeywords } = useKeywordValidation();

  const inventoryContent = targetingParams[contentOption] as InventoryAndContentOptionPayload | IntersectOptionPayload;
  const isOptionIdsType =
    contentOption === InventoryContentOption.PUBLISHER_ID ||
    contentOption === InventoryContentOption.INSTANCE_HASH ||
    contentOption === InventoryContentOption.ADUNIT ||
    contentOption === InventoryContentOption.LINE_ITEM_APP_BUNDLE_ID ||
    contentOption === InventoryContentOption.SITE;

  const comparisonFieldCriteria = useMemo(
    () => (Array.isArray(inventoryContent.val) ? inventoryContent.val : inventoryContent.val?.[comparisonType]),
    [comparisonType, inventoryContent.val]
  );

  const addItems = useCallback(
    (values: string[]) => {
      if (isOptionIdsType || contentOption === InventoryContentOption.KEYWORDS) {
        dispatchInventory({
          payload: [...(comparisonFieldCriteria || []), ...values],
          type: setComparisonOpValue[contentOption],
        });

        return;
      }

      const inventoryContentOptionComparisonData = inventoryContent.val[comparisonType] || [];
      const updatedValues = [...inventoryContentOptionComparisonData, ...values];

      dispatchInventory({
        payload: { comparisonType, values: updatedValues },
        type: setComparisonOpValue[contentOption],
      });
    },
    [comparisonFieldCriteria, comparisonType, contentOption, dispatchInventory, inventoryContent.val, isOptionIdsType]
  );

  const { isBulkOpen, onBulkAdd, onBulkClose, onBulkOpen } = useBulkAdd({ onAdd: addItems });

  const itemsCount = useMemo(() => {
    if (Array.isArray(inventoryContent?.val)) {
      return inventoryContent.val.length;
    }
    return Object.values(inventoryContent?.val || {}).reduce((acc, val) => (val ? acc + val.length : acc), 0);
  }, [inventoryContent?.val]) as number;

  const isLimitReached = itemsCount >= INVENTORY_LIMITS[contentOption];
  const optionsWithBulkAdd = [
    InventoryContentOption.ADUNIT,
    InventoryContentOption.APP_BUNDLE_ID,
    InventoryContentOption.PAGE_URL,
    InventoryContentOption.PUBLISHER_ID,
    InventoryContentOption.INSTANCE_HASH,
    InventoryContentOption.SITE,
    InventoryContentOption.KEYWORDS,
    InventoryContentOption.LINE_ITEM_APP_BUNDLE_ID,
  ];

  const showBulkAdd = optionsWithBulkAdd.includes(contentOption);

  /* eslint-disable sort-keys-fix/sort-keys-fix */
  let types: Record<string, JSX.Element> | null = {
    adunit: (
      <InventoryIDs
        inventoryContent={inventoryContent as IntersectOptionPayload}
        dispatchInventory={dispatchInventory}
        selectedType={InventoryContentOption.ADUNIT}
      />
    ),
    adunit_size: <AdunitSize targetingParams={targetingParams} dispatchInventory={dispatchInventory} />,
    app_bundle_id: (
      <Comparison
        contentOption={contentOption}
        comparisonType={comparisonType}
        dispatchInventory={dispatchInventory}
        inventoryContent={inventoryContent}
        itemsCount={itemsCount}
        setComparisonType={setComparisonType}
      />
    ),
    inventory: (
      <Inventory
        targetingParams={targetingParams}
        dispatchInventory={dispatchInventory}
        dispatchInstance={dispatchInstance}
        inputInstanceValue={inputInstanceValue}
        setInputInstanceValue={setInputInstanceValue}
      />
    ),
    page_url: (
      <Comparison
        contentOption={contentOption}
        comparisonType={comparisonType}
        dispatchInventory={dispatchInventory}
        inventoryContent={inventoryContent}
        itemsCount={itemsCount}
        setComparisonType={setComparisonType}
      />
    ),
    account: (
      <InventoryIDs
        inventoryContent={inventoryContent as IntersectOptionPayload}
        dispatchInventory={dispatchInventory}
        selectedType={InventoryContentOption.PUBLISHER_ID}
      />
    ),
    instance: (
      <InventoryIDs
        inventoryContent={inventoryContent as IntersectOptionPayload}
        dispatchInventory={dispatchInventory}
        selectedType={InventoryContentOption.INSTANCE_HASH}
      />
    ),
    site: (
      <InventoryIDs
        inventoryContent={inventoryContent as IntersectOptionPayload}
        dispatchInventory={dispatchInventory}
        selectedType={InventoryContentOption.SITE}
      />
    ),
    keywords: (
      <KeywordsInput
        keywords={targetingParams[InventoryContentOption.KEYWORDS].val}
        onChange={keywords => {
          dispatchInventory({
            payload: Array.from(keywords),
            type: setComparisonOpValue[InventoryContentOption.KEYWORDS],
          });
        }}
        keywordsLimit={INVENTORY_LIMITS.keywords}
      />
    ),
    line_item_app_bundle_id: (
      <InventoryIDs
        inventoryContent={inventoryContent as IntersectOptionPayload}
        dispatchInventory={dispatchInventory}
        selectedType={InventoryContentOption.LINE_ITEM_APP_BUNDLE_ID}
      />
    ),
  };

  const customValidation = useMemo(() => {
    if (contentOption === InventoryContentOption.KEYWORDS) {
      return (values: string[]) =>
        validateBulkKeywords(values, { generalKeywords: targetingParams[InventoryContentOption.KEYWORDS].val });
    }

    if (isOptionIdsType && isBefInstance) {
      return (values: string[]) =>
        validateBulkInventoryIds(values, inventoryContent as IntersectOptionPayload, contentOption);
    }

    return null;
  }, [isOptionIdsType, isBefInstance, inventoryContent, contentOption, validateBulkKeywords, targetingParams]);

  let publisherTargeting: Record<string, JSX.Element> | null = null;

  if (!isBefInstance) {
    publisherTargeting = omit(types, [InventoryContentOption.PUBLISHER_ID, InventoryContentOption.INSTANCE_HASH]);
  }

  if (customOptions) {
    types = pick(types, customOptions.availableOptions);
  }

  return (
    <>
      <DrawerTopBox>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item xs={3}>
            <InventoryContentOptionDropdown
              value={contentOption}
              onChange={setContentOption}
              parentAccountFeatures={parentAccountFeatures}
            />
          </Grid>

          <Grid item xs={9}>
            {publisherTargeting ? publisherTargeting[contentOption] : types[contentOption]}
          </Grid>

          {showBulkAdd && (
            <Grid item xs={12}>
              <Button disabled={isLimitReached} onClick={onBulkOpen}>
                {t('Bulk Add')}
              </Button>
            </Grid>
          )}
        </Grid>
      </DrawerTopBox>

      {isBulkOpen && comparisonType && (
        <BulkDrawer
          contentOption={contentOption}
          itemsCount={itemsCount}
          comparisonFieldCriteria={comparisonFieldCriteria}
          comparisonType={comparisonType}
          setContentOption={setContentOption}
          setComparisonType={setComparisonType}
          onBulkAdd={onBulkAdd}
          onBulkClose={onBulkClose}
          customValidation={customValidation}
          parentAccountFeatures={parentAccountFeatures}
        />
      )}
    </>
  );
};
