import { useMemo } from 'react';

export function useGetFilteredFields<T extends object>(targetingParams: T) {
  return useMemo(
    () =>
      Object.keys(targetingParams).filter(key => {
        if (targetingParams[key]?.val instanceof Set) return targetingParams[key]?.val.size;

        return targetingParams[key] && Object.keys(targetingParams[key]?.val).length;
      }) as Array<keyof T>,
    [targetingParams]
  );
}
