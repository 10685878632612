import { Typography, Paper, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { darken } from '@mui/system/colorManipulator';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@openx/components/core';

const StyledRemoveButton = styled(Button)`
  color: ${({ theme }) => theme.palette.error.main};
  margin-left: ${({ theme }) => theme.spacing(2)};

  :hover {
    color: ${darken('#D83158', 0.08)};
  }
`;

const StyledContainerPaper = styled(Paper, { shouldForwardProp: prop => prop !== 'isRequired' })<{
  isRequired?: boolean;
}>`
  box-shadow: 0px 2px 4px rgba(15, 15, 15, 0.08);
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  margin-top: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(2, 3)};
  border-color: ${({ isRequired, theme }) => (isRequired ? theme.palette.error.main : theme.palette.divider)};
`;

const StyledFilterDimensionTypography = styled(Typography)`
  margin-left: ${({ theme }) => theme.spacing(3)};
`;

interface TargetingReadOnlyProps {
  name: string | JSX.Element;
  isEmpty?: boolean;
  onOpenDrawerClick?: () => void;
  onRemove?: () => void;
  children?: ReactNode;
  readonly?: boolean;
  dataTest?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
}

const emptyF = () => {};

export const TargetingReadOnly = ({
  name,
  isEmpty = true,
  onOpenDrawerClick = emptyF,
  onRemove,
  children,
  readonly = false,
  dataTest,
  isDisabled = false,
  isRequired = false,
}: TargetingReadOnlyProps) => {
  const { t } = useTranslation();
  //TODO UIG-540 - Add OA Message edit audience above
  return (
    <StyledContainerPaper
      data-test={dataTest ?? `targeting-section-${name}`}
      variant="outlined"
      isRequired={isRequired}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid container item xs={6} alignItems="center">
          <Typography variant="h3">{name}</Typography>
          {isEmpty && (
            <StyledFilterDimensionTypography variant="body1" color="textSecondary">
              {t('Any')}
            </StyledFilterDimensionTypography>
          )}
        </Grid>
        {!readonly && (
          <Grid item>
            {isEmpty && (
              <Button
                variant="text"
                color="primary"
                type="button"
                onClick={onOpenDrawerClick}
                data-test="add-button"
                disabled={isDisabled}
              >
                {t('Add')}
              </Button>
            )}
            {!isEmpty && (
              <>
                <Button
                  variant="text"
                  color="primary"
                  type="button"
                  onClick={onOpenDrawerClick}
                  data-test="edit-button"
                  disabled={isDisabled}
                >
                  {t('Edit')}
                </Button>
                {onRemove && (
                  <StyledRemoveButton
                    variant="text"
                    color="secondary"
                    type="button"
                    onClick={onRemove}
                    data-test="remove-button"
                    disabled={isDisabled}
                  >
                    {t('Remove')}
                  </StyledRemoveButton>
                )}
              </>
            )}
          </Grid>
        )}
      </Grid>
      {children}

      {isRequired && (
        <Grid item>
          <Typography variant="body2" color="error">
            {t('{name} targeting is required', { name: name })}
          </Typography>
        </Grid>
      )}
    </StyledContainerPaper>
  );
};
