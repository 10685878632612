import { useTranslation } from 'react-i18next';

import { type OptionsMap, OptionsType } from '@openx/types';
import { useOptionsFetch } from '@openx/utils';

export type ExchangeSizeFetchResponse = {
  sizes: OptionsMap;
  isSizesLoading: boolean;
};

export function useExchangeSizeFetch(): ExchangeSizeFetchResponse {
  const { t } = useTranslation();
  const { isLoading: isSizesLoading, data: sizes } = useOptionsFetch({
    errorMessage: t('Failed to fetch Ad sizes'),
    optionType: OptionsType.EXCHANGE_SIZE_OPTIONS,
  });

  return {
    isSizesLoading,
    sizes,
  };
}
