import { Grid } from '@mui/material';
import { SxProps, Theme, styled } from '@mui/material/styles';

const StyledContainerGrid = styled(Grid)`
  background: ${({ theme }) => theme.palette.background.boxLight};
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  margin: 0 -24px;
  padding: ${({ theme }) => theme.spacing(1, 2.5)};
  width: auto;
`;

interface DrawerTopBoxProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

export const DrawerTopBox = ({ children, sx }: DrawerTopBoxProps): JSX.Element => {
  return (
    <StyledContainerGrid container sx={sx}>
      {children}
    </StyledContainerGrid>
  );
};
