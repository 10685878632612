import { useTranslation } from 'react-i18next';

import { type OptionsMap, OptionsType, type UseQueryOptions } from '@openx/types';

import { useOptionsFetch } from './generics';

export function useConnectionSpeedOptionsFetch(queryOptions?: UseQueryOptions<OptionsMap>) {
  const { t } = useTranslation();
  const {
    isLoading: isConnectionSpeedLoading,
    data: connectionSpeedOptions,
    refetch: refetchOptions,
  } = useOptionsFetch({
    errorMessage: t('Failed to fetch Connection Speed options'),
    optionType: OptionsType.CONNECTION_SPEED,
    queryOptions,
  });

  return {
    connectionSpeedOptions,
    isConnectionSpeedLoading,
    refetchOptions,
  };
}
