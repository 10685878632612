import { Intersect, ComparisonType } from './targetingValuesTypes';

export enum VideoOption {
  REWARDED_VIDEO = 'rewarded_video',
  MAX_AD_DURATION = 'adunit_max_duration',
  PRESENTATION_FORMAT = 'video_format',
  IN_STREAM_VIDEO_POSITION = 'screen_location',
  PLACEMENT = 'video_plcmt',
  VIDEO_SKIPABILITY = 'skip',
}

export const RewardedVideoOption = {
  DOES_NOT_EQUAL: ComparisonType.DOES_NOT_EQUAL,
  EQUALS: ComparisonType.EQUALS,
} as const;
export type RewardedVideoOptionKeys = (typeof RewardedVideoOption)[keyof typeof RewardedVideoOption];

export const VideoSkipabilityOption = {
  EQUALS: ComparisonType.EQUALS,
} as const;
export type VideoSkipabilityOptionKey = (typeof VideoSkipabilityOption)[keyof typeof VideoSkipabilityOption];

export enum VideoSkipabilityValue {
  SKIPPABLE = '1',
  NON_SKIPPABLE = '0',
}

export const payloadVideoTypes: Exclude<VideoOption, VideoOption.REWARDED_VIDEO | VideoOption.VIDEO_SKIPABILITY>[] = [
  VideoOption.MAX_AD_DURATION,
  VideoOption.PRESENTATION_FORMAT,
  VideoOption.IN_STREAM_VIDEO_POSITION,
  VideoOption.PLACEMENT,
];

export type VideoData = {
  [VideoOption.MAX_AD_DURATION]?: VideoDataPayload | null;
  [VideoOption.PRESENTATION_FORMAT]?: VideoDataPayload | null;
  [VideoOption.IN_STREAM_VIDEO_POSITION]?: VideoDataPayload | null;
  [VideoOption.REWARDED_VIDEO]?: RewardedVideoPayload | null;
  [VideoOption.PLACEMENT]?: VideoDataPayload | null;
  [VideoOption.VIDEO_SKIPABILITY]?: VideoSkipabilityPayload | null;
};

export type VideoFormState = {
  [VideoOption.MAX_AD_DURATION]: VideoStatePayload;
  [VideoOption.PRESENTATION_FORMAT]: VideoStatePayload;
  [VideoOption.IN_STREAM_VIDEO_POSITION]: VideoStatePayload;
  [VideoOption.REWARDED_VIDEO]: RewardedVideoPayload | null;
  [VideoOption.PLACEMENT]: VideoStatePayload;
  [VideoOption.VIDEO_SKIPABILITY]: VideoSkipabilityPayload | null;
};

export type VideoStatePayload = {
  op: Intersect;
  val: Set<string>;
};

export type RewardedVideoPayload = {
  op: RewardedVideoOptionKeys;
  val: string;
};

export type VideoSkipabilityPayload = {
  op: VideoSkipabilityOptionKey;
  val: VideoSkipabilityValue;
};

export type VideoDataPayload = {
  op: Intersect;
  val: string;
};
