import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoComplete } from '@openx/components/core';
import { TechnologyAndDevicesOption } from '@openx/types';

import { useCustomOptionsContext } from '../../../utils';
import { getTypeOptionsLabels } from '../utils';

type TypeOptionDropdownProps = {
  onChange: (type: TechnologyAndDevicesOption) => void;
  value: TechnologyAndDevicesOption;
};

export const TypeOptionDropdown = ({ onChange, value }: TypeOptionDropdownProps) => {
  const { t } = useTranslation();
  const customOptions = useCustomOptionsContext<TechnologyAndDevicesOption>();
  const labels = getTypeOptionsLabels(t);

  const availableOptions = useMemo(() => {
    const selectedOptions = {};
    customOptions?.availableOptions.forEach(option => (selectedOptions[option] = labels[option]));

    return selectedOptions;
  }, [customOptions, labels]);

  return (
    <AutoComplete<TechnologyAndDevicesOption, false, true, false>
      textFieldProps={{ label: t('Type') }}
      options={Object.keys(availableOptions) as TechnologyAndDevicesOption[]}
      renderOptions={{
        dataTest: 'type-option',
      }}
      getOptionLabel={option => availableOptions[option]}
      textFieldReadOnly
      disableClearable
      filterOptions={option => option}
      onChange={(_, value) => onChange(value)}
      value={value}
      fullWidth
      data-test="technology-and-devices-type"
    />
  );
};
