import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Grid, Tooltip, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@openx/components/core';
import { GeoLocationsList, GeoLocation, GeoLocationTypes, GeographicTargetingState } from '@openx/types';

import { useBulkAdd } from '../../shared';
import { convertGeoObjToArray } from '../utils';

import { BulkAdd } from './BulkAdd';
import { GeographicAutoComplete } from './GeographicAutoComplete';
import { GeographicType } from './GeographicType';

const StyledIconButton = styled(IconButton)`
  margin-left: ${({ theme }) => theme.spacing(1)};
`;

const StyledBulkBtnContainerGrid = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

type Props = {
  targetingParams: GeographicTargetingState;
  children?: ReactNode;
  onBulkApply: (items: GeoLocationsList) => void;
  onSelect: (item: GeoLocation) => void;
};

export function Location({ targetingParams, children, onBulkApply, onSelect }: Props) {
  const { t } = useTranslation();

  const [geographicType, setGeographicType] = useState<GeoLocationTypes>(GeoLocationTypes.CONTINENT);

  const { isBulkOpen, onBulkAdd, onBulkClose, onBulkOpen } = useBulkAdd<GeoLocationsList>({ onAdd: onBulkApply });
  const bulkDisabled =
    !isEmpty(targetingParams.excludes_subset) || !Object.values(targetingParams.excludes).every(isEmpty);
  const currentItems = !isEmpty(targetingParams.includes)
    ? convertGeoObjToArray(targetingParams.includes)
    : convertGeoObjToArray(targetingParams.excludes);

  return (
    <>
      <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={3}>
          {children}
        </Grid>
        <Grid item xs={9}>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item xs={3}>
              <GeographicType value={geographicType} onChange={setGeographicType} />
            </Grid>
            <Grid item xs={9} data-test="include-location">
              <GeographicAutoComplete
                targetingParams={targetingParams}
                onSelect={onSelect}
                geographicType={geographicType}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <StyledBulkBtnContainerGrid container alignItems="center">
        <Button onClick={onBulkOpen} disabled={bulkDisabled}>
          {t('Bulk Add')}
        </Button>
        <Tooltip
          title={t('Postal code only. Bulk Add is only available when there are no exclusions.')}
          data-test="bulk-icon"
        >
          <StyledIconButton size="small">
            <InfoRoundedIcon fontSize="small" />
          </StyledIconButton>
        </Tooltip>
      </StyledBulkBtnContainerGrid>
      {isBulkOpen && <BulkAdd onClose={onBulkClose} onBulkAdd={onBulkAdd} currentItems={currentItems} />}
    </>
  );
}
