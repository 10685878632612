import { isEqual } from 'lodash';

import { type CategoryFormState, Intersect, type UrlTargeting, UrlTargetingType } from '@openx/types';

type Props = {
  blockedCategories: string[];
  blockedDomains: string[];
  initState: CategoryFormState | UrlTargeting;
};

export const checkIsInheritingFromAccount = ({ blockedCategories, blockedDomains, initState }: Props) => {
  const isCategoryType = 'val' in initState;

  if (isCategoryType) {
    if (initState.val.size === 0 && new Set(blockedCategories).size === 0) return true;

    return isEqual(initState.val, new Set(blockedCategories)) && initState.op === Intersect.NOT_INTERSECTS;
  }

  if (!blockedDomains.length && !initState.urls.length) {
    return true;
  }

  return isEqual(initState.urls, blockedDomains) && initState.type === UrlTargetingType.blacklist;
};
