import { useMemo, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from '@openx/components/core';
import { InventoryContentOption } from '@openx/types';

import { INVENTORY_LIMITS } from '../../shared/InventoryAndContent/constants';

type InventoryInputProps = {
  onKeyUp: (e: KeyboardEvent<HTMLInputElement>) => void;
  option: InventoryContentOption;
  errorText: string;
  itemsCount: number;
};

export const InventoryInput = (props: InventoryInputProps) => {
  const { onKeyUp, option, errorText, itemsCount } = props;
  const { t } = useTranslation();

  const isLimitReached = itemsCount >= INVENTORY_LIMITS[option];

  const placeholder = useMemo(() => {
    const placeholders = {
      [InventoryContentOption.ADUNIT]: t('Enter an Ad Unit ID and press Enter'),
      [InventoryContentOption.APP_BUNDLE_ID]: t('Enter an app bundle ID and press Enter'),
      [InventoryContentOption.PAGE_URL]: t('Enter a page URL and press Enter'),
      [InventoryContentOption.SITE]: t('Enter an Site ID and press Enter'),
    };
    return isLimitReached
      ? t('The limit has been reached ({limit}).', { limit: INVENTORY_LIMITS[option] })
      : placeholders[option];
  }, [isLimitReached, option, t]);

  return (
    <TextField
      name="inventory_and_content"
      placeholder={placeholder}
      fullWidth
      margin="dense"
      data-test="inventory-and-content-input"
      autoComplete="off"
      onKeyUp={onKeyUp}
      style={{
        margin: 0,
        marginBottom: errorText ? '-20px' : '0px',
      }}
      error={!!errorText}
      helperText={errorText}
      disabled={isLimitReached}
    />
  );
};
