import { ApplicationPrefix } from '@openx/constants/paths';

export function getAppRootPath(): string {
  const pathname = new URL(window.location.href).pathname;

  if (pathname.includes(ApplicationPrefix.Unity)) {
    return ApplicationPrefix.Unity;
  } else if (pathname.includes(ApplicationPrefix.Publisher)) {
    return ApplicationPrefix.Publisher;
  } else if (pathname.includes(ApplicationPrefix.Dsp)) {
    return ApplicationPrefix.Dsp;
  } else {
    return '';
  }
}
