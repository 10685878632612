import type { Option } from '../options';

import type { CriteriaValue, Intersect } from './targetingValuesTypes';

export const ContentObjectOptions = {
  CHANNEL: 'channel',
  CONTENT_INTER_DIMENSION_OPERATOR: 'inter_dimension_operator',
  CONTENT_RATING: 'contentrating',
  EPISODE: 'episode',
  GENRE: 'genre',
  LANGUAGE: 'language',
  LENGTH: 'length',
  LIVESTREAM: 'livestream',
  NETWORK: 'network',
  SERIES: 'series',
} as const;

type ContentObjectKeys = keyof typeof ContentObjectOptions;

export type ContentObjectAction = {
  type: ContentObjectOption;
  values: any;
};

export type ContentObjectOption = (typeof ContentObjectOptions)[ContentObjectKeys];

export type IntersectApi = {
  op: Intersect;
  val: string[];
};

export type IntersectApiWrapper = {
  name: IntersectApi;
};

export type IntersectFormState<T = Set<string>> = {
  op: Intersect;
  val: T;
};

export type AllowBlockFormState = {
  allow: string[];
  block: string[];
  apiAllow?: string[];
  apiBlock?: string[];
};

export type AllowBlockApi = {
  allow: string[] | null;
  block: string[] | null;
};

export type AllowBlock = {
  allow: string[];
  block: string[];
};

type ExtendtedAllowBlock = AllowBlockApi & {
  apiAllow?: string[];
  apiBlock?: string[];
};

export type ContentObjectProps = {
  [ContentObjectOptions.GENRE]: AllowBlockFormState;
  [ContentObjectOptions.CONTENT_RATING]: IntersectFormState;
  [ContentObjectOptions.LIVESTREAM]: Option | null;
  [ContentObjectOptions.LENGTH]: null;
  [ContentObjectOptions.LANGUAGE]: IntersectFormState;
  [ContentObjectOptions.SERIES]: IntersectFormState;
  [ContentObjectOptions.EPISODE]: IntersectFormState<{ label: string; eps: number[] }[]>;
  [ContentObjectOptions.CHANNEL]: IntersectFormState;
  [ContentObjectOptions.NETWORK]: IntersectFormState;
  [ContentObjectOptions.CONTENT_INTER_DIMENSION_OPERATOR]: CriteriaValue;
};

export type ContentObjectApi = {
  [ContentObjectOptions.GENRE]: ExtendtedAllowBlock | null;
  [ContentObjectOptions.CONTENT_RATING]: IntersectApi | null;
  [ContentObjectOptions.LIVESTREAM]: '0' | '1' | null;
  [ContentObjectOptions.LENGTH]: null;
  [ContentObjectOptions.LANGUAGE]: IntersectApi | null;
  [ContentObjectOptions.SERIES]: IntersectApi | null;
  [ContentObjectOptions.EPISODE]: IntersectApi | null;
  [ContentObjectOptions.CHANNEL]: IntersectApiWrapper;
  [ContentObjectOptions.NETWORK]: IntersectApiWrapper;
  [ContentObjectOptions.CONTENT_INTER_DIMENSION_OPERATOR]: CriteriaValue;
};
