import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AnyAction } from '@reduxjs/toolkit';

import { InventoryAndContentState } from '@openx/types';

import { AdunitSizeDropdown } from '../AdunitSizeDropdown/AdunitSizeDropdown';

const DropdownContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

interface AdUnitSizeProps {
  targetingParams: InventoryAndContentState;
  dispatchInventory: (action: AnyAction) => void;
}

export const AdunitSize = ({ targetingParams, dispatchInventory }: AdUnitSizeProps): JSX.Element => (
  <DropdownContainer item xs={12}>
    <AdunitSizeDropdown targetingParams={targetingParams} dispatchInventory={dispatchInventory} />
  </DropdownContainer>
);
