import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Grid, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledContainerGrid = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

export const StyledExcludeItemsRemoveIconButton = styled(IconButton)`
  padding: 0;
`;

export const StyledExcludeItemsRemoveIcon = styled(RemoveCircleOutlineIcon)`
  color: ${({ theme }) => theme.palette.error.main};
  margin-top: none;
  margin-right: ${({ theme }) => theme.spacing(1.5)};
`;

export const StyledChildrenGrid = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(0.2)};
`;
