import { useMemo } from 'react';

import { GeographicTargetingState } from '@openx/types';

import { TargetingReadOnly } from '../shared';
import { TargetingReadOnlyProps } from '../types';

import { GeographicItems } from './GeographicItems';
import { isGeoEmpty } from './utils';

export const GeographicReadOnly = ({
  name,
  onOpenDrawerClick,
  onRemoveClick,
  targetingParams,
  readonly,
  isDisabled,
}: TargetingReadOnlyProps<GeographicTargetingState>) => {
  const isEmpty = useMemo(() => isGeoEmpty(targetingParams), [targetingParams]);

  return (
    <TargetingReadOnly
      name={name}
      isEmpty={isEmpty}
      onOpenDrawerClick={onOpenDrawerClick}
      onRemove={onRemoveClick}
      readonly={readonly}
      isDisabled={isDisabled}
    >
      {!isEmpty && targetingParams && <GeographicItems targetingParams={targetingParams} />}
    </TargetingReadOnly>
  );
};
