import { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoCompleteVirtualize } from '@openx/components/core';
import { GeoLocation } from '@openx/types';
import { getGeoLocationDisplayName } from '@openx/utils/state/geoLocationSearch';

import { useTargetingContext } from '../../../utils/context';

interface Props {
  onSelect: (item: GeoLocation) => void;
}

export const RadiusFindAutoComplete = ({ onSelect }: Props) => {
  const { t } = useTranslation();
  const [searchPhrase, setSearchPhrase] = useState('');
  const [inputPhrase, setInputPhrase] = useState('');
  const { useFetchBySearchText } = useTargetingContext();

  const { isLoading, geoSearchData } = useFetchBySearchText({
    radius: true,
    searchText: searchPhrase,
  });

  const noOptionsText = useMemo(() => {
    if (!searchPhrase || isLoading) {
      return t('Type to search');
    }
    return t('Nothing found');
  }, [searchPhrase, isLoading, t]);

  const onLocationAdd = useCallback(
    (e, item) => {
      if (item) {
        onSelect(item);
      }
    },
    [onSelect]
  );

  const onLocationClose = useCallback(() => {
    setSearchPhrase('');
    setInputPhrase('');
  }, []);

  const getOptionLabel = useCallback((option: GeoLocation) => getGeoLocationDisplayName(option) || '', []);
  const onDebounceChange = useCallback(({ target }) => setSearchPhrase(target.value), []);
  const onInputChange = useCallback(
    (e, value, reason) => (reason === 'input' || reason === 'clear') && setInputPhrase(value),
    []
  );

  return (
    <AutoCompleteVirtualize
      options={geoSearchData ?? []}
      blurOnSelect
      renderOptions={{
        dataTest: 'geo-location-option',
        tooltip: true,
      }}
      getOptionLabel={getOptionLabel}
      onDebounceChange={onDebounceChange}
      onInputChange={onInputChange}
      debounced
      textFieldProps={{
        label: t('Search'),
        placeholder: t('Enter City or Postal Code'),
      }}
      onChange={onLocationAdd}
      onClose={onLocationClose}
      loading={isLoading}
      disableClearable
      noOptionsText={noOptionsText}
      inputValue={inputPhrase}
    />
  );
};
