import { createRoot } from 'react-dom/client';

import { i18nConfigure, initGA } from '@openx/utils';

import { GA_TRACKING_ID, GA_ORIGIN_DOMAIN } from 'config';

import { App } from './App';

i18nConfigure();

initGA(GA_TRACKING_ID, !window.origin.includes(GA_ORIGIN_DOMAIN));

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(<App />);
