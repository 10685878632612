import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

import { ChangeSingleCriteriaAction, CriteriaDimension, PaginationCriteria } from '@openx/types';

import PaginationNav from '../PaginationNav';

export interface ManagementSectionProps {
  customManagementSection?: ReactNode;
  loading: boolean;
  paginationCriteria?: PaginationCriteria;
  onPaginationChange: ChangeSingleCriteriaAction<CriteriaDimension.PAGINATION>;
}

const StyledContainerGrid = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.background.boxLight};
  border-color: ${({ theme }) => theme.palette.divider};
  border-style: solid;
  border-width: 0;
  color: ${({ theme }) => theme.palette.text.secondary};
  height: 50px;
  padding: ${({ theme }) => theme.spacing(0, 2.5)};

  &:first-of-type {
    border-bottom-width: 1px;
  }

  *:not(:first-of-type) > &:first-of-type {
    border-top-width: 1px;
  }
`;

const StyledManagementSectionContainerGrid = styled(Grid)`
  flex-grow: 1;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export function ManagementSection(props: ManagementSectionProps): JSX.Element | null {
  const { customManagementSection, loading, paginationCriteria, onPaginationChange } = props;

  if (!customManagementSection && !paginationCriteria) {
    return null;
  }

  return (
    <StyledContainerGrid container alignItems="center">
      <StyledManagementSectionContainerGrid item>{customManagementSection}</StyledManagementSectionContainerGrid>
      <Grid item>
        {paginationCriteria && (
          <PaginationNav onChange={onPaginationChange} loading={loading} paginationParams={paginationCriteria} />
        )}
      </Grid>
    </StyledContainerGrid>
  );
}
