import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { type UrlTargeting, type CategoryFormState } from '@openx/types';
import { usePrevious } from '@openx/utils';

import { useTargetingContext } from '../../../utils/context';
import { InlineInfo } from '../../shared/InlineInfo';

import { checkIsInheritingFromAccount } from './utils';

type ResetToDefaultProps = {
  accountUid?: string;
  initState: CategoryFormState | UrlTargeting;
};

export const useResetToAccountDefault = ({ accountUid, initState }: ResetToDefaultProps) => {
  const path = window.location.pathname;
  const isTrafficSetPage = path.includes('/trafficsets');
  const isEditPage = path.includes('/edit');
  const isDetailsPage = path.includes('/details');
  const previousAccount = usePrevious(null);

  // default value added for OA, because it doesn't use this hook
  const { useAccountFetch = () => ({ account: null, isAccountLoading: false }) } = useTargetingContext();
  // hook is only used by Traffic Sets, to use it in other components you need to change the condition below
  const accountFetchHookPayload = useAccountFetch(isTrafficSetPage ? accountUid : '');
  const { account, isAccountLoading } = accountFetchHookPayload || {};
  const { t } = useTranslation();

  const shouldDisplayResetButton = !isAccountLoading && !!account;
  const shouldDisplayInfoComponent = isTrafficSetPage && accountUid;
  const isCategoryType = 'val' in initState;

  const shouldTriggerUpdate = useMemo(
    () => account !== previousAccount && !isEditPage && !isDetailsPage,
    [account, previousAccount, isEditPage, isDetailsPage]
  );

  const blockedCategories = useMemo(
    () => (account?.exchange?.blocked_adcategories ? Object.keys(account.exchange.blocked_adcategories) : []),
    [account]
  );

  const blockedDomains = useMemo(
    () => (account?.exchange?.domains ? Object.keys(account.exchange.domains) : []),
    [account]
  );

  const isInheritingFromAccount = useMemo(
    () => checkIsInheritingFromAccount({ blockedCategories, blockedDomains, initState }),
    [blockedCategories, blockedDomains, initState]
  );

  const inheritanceInfo = isInheritingFromAccount ? t('Inheriting') : t('Overriding');
  const objectTypeLabel = isCategoryType ? t('Category') : t('Domains');
  const infoText = (
    <>
      {t('This Line Item is')} <strong>{inheritanceInfo}</strong>{' '}
      {t("the Account's {objectTypeLabel} Settings", { objectTypeLabel })}
    </>
  );

  const inheritanceInfoComponent = shouldDisplayInfoComponent ? <InlineInfo infoText={infoText} /> : null;

  return {
    blockedCategories,
    blockedDomains,
    inheritanceInfoComponent,
    isAccountLoading,
    shouldDisplayResetButton,
    shouldTriggerUpdate,
  };
};
