import { useCallback, useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';

import {
  PackageFields,
  TargetingFields,
  GeographicTargetingApi,
  GeographicOption,
  TargetingLocation,
  TargetingLocationType,
} from '@openx/types';

import { useTargetingContext } from '../../../utils';

export const useCheckTargetingLocation = () => {
  const geographic: GeographicTargetingApi | null = useWatch({
    name: `${PackageFields.TARGETING}.${TargetingFields.GEOGRAPHIC}`,
  });

  const [targetingLocation, setTargetingLocation] = useState<TargetingLocationType>(TargetingLocation.UNSET);
  const [loading, setLoading] = useState<boolean>(true);

  const { useFetchByGeoData } = useTargetingContext();

  const { fetchByGeoData } = useFetchByGeoData();

  const isUsOnlyTargeting = useCallback(
    async (geographic: GeographicTargetingApi | null) => {
      if (!geographic) {
        return null;
      }

      if (geographic[GeographicOption.INCLUDES]) {
        const includedGeoData = await fetchByGeoData(geographic[GeographicOption.INCLUDES]);

        if (includedGeoData.some(item => item.country !== 'united states')) {
          return false;
        }
      }

      if (geographic[GeographicOption.EXCLUDES]) {
        const excludedGeoData = await fetchByGeoData(geographic[GeographicOption.EXCLUDES]);
        if (excludedGeoData.some(item => item.country === 'united states' && item.type === 'country')) {
          return false;
        }
      }

      return true;
    },
    [fetchByGeoData]
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      const usOnlyGeographic = await isUsOnlyTargeting(geographic);

      if (usOnlyGeographic === null) {
        setTargetingLocation(TargetingLocation.UNSET);
      } else {
        setTargetingLocation(usOnlyGeographic ? TargetingLocation.US_ONLY : TargetingLocation.GLOBAL);
      }

      setLoading(false);
    })();
  }, [geographic, isUsOnlyTargeting]);

  return { loading, targetingLocation };
};
