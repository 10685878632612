import { TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode, MouseEvent, KeyboardEvent } from 'react';

export const ICON_CELL_CLASS = 'iconCell';
const STOP_PROPAGATION = (e: MouseEvent | KeyboardEvent): void => e.stopPropagation();

const StyledTableCell = styled(TableCell)`
  text-align: center;
`;

export interface IconCellProps {
  className?: string;
  stopClickPropagation?: boolean;
  children?: ReactNode;
}

export function IconCell(props: IconCellProps): JSX.Element {
  const { stopClickPropagation, children, className } = props;
  const cellClassName = `${ICON_CELL_CLASS} ${className ? className : ''}`;

  return (
    <StyledTableCell className={cellClassName} onClick={stopClickPropagation ? STOP_PROPAGATION : undefined}>
      {children}
    </StyledTableCell>
  );
}
