import { styled } from '@mui/material';

export const Background = styled('div', { shouldForwardProp: prop => prop !== 'isHeader' })<{
  isHeader?: boolean;
}>`
  background: ${({ theme, isHeader }) =>
    isHeader
      ? 'linear-gradient(90deg, rgba(77, 130, 190, 1) 0%, rgba(79, 117, 178, 1) 40%, rgba(72, 68, 130, 1) 100%);'
      : `linear-gradient(135deg, ${theme.palette.background.dl_violet} 0%, ${theme.palette.background.dl_blue} 100%)`};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  padding: ${({ theme }) => theme.spacing(3, 4)};
  display: flex;
  flex-direction: column;
  min-height: ${({ isHeader }) => (isHeader ? '25vh' : '100vh')};
  position: relative;
`;
