import { cloneDeep, isEqual, omit } from 'lodash';
import { useState, useCallback, useMemo, memo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  type VideoData,
  type VideoFormState,
  VideoOption,
  type RewardedVideoPayload,
  type VideoStatePayload,
  type VideoSkipabilityPayload,
} from '@openx/types';

import { DimensionChipLine, EmptyFilterMessage, TargetingDrawer, useDrawer, useGetFilteredFields } from '../shared';
import { type TargetingProps } from '../types';

import { RewardedVideoItem, PlacementAndFormat, VideoSkipabilityItem } from './components';
import { placementAndFormat } from './constants';
import { Items } from './Items';
import { TopBox } from './TopBox';
import { isEmptyState, mapVideoToApi, mapVideoToFormState } from './utils';
import { VideoReadOnly } from './VideoReadOnly';

export const Video = memo(function Video({
  targetingParams,
  onFieldUpdate = () => {},
  name,
  readonly,
  field,
  isDisabled,
}: TargetingProps<VideoData>) {
  const { t } = useTranslation();

  const initState = useMemo(() => mapVideoToFormState(cloneDeep(targetingParams)), [targetingParams]);
  const [params, updateParams] = useState<VideoFormState>(initState);
  const [type, onTypeChange] = useState<VideoOption>(VideoOption.IN_STREAM_VIDEO_POSITION);
  const isDataUpdated = useMemo(() => !isEqual(targetingParams, mapVideoToApi(params)), [params, targetingParams]);

  const { isOpen, onDrawerClose, onDrawerOpen, onDrawerApply } = useDrawer({
    onApply: () => onFieldUpdate(field, mapVideoToApi(params)),
    restoreParams: () => updateParams({ ...initState }),
  });

  const onRemoveAll = useCallback(() => {
    onFieldUpdate(field, null);
    updateParams(mapVideoToFormState(null));
  }, [onFieldUpdate, field]);

  const onChange = useCallback(
    (typeParam: RewardedVideoPayload | VideoSkipabilityPayload | VideoStatePayload | null) => {
      updateParams(params => ({ ...params, [type]: typeParam }));
    },
    [type]
  );

  const targetingFields = omit(params, [VideoOption.REWARDED_VIDEO, VideoOption.VIDEO_SKIPABILITY]);

  const payloadVideoTypes = useGetFilteredFields(targetingFields || {});

  const hasBothPlcmntFormat = placementAndFormat.every(type => payloadVideoTypes.includes(type));

  const videoTypes = useMemo(
    () =>
      hasBothPlcmntFormat
        ? payloadVideoTypes.filter(type => type !== VideoOption.PLACEMENT && type !== VideoOption.PRESENTATION_FORMAT)
        : payloadVideoTypes,
    [hasBothPlcmntFormat, payloadVideoTypes]
  );

  const isStateEmpty = isEmptyState(params);

  return (
    <>
      <VideoReadOnly
        name={name}
        targetingParams={initState}
        onOpenDrawerClick={onDrawerOpen}
        onRemoveClick={onRemoveAll}
        readonly={readonly}
        isDisabled={isDisabled}
      />
      {isOpen && (
        <TargetingDrawer name={name} onClose={onDrawerClose} onApply={onDrawerApply} isDataUpdated={isDataUpdated}>
          <TopBox params={params} onChange={onChange} selectedType={type} onTypeChange={onTypeChange} />

          {isStateEmpty && <EmptyFilterMessage text={t('No filters applied.')} />}

          {!isStateEmpty &&
            videoTypes.map((type, index) => {
              const shouldAddDimensionChipLine =
                payloadVideoTypes.length - 1 > index ||
                !!params[VideoOption.REWARDED_VIDEO] ||
                !!params[VideoOption.VIDEO_SKIPABILITY] ||
                hasBothPlcmntFormat;

              return (
                !!params[type].val.size && (
                  <div key={type}>
                    <Items params={params[type]} type={type} readonly={!!readonly} onChange={updateParams} />

                    {shouldAddDimensionChipLine && <DimensionChipLine />}
                  </div>
                )
              );
            })}

          {hasBothPlcmntFormat && (
            <PlacementAndFormat
              placementAndFormat={placementAndFormat}
              params={params}
              updateParams={updateParams}
              readonly={!!readonly}
              shouldAddDimensionLine={!!params[VideoOption.REWARDED_VIDEO]}
            />
          )}

          {!isStateEmpty && !!params[VideoOption.REWARDED_VIDEO] && (
            <RewardedVideoItem
              params={params[VideoOption.REWARDED_VIDEO]}
              readonly={!!readonly}
              shouldAddDimensionLine={!!params[VideoOption.VIDEO_SKIPABILITY]}
              onChange={updateParams}
            />
          )}

          {!isStateEmpty && !!params[VideoOption.VIDEO_SKIPABILITY] && (
            <VideoSkipabilityItem
              params={params[VideoOption.VIDEO_SKIPABILITY]}
              readonly={!!readonly}
              onChange={updateParams}
            />
          )}
        </TargetingDrawer>
      )}
    </>
  );
});
