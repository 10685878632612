import axios from 'axios';

import { InventoryOptionPayloadApiFormat, TargetableInventoryList } from '@openx/types';

import { fetchApiResponseHandler } from '../../../api';

import { buildSearchQueryByText, buildSearchQueryFromInventoriesById } from './utils';

type MarketProps = {
  name: string;
  id?: string;
};

export const searchInventories = (
  isBefAndRoot = false,
  searchConfig: InventoryOptionPayloadApiFormat,
  instanceId?: string,
  accountUid?: string,
  searchById?: boolean,
  text?: string,
  extendQuery = '',
  limit?: number
): Promise<TargetableInventoryList> => {
  if (!Object.keys(searchConfig).length && searchById) {
    return Promise.resolve([]);
  }
  const params = new FormData();

  const searchPhrase = searchById
    ? buildSearchQueryFromInventoriesById(searchConfig)
    : buildSearchQueryByText(text, isBefAndRoot);

  params.append('q', `${searchPhrase} ${extendQuery}`);
  params.append('order', 'asc');
  params.append('sort', 'name.none');
  if (limit) {
    params.append('size', `${limit}`);
  }

  return isBefAndRoot
    ? fetchApiResponseHandler(
        axios.post(`/oxsearch/operator_targetable?include_networks=true&instance_uid=${instanceId}`, params),
        'hits.hits'
      )
    : fetchApiResponseHandler(
        axios.post(`/oxsearch/package_targetable?account_uid=${accountUid}`, params),
        'hits.hits'
      );
};

export const searchInventoriesInstances = () => {
  return fetchApiResponseHandler(
    axios.get('options/operator_instance_options?limit=500&overload=medium&sort=unsorted'),
    undefined,
    true
  );
};

export const searchInventoriesMarket = () => {
  return fetchApiResponseHandler(
    axios.get<MarketProps[]>('options/market_operators?limit=500&overload=medium&sort=unsorted'),
    undefined,
    true
  );
};
