import { Box, Typography } from '@mui/material';
import { styled, css } from '@mui/material/styles';
import { ReactNode, useCallback } from 'react';

import { CopyToClipboard } from '../CopyToClipboard';
import { HighlightedPhrase } from '../HighlightedPhrase';
import { Link, OutsideLink } from '../Link';
import { ValuePlaceholder } from '../ValuePlaceholder';

const StyledCellContainer = styled('div')`
  align-items: center;
  display: flex;
  padding-bottom: 0;
  padding-top: 0;

  &:hover {
    .styled-copy-icon-container {
      visibility: visible;
    }
  }
`;

const StyledRowSubtitle = styled(Typography, {
  shouldForwardProp: prop => prop !== 'disableBold',
})<{ disableBold: boolean }>`
  font-weight: ${({ theme, disableBold }) => (disableBold ? 'normal' : theme.typography.fontWeightBold)};
  margin-left: ${({ theme }) => theme.spacing(1)};
`;

const StyledCopyIconContainer = styled('span')`
  display: inline;
  margin-left: ${({ theme }) => theme.spacing(0.5)};
  visibility: hidden;
`;

const CellSubtitleWrapper = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(0.2)};
`;

const StyledContentWrapperBox = styled(Box, {
  shouldForwardProp: prop => prop !== 'isEllipsis',
})<{ isEllipsis: boolean }>`
  ${({ isEllipsis }) =>
    isEllipsis &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}
`;

const StyledBadgeTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: 10px;
  font-weight: 600;
  margin-top: -3px;
  padding-left: 5px;
  text-transform: uppercase;
  white-space: nowrap;
`;

interface NameCellProps {
  name?: string;
  pathTo?: string;
  id?: string;
  children?: ReactNode;
  searchPhrase?: string;
  subRowLabel?: string;
  isOutsideLink?: boolean;
  ellipsis?: boolean;
  disableBoldId?: boolean;
  PreTitleIconComponent?: JSX.Element;
  badge?: string;
}

export const NameCell = ({
  name,
  pathTo,
  id,
  children,
  searchPhrase = '',
  subRowLabel = 'ID:',
  isOutsideLink = false,
  ellipsis = false,
  disableBoldId = false,
  PreTitleIconComponent,
  badge,
}: NameCellProps) => {
  const CellLink = isOutsideLink ? OutsideLink : Link;

  const NameRender = useCallback(() => {
    if (name) {
      if (pathTo) {
        return (
          <CellLink data-test="row-title" to={pathTo}>
            {PreTitleIconComponent && PreTitleIconComponent}
            <HighlightedPhrase searchPhrase={searchPhrase}>{name}</HighlightedPhrase>
          </CellLink>
        );
      }
      return (
        <Box component={'span'} data-test="row-title">
          {PreTitleIconComponent && PreTitleIconComponent}
          <HighlightedPhrase searchPhrase={searchPhrase}>{name}</HighlightedPhrase>
        </Box>
      );
    }
    return <ValuePlaceholder />;
  }, [name, pathTo, PreTitleIconComponent, searchPhrase, CellLink]);

  return (
    <StyledCellContainer>
      <StyledContentWrapperBox isEllipsis={ellipsis}>
        <Box sx={{ display: 'flex' }}>
          <NameRender />
          {!!badge && <StyledBadgeTypography data-test="row-title-badge">{badge}</StyledBadgeTypography>}
        </Box>
        {id && (
          <CellSubtitleWrapper data-test="row-subtitle">
            <Typography variant="body2" color="textSecondary" display="inline">
              <HighlightedPhrase searchPhrase={searchPhrase}>{subRowLabel}</HighlightedPhrase>
            </Typography>
            <StyledRowSubtitle disableBold={disableBoldId} display="inline" variant="body2">
              <HighlightedPhrase searchPhrase={searchPhrase}>{String(id)}</HighlightedPhrase>{' '}
              <StyledCopyIconContainer className="styled-copy-icon-container">
                <CopyToClipboard text={id} />
              </StyledCopyIconContainer>
            </StyledRowSubtitle>
          </CellSubtitleWrapper>
        )}
        {children}
      </StyledContentWrapperBox>
    </StyledCellContainer>
  );
};
