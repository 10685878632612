import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Grid, styled } from '@mui/material';

export const StyledRowContainer = styled(Grid)`
  flex-wrap: nowrap;
  align-items: center;
`;

export const StyledIconContainer = styled(Grid)`
  display: flex;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const StyledRowTextContainer = styled(Grid)`
  & p {
    word-break: break-word;
    text-wrap: wrap;
  }
`;

export const StyledAddIcon = styled(AddCircleOutlineIcon)`
  color: ${({ theme }) => theme.palette.success.main};
`;

export const StyledRemoveIcon = styled(RemoveCircleOutlineIcon)`
  color: ${({ theme }) => theme.palette.error.main};
`;

export const StyledTagsSection = styled(Grid)`
  margin: ${({ theme }) => theme.spacing(1, 0)};
`;
