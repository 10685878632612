import { useMemo } from 'react';

import { Criteria, CriteriaValue } from '@openx/types';

import { StyledBox, StyledBoxContainer } from './components';
import { useGetDimensionLabels } from './hooks';

type DimensionChipLineProps = {
  targetingDimension?: CriteriaValue;
  switchState?: boolean;
  isSubsection?: boolean;
};

export const DimensionChipLine = ({
  targetingDimension = Criteria.ALL,
  switchState,
  isSubsection = false,
}: DimensionChipLineProps) => {
  const { dimensionLabels } = useGetDimensionLabels();

  const dimensionLabel = useMemo(() => {
    if (typeof switchState !== 'undefined') {
      return switchState ? dimensionLabels[Criteria.ANY] : dimensionLabels[Criteria.ALL];
    }

    return dimensionLabels[targetingDimension];
  }, [dimensionLabels, targetingDimension, switchState]);

  return (
    <StyledBoxContainer isSubsection={isSubsection}>
      <StyledBox component="span" data-test="targeting-dimension-line" isInside={false} isSubsection={isSubsection}>
        {dimensionLabel}
      </StyledBox>
    </StyledBoxContainer>
  );
};
