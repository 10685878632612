import { Grid, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode, useCallback, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, TextField } from '@openx/components/core';
import { GeoLocation, RadiusCircle } from '@openx/types';
import { getCircleDisplayName } from '@openx/utils/state/geoLocationSearch';

import { validateRadius } from '../utils';

import { RadiusFindAutoComplete } from './RadiusFindAutoComplete';

const StyledBoxAlignmentGrid = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

const StyledCancelSearchGrid = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

const StyledFindContainerGrid = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(1)};
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

const StyledInputsContainerGrid = styled(Grid)`
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

const StyledTextField = styled(TextField)`
  -moz-appearance: textfield;

  .MuiInputBase-inputAdornedEnd {
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
  }
`;

interface Props {
  children?: ReactNode;
  circles: RadiusCircle[];
  onSelect: (item: RadiusCircle) => void;
}

export function LocationRadius({ children, onSelect, circles }: Props) {
  const { t } = useTranslation();
  const [isSearching, setSearching] = useState(false);
  const [touchedCoordinates, setTouchedCoordinetes] = useState(false);
  const [coordinates, setCoordinates] = useState('');
  const [radius, setRadius] = useState('');
  const [coordinatesError, setCoordinatesError] = useState(false);
  const [radiusError, setRadiusError] = useState(false);
  const [duplicateError, setDuplicateError] = useState('');

  const onCoordinatesChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setCoordinates(event.target.value);
    setCoordinatesError(false);
  }, []);

  const onCoordinatesBlur = useCallback(() => {
    setTouchedCoordinetes(true);
    const [lat, lon, ...rest] = coordinates.split(',').map(parseFloat);
    setCoordinatesError(!validateRadius(lat, lon, rest));
    setDuplicateError('');
  }, [coordinates]);

  const onRadiusChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setRadius(value);
    setRadiusError(isNaN(Number(value)) || Number(value) < 1 || Number(value) > 7000);
    setDuplicateError('');
  }, []);

  const onAdd = useCallback(() => {
    const [lat, lon] = coordinates.split(',');
    const coord = { lat: lat.trim(), lon: lon.trim(), rad: radius };
    const coordName = getCircleDisplayName(coord);
    if (circles.some(cir => getCircleDisplayName(cir) === coordName)) {
      setDuplicateError(t('Duplicate coordinates'));
      return;
    }
    onSelect({ lat: lat.trim(), lon: lon.trim(), rad: radius });
    setCoordinates('');
    setRadius('');
    setTouchedCoordinetes(false);
  }, [circles, coordinates, onSelect, radius, t]);

  const toggleSearching = useCallback(() => {
    setSearching(!isSearching);
  }, [isSearching]);

  const onCoordinatesSelect = useCallback((item: GeoLocation) => {
    setTouchedCoordinetes(true);
    setCoordinates(`${item.latitude},${item.longitude}`);
    setCoordinatesError(false);
  }, []);

  const isAddDisabled = !touchedCoordinates || radiusError || coordinatesError || !radius || !coordinates;

  return (
    <>
      <StyledInputsContainerGrid container spacing={1}>
        <Grid item xs={3}>
          {children}
        </Grid>
        <Grid item xs={6} data-test="location-radius-coordinates">
          <TextField
            fullWidth
            label={t('Latitude and Longitude')}
            placeholder={t('Enter coordinates (example: 34.147785, -118.144516)')}
            onChange={onCoordinatesChange}
            onBlur={onCoordinatesBlur}
            value={coordinates}
            helperText={duplicateError}
            error={coordinatesError || !!duplicateError}
            data-test="duplicate-error"
          />
        </Grid>
        <StyledBoxAlignmentGrid item xs={2} data-test="radius-coords">
          <StyledTextField
            fullWidth
            placeholder={t('Radius')}
            InputProps={{
              endAdornment: <InputAdornment position="start">MI</InputAdornment>,
            }}
            variant={'outlined' as 'standard'} // MUI or TS bug/feature not accepting when textfield has input type props with |
            onChange={onRadiusChange}
            value={radius}
            error={radiusError || !!duplicateError}
          />
        </StyledBoxAlignmentGrid>
        <StyledBoxAlignmentGrid item xs={1}>
          <Button onClick={onAdd} disabled={isAddDisabled}>
            {t('Add')}
          </Button>
        </StyledBoxAlignmentGrid>
      </StyledInputsContainerGrid>
      <StyledFindContainerGrid container spacing={1} alignItems="center">
        <Grid item xs={3}>
          {!isSearching && <Button onClick={toggleSearching}>{t('Find Coordinates')}</Button>}
        </Grid>
        <Grid item xs={7} data-test="location-radius-search">
          {isSearching && <RadiusFindAutoComplete onSelect={onCoordinatesSelect} />}
        </Grid>
        <StyledCancelSearchGrid item xs={2}>
          {isSearching && (
            <Button onClick={toggleSearching} variant="text">
              {t('Cancel')}
            </Button>
          )}
        </StyledCancelSearchGrid>
      </StyledFindContainerGrid>
    </>
  );
}
