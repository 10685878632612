import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledGrid = styled(Grid)`
  padding-top: ${({ theme }) => theme.spacing(0.25)} !important;
`;

export const StyledButtonContainer = styled(Grid)`
  height: 100%;
  display: flex;
  align-items: flex-start;
`;
