import { TargetableInventoryEntities } from '@openx/types';

export const buildSearchQueryFromInventoriesById = (searchConfig: TargetableInventoryEntities): string => {
  const queries = Object.entries(searchConfig).map(value => {
    if (value[0] === 'network') {
      value[0] = 'account';
    }
    if (value[0] === 'page') {
      value[0] = 'adunitgroup';
    }
    const ids = value[1]
      .split(',')
      .map(id => `"${id}"`)
      .join(' OR ');

    return `(type:${value[0]} AND id:(${ids}))`;
  });

  return `(${queries.join(' OR ')})`;
};

export const buildSearchQueryByText = (text: string | undefined, isBefAndRoot: boolean) => {
  let searchText = '';
  if (text) {
    searchText = text;
  }
  const adunitGroupPart = isBefAndRoot ? '(type:adunitgroup AND delivery_medium_id:2)' : 'type:adunitgroup';
  return `name:*${searchText}* AND (${adunitGroupPart} OR type:account OR type:site OR type:adunit OR type:sitesection)`;
};
