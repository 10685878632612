import { AudienceData } from '@openx/types';

import { TargetingReadOnly } from '../shared';
import { TargetingReadOnlyProps } from '../types';

import { Items } from './Items';

export const AudienceReadOnly = ({
  name,
  onRemoveClick,
  onOpenDrawerClick,
  targetingParams,
  accountUid,
  readonly,
  isDisabled,
}: TargetingReadOnlyProps<AudienceData>) => {
  return (
    <TargetingReadOnly
      name={name}
      isEmpty={!targetingParams}
      onOpenDrawerClick={onOpenDrawerClick}
      onRemove={onRemoveClick}
      readonly={readonly}
      isDisabled={isDisabled}
    >
      {targetingParams && (
        <Items
          targetingParams={targetingParams as unknown as AudienceData}
          accountUid={accountUid as string}
          readonly={readonly}
        />
      )}
    </TargetingReadOnly>
  );
};
