import { isEmpty } from 'lodash';

import { AdPlacement, DistributionChannel, type FormatTypeData, InventoryFormatTypeOption } from '@openx/types';

export const countItems = (adPlacement?: AdPlacement[], distributionChannel?: DistributionChannel[]) =>
  (adPlacement?.length ?? 0) + (distributionChannel?.length ?? 0);

export const checkForEmptyParams = (params: FormatTypeData | null): boolean => {
  if (!params) return true;

  return (
    isEmpty(params[InventoryFormatTypeOption.AD_PLACEMENT]) &&
    isEmpty(params[InventoryFormatTypeOption.DISTRIBUTION_CHANNEL]) &&
    !!params.op
  );
};
