import { Intersect } from '@openx/types';

import { ColumnKey } from 'modules/DealLibraryList/constants';

import { StyledAddIcon, StyledIconContainer, StyledRemoveIcon, StyledRowContainer } from '../styled';

import { TextCellContainer } from './TextCellContainer';

export const DeviceTypeCellContainer = ({ device_type }): JSX.Element | null => {
  const isAddIcon = device_type.op === Intersect.INTERSECTS;
  const Icon = isAddIcon ? StyledAddIcon : StyledRemoveIcon;

  return (
    <StyledRowContainer container>
      {device_type?.name && (
        <StyledIconContainer item>
          <Icon fontSize="small" />
        </StyledIconContainer>
      )}

      <TextCellContainer displayValue={device_type.name} type={ColumnKey.DEVICE_TYPE} />
    </StyledRowContainer>
  );
};
