export const segmentExpiredText = fromDeal =>
  fromDeal
    ? 'Audience associated with a given deal has expired segment.'
    : 'Segment data is expired and cannot be used for audience creation.';

export const segmentSoonWillBeExpired = fromDeal =>
  fromDeal
    ? 'Audience associated with a given deal has segments that will expire in next 10 days.'
    : 'Segment is going to expire within the next 10 days.';

export const SegmentStatus = {
  AWAITING_DATA: 'awaiting_data',
  ERROR: 'error',
  EXPIRED: 'expired',
  PUBLISHING: 'publishing',
  READY: 'ready',
} as const;

export type Segment = {
  expiration_date?: Date;
  id: string;
  name: string;
  status?: string | null;
};
