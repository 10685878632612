import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { RHFSwitchField, IndentedSections, FieldDetails, RHFCheckboxField } from '@openx/components/core';
import { ACL_TYPE, isAllowedByCompiledAcl, usePermissionContext } from '@openx/utils/permission';

import { TargetingFields } from '../../types';

const StyledIndentedSections = styled(IndentedSections)`
  margin-top: 0;
`;

const StyledGridSwitch = styled(Grid, {
  shouldForwardProp: prop => prop !== 'showCheckbox',
})<{ showCheckbox: boolean }>`
  padding-left: ${({ theme }) => theme.spacing(0.5)};
  margin-bottom: ${({ showCheckbox, theme }) => (showCheckbox ? 0 : theme.spacing(1.5))};
`;

const StyledGridCheckbox = styled(Grid)`
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
  padding-left: ${({ theme }) => theme.spacing(0.5)};
`;

type ExcludeIndirectirectProps = {
  excludeIndirectValue: boolean;
  includeOpenBiddingInventory: boolean;
  onFieldUpdate?: (field: TargetingFields, newData: boolean) => void;
  readonly?: boolean;
};

export function ExcludeIndirect({
  excludeIndirectValue,
  includeOpenBiddingInventory,
  onFieldUpdate = () => {},
  readonly = false,
}: ExcludeIndirectirectProps) {
  const { isOa, useIsAllowed } = usePermissionContext();
  const { isAllowed } = useIsAllowed();

  const { t } = useTranslation();
  //TODO: UIG-540 Fix Perms
  const isAllowedToSee = isOa ?? isAllowed(isAllowedByCompiledAcl(ACL_TYPE.INTERNAL_FIELD_READ));
  const isAllowedToEdit = isOa ?? isAllowed(isAllowedByCompiledAcl(ACL_TYPE.INTERNAL_FIELD_WRITE));

  if (!isAllowedToSee) {
    return null;
  }

  if (readonly) {
    return (
      <>
        <StyledGridSwitch showCheckbox={excludeIndirectValue} item xs={12}>
          <FieldDetails title={t('Exclude Indirect Inventory')} toggle value={excludeIndirectValue} />
        </StyledGridSwitch>

        {!!excludeIndirectValue && (
          <StyledIndentedSections>
            <FieldDetails
              title={t('Include Google Open Bidding Inventory')}
              checkbox
              value={includeOpenBiddingInventory}
            />
          </StyledIndentedSections>
        )}
      </>
    );
  }

  return (
    <>
      <StyledGridSwitch showCheckbox={excludeIndirectValue} item xs={12}>
        <RHFSwitchField
          checked={excludeIndirectValue}
          data-test={'exclude-indirect-inventory'}
          name={`targeting.${TargetingFields.EXCLUDE_INDIRECT}`}
          label={t('Exclude Indirect Inventory')}
          disabled={!isAllowedToEdit}
          onChange={() => {
            onFieldUpdate(TargetingFields.EXCLUDE_INDIRECT, !excludeIndirectValue);
            onFieldUpdate(TargetingFields.INCLUDE_OPEN_BIDDING_INVENTORY, false);
          }}
        />
      </StyledGridSwitch>

      {excludeIndirectValue && (
        <StyledGridCheckbox display={'block'} item xs={12}>
          <StyledIndentedSections>
            <RHFCheckboxField
              name={`targeting.${TargetingFields.INCLUDE_OPEN_BIDDING_INVENTORY}`}
              label={t('Include Google Open Bidding Inventory')}
              onChange={() =>
                onFieldUpdate(TargetingFields.INCLUDE_OPEN_BIDDING_INVENTORY, !includeOpenBiddingInventory)
              }
              data-test="include-google-open-bidding-inventory"
              disabled={!isAllowedToEdit}
            />
          </StyledIndentedSections>
        </StyledGridCheckbox>
      )}
    </>
  );
}
