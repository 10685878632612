import { SetStateAction, useCallback, useEffect } from 'react';

import { type TargetingFields, type UrlTargeting, UrlTargetingType } from '@openx/types';

import { useResetToAccountDefault } from '../../shared/hooks/';

type ResetToDefaultProps = {
  accountUid?: string;
  field: TargetingFields;
  initState: UrlTargeting;
  onFieldUpdate: (field: TargetingFields, newData: UrlTargeting | null) => void;
  onRemoveAll: () => void;
  updateParams: (value: SetStateAction<UrlTargeting>) => void;
};

export const useResetDomainsToAccountDefault = ({
  accountUid,
  field,
  initState,
  onFieldUpdate,
  onRemoveAll,
  updateParams,
}: ResetToDefaultProps) => {
  const { blockedDomains, inheritanceInfoComponent, shouldDisplayResetButton, shouldTriggerUpdate } =
    useResetToAccountDefault({
      accountUid,
      initState,
    });

  const handleResetToAccountDefault = useCallback(() => {
    updateParams({
      type: UrlTargetingType.blacklist,
      urls: blockedDomains,
    });
  }, [blockedDomains, updateParams]);

  useEffect(() => {
    if (shouldTriggerUpdate) {
      if (!blockedDomains.length) {
        onRemoveAll();
        return;
      }

      const payload = {
        type: UrlTargetingType.blacklist,
        urls: blockedDomains,
      };

      updateParams(payload);
      onFieldUpdate(field, payload);
    }
  }, [blockedDomains, field, onRemoveAll, onFieldUpdate, shouldTriggerUpdate, updateParams]);

  return { handleResetToAccountDefault, inheritanceInfoComponent, shouldDisplayResetButton };
};
