import { useEffect, useRef } from 'react';

interface ConditionFuntion<T> {
  (value: T): boolean;
}

export const usePrevious = <T>(value: T, conditionalFunction: ConditionFuntion<T> = () => true): T => {
  const ref = useRef<T>(value);
  useEffect(() => {
    if (conditionalFunction(value)) {
      ref.current = value;
    }
  });
  return ref.current;
};
