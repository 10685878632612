import { useCallback, useContext } from 'react';

import { ConfirmationDialogContext } from './ConfirmationDialogProvider';
import type { ConfirmationDialogContextProps } from './ConfirmationDialogProvider';
import type { UseConfirmationDialogParams } from './types';

export type UseConfirmationDialogInitialParams = Partial<UseConfirmationDialogParams>;

export function useConfirmationDialog(
  initialParams?: UseConfirmationDialogInitialParams
): ConfirmationDialogContextProps {
  const { open: contextOpen, ...restContextProps } = useContext(ConfirmationDialogContext);

  const open = useCallback(
    (openParams?: UseConfirmationDialogInitialParams) =>
      contextOpen({
        ...initialParams,
        ...openParams,
      }),
    [initialParams, contextOpen]
  );

  return {
    open,
    ...restContextProps,
  };
}
