import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { OptionsType } from '@openx/types';
import { produceSortedOptionsKeys, useOptionsFetch } from '@openx/utils';

export function useSelectRateOptionsFetch(optionType: OptionsType.VIEWABILITY | OptionsType.VIEW_THROUGH_RATE) {
  const { t } = useTranslation();
  const optionTypeText = optionType === OptionsType.VIEWABILITY ? 'viewability' : 'view through rate';
  const { isLoading: isSelectRateLoading, data: selectRate } = useOptionsFetch({
    errorMessage: t('Failed to fetch {optionType} options', { optionType: optionTypeText }),
    optionType,
  });

  const optionsList = useMemo(() => produceSortedOptionsKeys(selectRate), [selectRate]);

  return {
    isSelectRateLoading,
    optionsList,
    selectRate,
  };
}
