import type { AclFlag, AclMapping, Session } from '@openx/types';

type SessionState = {
  details: Session;
  acl: {
    mapping: AclMapping;
    list: AclFlag[];
  };
};

export const session = (): SessionState => {
  return {
    acl: {
      list: [1],
      mapping: {
        'internal.field.write': 0,
      },
    },
    details: {
      instance: {
        experience: 'bef',
      },
      user: {
        account_uid: '606e46ed-acc0-fff1-8123-d7409f',
        acl_mapping: {
          'internal.field.write': 0,
        },
      },
    } as unknown as Session,
  };
};
