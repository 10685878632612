import { Typography } from '@mui/material';

import { ColumnKey } from 'modules/DealLibraryList/constants';
import { displayCellText } from 'modules/DealLibraryList/utils/displayCellText';

import { StyledRowTextContainer } from '../styled';

type TextCellContainerProps = {
  type: ColumnKey;
  displayValue?: string | null;
};

export const TextCellContainer = ({ type, displayValue }: TextCellContainerProps): JSX.Element | null => {
  const cellText = displayCellText(type, displayValue);

  return (
    <StyledRowTextContainer item>
      <Typography variant="body2">{cellText}</Typography>
    </StyledRowTextContainer>
  );
};
