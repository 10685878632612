import { Grid } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Criteria,
  ListIconType,
  type OptionEntriesProps,
  type TechnologyAndDevicesFormState,
  TechnologyAndDevicesOption,
  type ComparisonMap,
} from '@openx/types';

import { mapTypeToIconType, useComparisonLabels } from '../../constants';
import { SelectedItems, Switch, FiltersTitle, DimensionChip } from '../../shared';
import type { TargetingItemsProps } from '../../types';

export const UserAgentItems = ({
  targetingParams,
  readonly = true,
  onChange = () => {},
}: TargetingItemsProps<TechnologyAndDevicesFormState>) => {
  const { t } = useTranslation();
  const comparisonLabels = useComparisonLabels();

  const { op: dimension, val: optionsMap } = targetingParams[TechnologyAndDevicesOption.USER_AGENT];
  const switchState = dimension === Criteria.ANY;
  const optionsEntries: Array<OptionEntriesProps> = useMemo(() => Object.entries(optionsMap), [optionsMap]);
  const selectedItemsCount = useMemo(() => optionsEntries.map(option => option[1]).flat().length, [optionsEntries]);

  const handleChange = useCallback(
    (val: string[], comparisonType?: string) => {
      let newState: ComparisonMap = { ...optionsMap };
      const isRemovingGroup = !val.length && comparisonType;
      const isRemovingSingleOption = val.length && comparisonType;
      const isRemovingAll = !val.length && !comparisonType;

      if (isRemovingGroup) {
        delete newState[comparisonType];
      }

      if (isRemovingSingleOption) {
        newState[comparisonType] = val;
      }

      if (isRemovingAll) {
        newState = {};
      }

      onChange(state => ({
        ...state,
        [TechnologyAndDevicesOption.USER_AGENT]: {
          op: dimension,
          val: newState,
        },
      }));
    },
    [onChange, dimension, optionsMap]
  );

  const onSwitchChange = useCallback(() => {
    onChange(state => ({
      ...state,
      [TechnologyAndDevicesOption.USER_AGENT]: { op: switchState ? Criteria.ALL : Criteria.ANY, val: optionsMap },
    }));
  }, [onChange, switchState, optionsMap]);

  return (
    <div data-test="sub-section">
      <FiltersTitle
        onClear={() => handleChange([])}
        title={t('User Agent [ {count} ]', { count: selectedItemsCount })}
        readonly={readonly}
      />

      {!readonly && (
        <Switch
          switchState={switchState}
          onSwitchChange={onSwitchChange}
          switchOffLabel={t('all')}
          switchOnLabel={t('any')}
        />
      )}

      {optionsEntries.map(([comparisonType, items], index) =>
        items?.length ? (
          <Grid
            key={comparisonType}
            data-test={`section-items-${comparisonLabels[comparisonType].toLowerCase().replaceAll(' ', '-')}`}
          >
            <SelectedItems
              filtersTitle={comparisonLabels[comparisonType]}
              isAddIcon={mapTypeToIconType[comparisonType] === ListIconType.INCLUDE}
              selectedItems={items}
              readonly={readonly}
              handleChange={val => handleChange(val, comparisonType)}
              isRemovingAll={true}
              dataTest={'user-agent'}
            />

            {index < optionsEntries.length - 1 && <DimensionChip targetingDimension={dimension} isInsideOption />}
          </Grid>
        ) : null
      )}
    </div>
  );
};
