import { Typography } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';

import { Button } from '@openx/components/core';

import { StyledAlert, StyledAlertContainer, StyledInfoContainer } from './CtvAlert.styled';

type CtvAlertProps = {
  invalidField: string;
  invalidParams: string[];
  onOpen: (state: boolean) => void;
  dataTest?: string;
};

export const CtvAlert = ({ invalidField, invalidParams, onOpen, dataTest }: CtvAlertProps) => {
  const { t } = useTranslation();

  return (
    <StyledAlert severity="warning" data-test={dataTest}>
      <StyledAlertContainer>
        <StyledInfoContainer>
          <Typography variant="body1">
            <Trans t={t}>
              If you would like to target CTV, you need to set additional settings in <b>{invalidField}</b> targeting
              section:
            </Trans>
          </Typography>

          <Button variant="text" color="warning" type="button" onClick={() => onOpen(true)}>
            {t('Go to {invalidField}', { invalidField })}
          </Button>
        </StyledInfoContainer>

        {invalidParams.map(param => (
          <Typography key={param} variant="body1">
            {param}
          </Typography>
        ))}
      </StyledAlertContainer>
    </StyledAlert>
  );
};
