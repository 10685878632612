import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';

import { AutoComplete } from '@openx/components/core';
import { type ContentObjectOption, ContentObjectOptions } from '@openx/types';

import { getOptionName } from '../../constants';

type TypeOptionDropdownProps = {
  enabledTypes: string[];
  onChange: (type: ContentObjectOption) => void;
  value: ContentObjectOption;
};

export const TypeOptionDropdown = ({ enabledTypes, value, onChange }: TypeOptionDropdownProps): JSX.Element => {
  const { t } = useTranslation();

  const optionsOrderByDesign = [
    ContentObjectOptions.GENRE,
    ContentObjectOptions.CONTENT_RATING,
    ContentObjectOptions.LIVESTREAM,
    ContentObjectOptions.LENGTH,
    ContentObjectOptions.LANGUAGE,
    ContentObjectOptions.SERIES,
    ContentObjectOptions.EPISODE,
    ContentObjectOptions.CHANNEL,
    ContentObjectOptions.NETWORK,
  ];

  const dropdownOptions = Object.values(omit(ContentObjectOptions, 'CONTENT_INTER_DIMENSION_OPERATOR'))
    .filter(option => enabledTypes.includes(option))
    .sort((a, b) => optionsOrderByDesign.indexOf(a) - optionsOrderByDesign.indexOf(b));

  return (
    <AutoComplete<ContentObjectOption, false, true, false>
      textFieldProps={{ label: t('Type') }}
      options={dropdownOptions}
      renderOptions={{
        dataTest: 'type-option',
      }}
      getOptionLabel={option => getOptionName(option)}
      textFieldReadOnly
      disableClearable
      filterOptions={option => option}
      onChange={(_, value) => onChange(value)}
      value={value}
      fullWidth
      data-test="content-object-type"
    />
  );
};
