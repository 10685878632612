import { useTranslation } from 'react-i18next';

import { type OptionsMap, OptionsType, type UseQueryOptions } from '@openx/types';

import { useOptionsFetch } from './generics';

export function useDeviceOptionsFetch(queryOptions?: UseQueryOptions<OptionsMap>) {
  const { t } = useTranslation();
  const {
    isLoading: isDeviceOptionsLoading,
    data: deviceOptions,
    refetch: refetchDeviceOptions,
  } = useOptionsFetch({
    errorMessage: t('Failed to fetch Device options'),
    optionType: OptionsType.DEVICE,
    queryOptions,
  });

  return {
    deviceOptions,
    isDeviceOptionsLoading,
    refetchDeviceOptions,
  };
}
