import { useMemo } from 'react';

import type { ContentObjectProps } from '@openx/types';

import { TargetingReadOnly } from '../shared';
import type { TargetingReadOnlyProps } from '../types';

import { ContentObjectItems } from './ContentObjectItems';
import { filterEmptyParams } from './utils';

export const ContentObjectReadOnly = ({
  name,
  onOpenDrawerClick,
  onRemoveClick,
  targetingParams,
  readonly,
  isDisabled,
}: TargetingReadOnlyProps<ContentObjectProps>) => {
  const isEmpty = useMemo(
    () => !targetingParams || !Object.keys(filterEmptyParams(targetingParams)).length,
    [targetingParams]
  );

  return (
    <TargetingReadOnly
      name={name}
      isEmpty={isEmpty}
      onOpenDrawerClick={onOpenDrawerClick}
      onRemove={onRemoveClick}
      readonly={readonly}
      isDisabled={isDisabled}
    >
      {!isEmpty && targetingParams && <ContentObjectItems targetingParams={targetingParams} readonly />}
    </TargetingReadOnly>
  );
};
