import { useMemo } from 'react';

import type { RowActionButtonBaseProps } from '@openx/components/table';
import { trackCreateDealConversion } from '@openx/utils/lib';

import { useDealActivationModal } from 'components/DealActivationModal';
import type { DealLibraryItem } from 'types';

export function useDealLibraryRowActions(): Record<string, RowActionButtonBaseProps<DealLibraryItem> | undefined> {
  const { openDealActivationModal } = useDealActivationModal();

  const dealLibraryRowActions = useMemo(
    () => ({
      CREATE_DEAL: {
        'data-test': 'create-deal-button',
        label: 'Create Deal',
        onClick: (deal: DealLibraryItem) => {
          trackCreateDealConversion();
          openDealActivationModal(deal.name);
        },
      },
    }),
    [openDealActivationModal]
  );

  return dealLibraryRowActions;
}
