import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MetacategoryOptionsMap, OptionsType } from '@openx/types';
import { useOptionsFetch } from '@openx/utils';

import { prepareMetacategoryOptions } from '../../Targeting/Metacategories/utils';

export function useMetacategoriesFetch() {
  const { t } = useTranslation();
  const {
    isLoading: areMetacategoriesLoading,
    data: metacategories,
    refetch: refetchMetacategories,
  } = useOptionsFetch({
    errorMessage: t('Failed to fetch Metacategories'),
    optionType: OptionsType.METACATEGORIES,
    queryOptions: { enabled: false },
  });

  const preperedCategories = useMemo(
    () => prepareMetacategoryOptions(metacategories as unknown as MetacategoryOptionsMap),
    [metacategories]
  );

  return {
    areMetacategoriesLoading,
    metacategories: preperedCategories,
    refetchMetacategories,
  };
}
