import { OptionsType, SelectRateFormState } from '@openx/types';

import { SelectRate } from '../shared';
import { viewThroughRateTierLabel } from '../shared/SelectRate/constants';
import { TargetingFields, TargetingProps } from '../types';

export function ViewThroughRate({
  targetingParams,
  onFieldUpdate = () => {},
  name,
  readonly,
  isDisabled,
}: TargetingProps<SelectRateFormState>) {
  return (
    <SelectRate
      targetingParams={targetingParams}
      onFieldUpdate={onFieldUpdate}
      name={name}
      readonly={readonly}
      field={TargetingFields.VIEW_THROUGH_RATE}
      isDisabled={isDisabled}
      placeholder={viewThroughRateTierLabel}
      filterTitle={viewThroughRateTierLabel}
      optionsFetch={OptionsType.VIEW_THROUGH_RATE}
      dataTest="view-through-rate"
    />
  );
}
