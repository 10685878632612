import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from '@openx/components/core';
import { OptionsType, SelectRateFormState } from '@openx/types';

import { useTargetingContext } from '../../../utils/context/TargetingContext';
import { TargetingItemsProps } from '../../types';
import { FiltersTitle } from '../FiltersTitle';
import { SelectedItems } from '../SelectedItems';

interface SelectRateItemsProps extends TargetingItemsProps<SelectRateFormState> {
  title: string;
  dataTest?: string;
  optionsFetch: OptionsType.VIEWABILITY | OptionsType.VIEW_THROUGH_RATE;
}

export const Items = ({
  targetingParams,
  readonly = true,
  onChange = () => {},
  title,
  optionsFetch,
  dataTest,
}: SelectRateItemsProps) => {
  const { t } = useTranslation();
  const { useSelectRateOptionsFetch } = useTargetingContext();
  const { isSelectRateLoading, selectRate: options } = useSelectRateOptionsFetch(optionsFetch);

  const { val } = targetingParams;

  const handleChange = useCallback(() => {
    onChange(state => ({ ...state, val: '' }));
  }, [onChange]);

  if (isSelectRateLoading) {
    return <Loader />;
  }

  if (!val) {
    return null;
  }

  return (
    <div data-test="sub-section">
      <FiltersTitle onClear={handleChange} title={t(title)} readonly={readonly} />
      <SelectedItems
        dataTest={dataTest}
        isAddIcon
        selectedItems={[val]}
        readonly={readonly}
        handleChange={handleChange}
        options={options}
      />
    </div>
  );
};
