import { contextualSegmentPrefix, CustomVariablesData } from '@openx/types';

const knownPrefixes = [contextualSegmentPrefix];

export const getCustomVariables = (customVariables?: CustomVariablesData | null): CustomVariablesData | null => {
  if (!customVariables?.val || !Array.isArray(customVariables?.val)) return null;

  const customVars = customVariables.val.filter(
    customVar => !knownPrefixes.some(prefix => customVar.attr.startsWith(prefix))
  );

  return {
    ...customVariables,
    val: customVars,
  };
};
