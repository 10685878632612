import { useMemo } from 'react';
import { getSearchParamsObj } from 'utils';

import type { DealLibraryItem } from 'types';

import { mapSearchParamsToCriteria } from '../utils';

export const useGetCriteria = (dealTemplates: DealLibraryItem[], queryParams: URLSearchParams) => {
  const {
    params: { pageNumber, sort, column, phrase },
  } = getSearchParamsObj(queryParams);

  return useMemo(() => {
    return mapSearchParamsToCriteria({
      pagination: {
        pageNumber: Number(pageNumber) || 1,
        totalCount: dealTemplates.length,
      },
      phrase: phrase || '',
      sort: { column, direction: sort },
    });
  }, [pageNumber, sort, column, phrase, dealTemplates.length]);
};
