export enum GeoLocationTypes {
  CONTINENT = 'continent',
  COUNTRY = 'country',
  CITY = 'city',
  DMA = 'dma',
  MSA = 'msa',
  REGION = 'region',
  STATE = 'state',
  POSTAL_CODE = 'postal_code',
}

export interface GeoLocation {
  city?: string | null;
  code?: string | null;
  continent?: string | null;
  country?: string | null;
  dma?: string | null;
  id: string;
  latitude?: string | null;
  longitude?: string | null;
  msa?: string | null;
  name: string;
  region?: string | null;
  postal_code?: string | null;
  state?: string | null;
  type: string;
  'type-id': string;
}

export type GeoLocationsList = GeoLocation[];
export interface GeoLocationName extends Pick<GeoLocation, 'id' | 'type' | 'name'> {
  displayName: string;
}

export interface GeoLocationTree extends GeoLocation {
  childrens: GeoLocationsList;
}

export interface GeographyEntities {
  city?: string | null;
  continent?: string | null;
  country?: string | null;
  dma?: string | null;
  msa?: string | null;
  region?: string | null;
  state?: string | null;
  postal_code?: string | null;
}

export interface GeoExceptions {
  geographic: {
    includes?: GeographyEntities;
  };
}
