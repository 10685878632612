import { Grid } from '@mui/material';

import type { ContentObjectAction, ContentObjectOption, ContentObjectProps } from '@openx/types';

import type { UseOptionFetch } from '../../../../utils/context/TargetingHookTypes';
import { OptionsDropdown } from '../../../shared';
import { getOptionName, inputPlaceholder } from '../../constants';

export const StandardDropdown = (props: {
  type: ContentObjectOption;
  formState: Pick<ContentObjectProps, 'contentrating' | 'language'>;
  useOptionFetch: UseOptionFetch;
  dispatch: (action: ContentObjectAction) => void;
}) => {
  const { useOptionFetch, dispatch, formState, type } = props;
  const { loading, options } = useOptionFetch(type);

  const selectedOptions = formState[type]?.val || new Set<string>();

  return (
    <Grid item xs={9}>
      <OptionsDropdown
        loading={loading}
        options={options}
        onChange={ids => {
          dispatch({ type, values: ids });
        }}
        selectedOptions={selectedOptions}
        label={getOptionName(type)}
        placeholder={inputPlaceholder(type)}
        dataTest="content-object-input"
      />
    </Grid>
  );
};
