import { useQuery } from '@tanstack/react-query';

import { ReactQueryStaleTime } from '@openx/constants/react-query';
import type { OptionsMap, OptionsType, UseQueryOptions } from '@openx/types';

import { prepareMapBy } from '../../lib/prepareMapBy';
import { produceSortedOptions } from '../../lib/sortedOptions';
import { fetchOptions } from '../../state/options';

const FALLBACK_DATA: OptionsMap = {};

type UseOptionsFetchParams = {
  errorMessage: string;
  mapByKey?: string;
  optionType: OptionsType;
  queryOptions?: UseQueryOptions<OptionsMap>;
};

export function useOptionsFetch({
  errorMessage,
  mapByKey = 'id',
  optionType,
  queryOptions = {},
}: UseOptionsFetchParams) {
  const { isLoading, data, refetch } = useQuery<OptionsMap>({
    meta: {
      errorMessage,
    },
    queryFn: async () => {
      const options = await fetchOptions(optionType);
      const mappedOptions = prepareMapBy(options, mapByKey);

      return produceSortedOptions(mappedOptions);
    },
    queryKey: [optionType],
    staleTime: ReactQueryStaleTime.MINUTES_15,
    ...queryOptions,
  });

  return {
    data: data || FALLBACK_DATA,
    isLoading,
    refetch,
  };
}
