import type { Store } from 'redux';
import { push } from 'redux-first-history';

import { getAppRootPath } from '../lib/getAppRootPath';
import { removeOIDCAuthInfoFromStorage } from '../lib/oidc/getOIDCAuthInfoFromStorage';
import { cleanLocalStorage } from '../state/session/cleanLocalStorage';

const notAlloweRedirectionPathsRegexp = new RegExp(/sso-redirect/g);
const notAllowedCallbackUrlRegexp = new RegExp(/sso-redirect|login|response-oidc/g);

export function logoutOnError(store?: Store): void {
  const isAllowedToRedirect = !notAlloweRedirectionPathsRegexp.test(window.location.pathname);

  if (!isAllowedToRedirect) {
    return;
  }

  const url = new URL(window.location.href);
  const callbackParam = new URLSearchParams();

  if (!notAllowedCallbackUrlRegexp.test(url.toString()) && url.pathname !== '/') {
    callbackParam.set('callbackUrl', url.toString());
  }

  removeOIDCAuthInfoFromStorage();
  cleanLocalStorage();

  const callbackParamString = callbackParam.toString();
  const ssoRedirectUrl = callbackParamString
    ? `${getAppRootPath()}/sso-redirect?${callbackParamString}`
    : '/sso-redirect';

  if (store) {
    store.dispatch(push(ssoRedirectUrl));
  } else {
    window.location.href = `${window.location.origin}${ssoRedirectUrl}`;
  }
}
