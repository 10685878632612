import { AdvancedTargetingData, AdvancedTargetingType, TargetingFields } from '@openx/types';

export const getCurrentType = (targetingParams?: AdvancedTargetingData | null): AdvancedTargetingType | undefined => {
  if (targetingParams?.contextual?.length) {
    return TargetingFields.CONTEXTUAL_SEGMENTS;
  }

  if (targetingParams?.custom?.length) {
    return TargetingFields.CUSTOM_VARIABLES;
  }

  return undefined;
};
