export const itemToString = (item: string | JSX.Element) => {
  if (typeof item === 'string') {
    return item;
  }

  return item.props.children.reduce((acc, element) => acc + element.props.children, '');
};

export const getDisplayLimit = (selectedItems: (string | JSX.Element)[], itemsCharaterLimit: number): number => {
  let charLenSum = 0,
    itemsToDisplay = 0;

  for (const item of selectedItems) {
    charLenSum +=
      typeof item === 'string'
        ? item.length
        : item.props.children.reduce((acc, element) => acc + element.props.children.length, 0);
    if (charLenSum > itemsCharaterLimit) {
      return itemsToDisplay;
    }
    itemsToDisplay++;
  }
  return itemsToDisplay;
};
