import { Grid } from '@mui/material';
import { AnyAction } from '@reduxjs/toolkit';
import { type Dispatch, type SetStateAction, useCallback, useState } from 'react';

import { AutoCompleteVirtualize } from '@openx/components/core';
import {
  TargetableInventoryOption,
  type InstanceProps,
  type InventoryAndContentState,
  InventoryOptionPayload,
} from '@openx/types';
import { ACL_TYPE, isAllowedByCompiledAcl, usePermissionContext } from '@openx/utils/permission';

import { useTargetingContext } from '../../../../utils/context/TargetingContext';
import {
  clearSelectedInstance,
  setInventories,
  setSelectedInstance,
} from '../../../shared/InventoryAndContent/inventoryHelpers';
import { InventoryAutoComplete } from '../InventoryAutoComplete/InventoryAutoComplete';

const DropdownContainer = props => <Grid {...props} sx={{ marginTop: 2, ...props.sx }} />;

type inventoryProps = {
  targetingParams: InventoryAndContentState;
  dispatchInventory: (action: AnyAction) => void;
  dispatchInstance: (action: AnyAction) => void;
  inputInstanceValue: InstanceProps | null;
  setInputInstanceValue: Dispatch<SetStateAction<InstanceProps | null>>;
};

const isExcludingMode = (inventory: InventoryOptionPayload): boolean =>
  !inventory.includes.length && inventory.excludes.length > 0;

const getSelectedOption = (inventory: InventoryOptionPayload) =>
  isExcludingMode(inventory) ? TargetableInventoryOption.EXCLUDES : TargetableInventoryOption.INCLUDES;

export const Inventory = ({
  targetingParams,
  dispatchInventory,
  dispatchInstance,
  inputInstanceValue,
  setInputInstanceValue,
}: inventoryProps): JSX.Element => {
  const { useAccountUid, useCurrentInstanceState } = useTargetingContext();
  const accountUid = useAccountUid();
  const { useIsAllowed } = usePermissionContext();
  const { isAllowed } = useIsAllowed();

  const [selectedMarket, setSelectedMarket] = useState<null | string>(null);

  const { isBefInstance } = useCurrentInstanceState();

  const inventoryOption: TargetableInventoryOption = getSelectedOption(targetingParams.inventory);

  const isUserAllowed =
    isAllowed(isAllowedByCompiledAcl(ACL_TYPE.ROOT_WORKAS_ANYINSTANCE)) ||
    isAllowed(isAllowedByCompiledAcl(ACL_TYPE.INTERNAL_FIELD_WRITE)) ||
    isAllowed(isAllowedByCompiledAcl(ACL_TYPE.MARKET_OPERATOR_SEARCH));

  const onInventorySelect = useCallback(
    item => {
      dispatchInventory({
        payload: [...targetingParams.inventory[inventoryOption], item],
        type: setInventories[inventoryOption],
      });
    },
    [dispatchInventory, inventoryOption, targetingParams.inventory]
  );

  const instanceChangeHandler = useCallback(
    (value: InstanceProps | null, reason: string) => {
      if (reason === 'clear') {
        setInputInstanceValue(null);
        dispatchInstance({
          type: clearSelectedInstance,
        });
      } else {
        setInputInstanceValue(value);
        dispatchInstance({
          payload: value?.id,
          type: setSelectedInstance,
        });
      }
    },
    [setInputInstanceValue, dispatchInstance]
  );

  const marketChangeHandler = useCallback(
    (id: string | null) => {
      setSelectedMarket(id);
      setInputInstanceValue(null);
    },
    [setSelectedMarket, setInputInstanceValue]
  );

  return isUserAllowed && isBefInstance ? (
    <>
      <Grid container spacing={1}>
        <DropdownContainer item xs={6}>
          <InventoryMarkets
            onChange={(event, value) => {
              marketChangeHandler(value?.id || null);
            }}
          />
        </DropdownContainer>
        <DropdownContainer item xs={6}>
          <InventoryInstance
            inputInstanceValue={inputInstanceValue}
            selectedMarket={selectedMarket}
            onChange={(event, value, reason) => {
              instanceChangeHandler(value, reason);
            }}
          />
        </DropdownContainer>
      </Grid>
      {inputInstanceValue && (
        <DropdownContainer item xs={12}>
          <InventoryAutoComplete
            onInventorySelect={onInventorySelect}
            targetingParams={targetingParams}
            testId="inventory-autocomplete-include"
            instanceUid={inputInstanceValue?.id}
            accountId={accountUid}
          />
        </DropdownContainer>
      )}
    </>
  ) : (
    <Grid container spacing={1}>
      <DropdownContainer item xs={8}>
        <InventoryAutoComplete
          onInventorySelect={onInventorySelect}
          targetingParams={targetingParams}
          testId="inventory-autocomplete-include"
          accountId={accountUid}
        />
      </DropdownContainer>
    </Grid>
  );
};

const InventoryMarkets = ({ onChange }) => {
  const { useFetchInventoryMarket } = useTargetingContext();
  const { markets, loading } = useFetchInventoryMarket();

  if (markets && markets.length > 0)
    return (
      <AutoCompleteVirtualize
        loading={loading}
        disabled={loading}
        disableClearable={true}
        defaultValue={markets[0]}
        options={markets}
        getOptionLabel={option => option.name}
        data-test="inventory-autocomplete-market"
        onChange={onChange}
      />
    );
  return null;
};

const InventoryInstance = ({ selectedMarket, inputInstanceValue, onChange }) => {
  const { useFetchInventoryInstances } = useTargetingContext();
  const { instances, loading } = useFetchInventoryInstances(selectedMarket);

  return (
    <AutoCompleteVirtualize
      loading={loading}
      disabled={loading}
      textFieldProps={{
        placeholder: 'Select Instance',
      }}
      value={inputInstanceValue}
      options={instances}
      getOptionLabel={option => option.name}
      data-test="inventory-autocomplete-instance"
      onChange={onChange}
    />
  );
};
