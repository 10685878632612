import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { type ContentObjectOption, Intersect, type ContentObjectProps, type IntersectFormState } from '@openx/types';

import { SelectedItems, Switch, FiltersTitle, EmptyFilterMessage } from '../../../shared';
import type { TargetingItemsProps } from '../../../types';
import { itemTitle } from '../../constants';

export const IntersectItems = ({
  targetingParams,
  readonly = true,
  onChange = () => {},
  type,
}: TargetingItemsProps<ContentObjectProps> & { type: ContentObjectOption }) => {
  const { t } = useTranslation();

  const { op, val } = (targetingParams[type] as IntersectFormState) || {
    op: Intersect.INTERSECTS,
    val: new Set<string>(),
  };

  const typeLabel = op === Intersect.NOT_INTERSECTS ? t('blocked') : t('allowed');
  const filtersTitle = val.size ? typeLabel : '';
  const switchState = op === Intersect.NOT_INTERSECTS;
  const selectedItems = useMemo(() => [...val], [val]);

  const handleChange = useCallback(
    (values: string[]) => {
      const selected = new Set(values);
      const returnValue = values.length === 0 ? null : { op, val: selected };

      onChange((prevState: ContentObjectProps) => {
        return {
          ...prevState,
          [type]: returnValue,
        };
      });
    },
    [onChange, op, type]
  );

  if (val.size === 0) {
    return <EmptyFilterMessage text={t('No filters applied.')} />;
  }

  return (
    <div data-test="sub-section">
      <FiltersTitle onClear={() => handleChange([])} title={itemTitle(type, val.size)} readonly={readonly} />

      {!readonly && (
        <Switch
          switchState={switchState}
          onSwitchChange={() =>
            onChange(prevState => ({
              ...prevState,
              [type]: {
                op: switchState ? Intersect.INTERSECTS : Intersect.NOT_INTERSECTS,
                val,
              },
            }))
          }
          switchOffLabel={t('allow')}
          switchOnLabel={t('block')}
          groupTitle={t('the following:')}
        />
      )}

      <SelectedItems
        filtersTitle={filtersTitle}
        isAddIcon={!switchState}
        selectedItems={selectedItems}
        readonly={readonly}
        handleChange={handleChange}
        dataTest={type}
      />
    </div>
  );
};
