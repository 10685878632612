import { AdvancedTargetingData, CustomVariablesData, TargetingFields } from '@openx/types';

import { ContextualSegmentsItems } from './ContextualSegments/ContextualSegmentsItems';
import { CustomVariablesItems } from './CustomVariables/CustomVariablesItems';

type UpdateParamsType = React.Dispatch<React.SetStateAction<AdvancedTargetingData>>;
interface Props {
  onAdd?: (ctxSegment: CustomVariablesData) => void;
  params: AdvancedTargetingData;
  updateParams: UpdateParamsType;
  type?: TargetingFields;
}

export const AdvanceTargetingItems = ({ params, updateParams, onAdd, type }: Props) => {
  return (
    <>
      {type === TargetingFields.CONTEXTUAL_SEGMENTS && (
        <ContextualSegmentsItems targetingParams={params} readonly={false} onChange={updateParams} onAdd={onAdd} />
      )}

      {type === TargetingFields.CUSTOM_VARIABLES && (
        <CustomVariablesItems targetingParams={params} readonly={false} onChange={updateParams} />
      )}
    </>
  );
};
