import { Grid } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  VideoOption,
  type RewardedVideoPayload,
  type VideoStatePayload,
  type VideoFormState,
  type VideoSkipabilityPayload,
} from '@openx/types';

import { useTargetingContext } from '../../utils';
import { DrawerTopBox, OptionsDropdown } from '../shared';

import { TypeOptionDropdown, RewardedVideoDropdown, VideoSkipabilityDropdown } from './components';
import { getTypePlaceholder } from './utils';

type Props = {
  params: VideoFormState;
  onChange: (typeParams: RewardedVideoPayload | VideoSkipabilityPayload | VideoStatePayload | null) => void;
  selectedType: VideoOption;
  onTypeChange: (type: VideoOption) => void;
};

export const TopBox = ({ params, onChange, selectedType, onTypeChange }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { useVideoOptionsFetch } = useTargetingContext();

  const { options, isLoading } = useVideoOptionsFetch(selectedType);

  const placeholder = useMemo(() => getTypePlaceholder(t, selectedType), [t, selectedType]);

  const dropdown = useMemo(() => {
    switch (selectedType) {
      case VideoOption.REWARDED_VIDEO:
        return (
          <RewardedVideoDropdown
            placeholder={placeholder}
            value={params[selectedType]?.op || null}
            onChange={onChange}
          />
        );
      case VideoOption.VIDEO_SKIPABILITY:
        return (
          <VideoSkipabilityDropdown
            placeholder={placeholder}
            value={params[selectedType]?.val || null}
            onChange={onChange}
          />
        );
      default:
        return (
          <OptionsDropdown
            loading={isLoading}
            options={options}
            onChange={val => onChange({ ...params[selectedType], val })}
            selectedOptions={params[selectedType].val as Set<string>}
            placeholder={placeholder}
            dataTest="video-input"
          />
        );
    }
  }, [selectedType, placeholder, params, isLoading, options, onChange]);

  return (
    <DrawerTopBox>
      <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={4}>
          <TypeOptionDropdown onChange={onTypeChange} value={selectedType} />
        </Grid>
        <Grid item xs={8}>
          {dropdown}
        </Grid>
      </Grid>
    </DrawerTopBox>
  );
};
