import { shallowEqual, useSelector } from 'react-redux';

import { SessionState } from '@openx/utils/state/session/types';

export interface AccountStateType {
  session: SessionState;
}

export const useAccountUid = () => {
  const accountUid = useSelector<AccountStateType, string | undefined>(
    state => state.session.details?.user.account_uid,
    shallowEqual
  );

  return accountUid as string;
};
