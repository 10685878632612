import { Grid, styled } from '@mui/material';

import { TechnologyAndDevicesOption, UserAgentPayload } from '@openx/types';

import { DrawerTopBox } from '../shared';

import { TopBoxValueField, TypeOptionDropdown } from './TechnologyAndDevicesTopBox';

const StyledGrid = styled(Grid)`
  padding-bottom: 1em;
`;

type TopBoxProps = {
  items: Set<string> | UserAgentPayload;
  onChange: (options: Set<string> | UserAgentPayload) => void;
  selectedType: TechnologyAndDevicesOption;
  onTypeChange: (type: TechnologyAndDevicesOption) => void;
};

export const TopBox = ({ items, onChange, selectedType, onTypeChange }: TopBoxProps): JSX.Element => {
  return (
    <DrawerTopBox>
      <StyledGrid container spacing={1} alignItems={'flex-end'}>
        <Grid item xs={3}>
          <TypeOptionDropdown onChange={onTypeChange} value={selectedType} />
        </Grid>

        <Grid item xs={9}>
          <TopBoxValueField items={items} onChange={onChange} selectedType={selectedType} />
        </Grid>
      </StyledGrid>
    </DrawerTopBox>
  );
};
