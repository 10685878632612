import { IconButton, type SxProps, type Theme, Tooltip, type TooltipProps } from '@mui/material';
import React from 'react';

interface AlertTooltipProps {
  color: string;
  title: string | React.JSX.Element;
  icon?: React.JSX.Element;
  sx?: SxProps<Theme>;
  placement?: TooltipProps['placement'];
}

export const AlertTooltip = ({ title, icon, color, sx = {}, placement = 'right' }: AlertTooltipProps) => {
  return (
    <Tooltip title={title} placement={placement}>
      <IconButton sx={{ color: color, margin: 0, padding: '6.5px', ...sx }}>{icon}</IconButton>
    </Tooltip>
  );
};
