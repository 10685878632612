import { Typography, styled } from '@mui/material';

export const StyledTypography = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

export const StyledOutsideLinkContainer = styled('span')`
  margin: ${({ theme }) => theme.spacing(0, 0.7)};
`;
