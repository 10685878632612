import { useMemo } from 'react';

import { AudienceOption, OptionsMap } from '@openx/types';
import { useOpenAudiencesOptionsFetch, useDMPSegmentsOptionsFetch } from '@openx/utils';

import { UseAudienceOptionsFetchFn } from '../context';

type OptionsFetchResponse = {
  isLoading: boolean;
  options: OptionsMap;
  refetch?: (props?: any) => any | void;
};

export const useAudienceOptionsFetch: UseAudienceOptionsFetchFn = (
  accountUid: string,
  type: AudienceOption | null
): OptionsFetchResponse => {
  const audiences = useOpenAudiencesOptionsFetch(accountUid);
  const dmpSegments = useDMPSegmentsOptionsFetch(accountUid);
  return useMemo(() => {
    if (type && (type === AudienceOption.DMP_SEGMENTS || type === AudienceOption.OPEN_AUDIENCES)) {
      return {
        [AudienceOption.DMP_SEGMENTS]: {
          isLoading: dmpSegments.isDmpSegmentsLoading,
          options: dmpSegments.dmpSegments,
          refetch: dmpSegments.refetchDMPSegments,
        },
        [AudienceOption.OPEN_AUDIENCES]: {
          isLoading: audiences.isAudiencsesLoading,
          options: audiences.audiences,
          refetch: audiences.refetchAudiences,
        },
      }[type];
    }
    return {} as OptionsFetchResponse;
  }, [
    audiences.audiences,
    audiences.isAudiencsesLoading,
    audiences.refetchAudiences,
    dmpSegments.dmpSegments,
    dmpSegments.isDmpSegmentsLoading,
    dmpSegments.refetchDMPSegments,
    type,
  ]);
};
