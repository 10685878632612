import type { TFunction } from 'i18next';

import { AudienceTypeOption, MatchedUsersOption } from './constants';

export const getAudienceTypeLabelsMap = (t: TFunction) => {
  return {
    [AudienceTypeOption.Over_17]: { id: AudienceTypeOption.Over_17, name: t('>17') },
    [AudienceTypeOption.Over_12]: { id: AudienceTypeOption.Over_12, name: t('Older than 12 years') },
    [AudienceTypeOption.AllAudiences]: { id: AudienceTypeOption.AllAudiences, name: t('All Audiences') },
    [AudienceTypeOption.Under_18]: { id: AudienceTypeOption.Under_18, name: t('<18') },
    [AudienceTypeOption.Under_13]: { id: AudienceTypeOption.Under_13, name: t('<13') },
  };
};

export const getMatchedUsersLabelsMap = (t: TFunction) => {
  return {
    [MatchedUsersOption.Matched]: { id: MatchedUsersOption.Matched, name: t('Matched Users') },
    [MatchedUsersOption.Unmatched]: { id: MatchedUsersOption.Unmatched, name: t('Unmatched Users') },
    [MatchedUsersOption.MatchedByCookie]: {
      id: MatchedUsersOption.MatchedByCookie,
      name: t('Users Matched by Cookie Only'),
    },
    [MatchedUsersOption.MatchedByEids]: { id: MatchedUsersOption.MatchedByEids, name: t('Users Matched by EIDs Only') },
  };
};
