import { Button } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { OptionsMap } from '@openx/types';

import { useTargetingContext } from '../../utils';
import { DrawerTopBox, OptionsDropdown } from '../shared';

import { getAllowedSortedIds, filterOptions } from './utils';

type TopBoxProps = {
  items: Set<string>;
  onChange: (categories: Set<string>) => void;
  handleReset: () => void;
  shouldDisplayResetButton: boolean;
};

export const CategoryTopBox = ({
  items,
  onChange,
  handleReset,
  shouldDisplayResetButton,
}: TopBoxProps): JSX.Element => {
  const { t } = useTranslation();
  const { useCategoriesFetch } = useTargetingContext();
  const { isCategoriesLoading, categories, refetchCategories } = useCategoriesFetch();

  useEffect(() => {
    refetchCategories();
  }, [refetchCategories]);

  return (
    <DrawerTopBox>
      <OptionsDropdown
        loading={isCategoriesLoading}
        options={categories}
        onChange={onChange}
        selectedOptions={items}
        placeholder={t('[ Select Category Types ]')}
        label={t('Category')}
        dataTest="category-input"
        getSortedKeys={getAllowedSortedIds as (options: OptionsMap) => string[]}
        filterOptions={
          filterOptions as (optionsIds: string[], optionsMap: OptionsMap, searchPhrase: string) => string[]
        }
        margin="dense"
      />

      {shouldDisplayResetButton && (
        <Button
          data-test="reset-to-account-settings"
          onClick={handleReset}
          variant={'text'}
          color={'primary'}
          sx={{ marginTop: theme => theme.spacing(1) }}
        >
          {t("Reset to account's settings")}
        </Button>
      )}
    </DrawerTopBox>
  );
};
