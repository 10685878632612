import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoCompleteVirtualize, Ellipsis, HighlightedPhrase } from '@openx/components/core';
import {
  TargetableInventoryList,
  TargetableInventory,
  InventoryAndContentState,
  InventoryContentOption,
} from '@openx/types';

import { useTargetingContext } from '../../../../utils/context/TargetingContext';
import { getInventoryDisplayName } from '../../../shared/InventoryAndContent/utils';

interface InventoryAutoCompleteProps {
  inputLabel?: string;
  onInventorySelect: (item: TargetableInventory) => void;
  targetingParams: InventoryAndContentState;
  includes?: TargetableInventoryList;
  testId?: string;
  instanceUid?: string;
  accountId: string;
}

export const InventoryAutoComplete = ({
  inputLabel = '',
  onInventorySelect,
  targetingParams,
  includes = [],
  testId,
  instanceUid,
  accountId,
}: InventoryAutoCompleteProps) => {
  const { t } = useTranslation();
  const { useFetchInventoriesBySearchText } = useTargetingContext();

  const [inputPhrase, setInputPhrase] = useState('');
  const [searchPhrase, setSearchPhrase] = useState('');

  const { isLoading, data } = useFetchInventoriesBySearchText({
    accountId,
    includes,
    instanceUid,
    searchPhrase,
  });

  const inventories = useMemo(() => data ?? [], [data]);

  const selectedInventoriesIds = useMemo(() => {
    return new Set([
      ...targetingParams[InventoryContentOption.INVENTORY].excludes.map(item => item.id),
      ...targetingParams[InventoryContentOption.INVENTORY].includes.map(item => item.id),
    ]);
  }, [targetingParams]);

  const filterInventoryOptions = useCallback(() => {
    return inventories.filter(option => !selectedInventoriesIds.has(option.id));
  }, [inventories, selectedInventoriesIds]);

  const noOptionsText = useCallback(() => {
    if (!searchPhrase || isLoading) {
      return t('Type to search');
    }
    return t('Nothing found');
  }, [searchPhrase, isLoading, t]);

  const onInventoryAdd = useCallback(
    (e, item) => {
      if (item) {
        onInventorySelect(item);
      }
    },
    [onInventorySelect]
  );

  const onInventoryClose = useCallback(() => {
    setInputPhrase('');
    setSearchPhrase('');
  }, []);

  return (
    <AutoCompleteVirtualize
      options={inventories}
      renderOption={(state, option) => (
        <li {...state} key={option.id}>
          <Ellipsis width="100%" tooltip data-test="type-option">
            <HighlightedPhrase searchPhrase={searchPhrase}>{getInventoryDisplayName(option)}</HighlightedPhrase>
          </Ellipsis>
        </li>
      )}
      getOptionLabel={option => getInventoryDisplayName(option) || ''}
      data-test={testId}
      onInputChange={(e, value, reason) => {
        (reason === 'input' || reason === 'clear') && setInputPhrase(value);
      }}
      onDebounceChange={({ target }) => {
        setInputPhrase(target.value);
        setSearchPhrase(target.value);
      }}
      debounced={true}
      textFieldProps={{
        label: inputLabel,
        placeholder: t('Enter a Publisher, Site, Section, Ad Unit and press Enter'),
      }}
      onChange={onInventoryAdd}
      onClose={onInventoryClose}
      disableCloseOnSelect
      filterOptions={filterInventoryOptions}
      filterSelectedOptions
      loading={isLoading}
      disableClearable
      noOptionsText={noOptionsText()}
      inputValue={inputPhrase}
      value={{} as TargetableInventory}
    />
  );
};
