import { useCallback } from 'react';

import type { ContentObjectOption, ContentObjectProps, Option } from '@openx/types';

import { SelectedItems, FiltersTitle } from '../../../shared';
import type { TargetingItemsProps } from '../../../types';
import { itemTitle } from '../../constants';

export const DefaultItems = ({
  targetingParams,
  type,
  readonly,
  onChange = () => {},
}: TargetingItemsProps<ContentObjectProps> & { type: ContentObjectOption }) => {
  const handleChange = useCallback(() => {
    onChange(state => ({ ...state, [type]: null }));
  }, [onChange, type]);

  if (!targetingParams[type]) {
    return null;
  }

  const val = [targetingParams[type]] as Option[];

  return (
    <div data-test="sub-section">
      <FiltersTitle onClear={handleChange} title={itemTitle(type, val.length)} readonly={readonly} />

      <SelectedItems
        isAddIcon={val[0] ? val[0].name === 'True' : false}
        selectedItems={[val[0]?.name]}
        readonly={readonly}
        handleChange={handleChange}
        dataTest={type}
      />
    </div>
  );
};
