import { useCallback } from 'react';

import type { ChipFilterValue, FiltersConfig } from '@openx/types';

import { Chip } from './Chip';
import { formatValueToLabel } from './NumberFilter/utils';

interface ChipContainerProps<FiltersValue extends Record<string, string>> {
  filters: FiltersValue;
  filtersConfig: FiltersConfig;
  onDelete: (categoryName: string, value: ChipFilterValue) => void;
  onEdit: (element: HTMLElement, category: string) => void;
}

export function ChipContainer<FiltersValue extends Record<string, string>>({
  filters,
  onDelete,
  filtersConfig,
  onEdit,
}: ChipContainerProps<FiltersValue>) {
  const getName = useCallback(
    (categoryName: string, filterValue: string) => {
      if (filtersConfig[categoryName].numberFilter) {
        return formatValueToLabel(filterValue);
      }
      const options = filtersConfig[categoryName].optionsToSearch || filtersConfig[categoryName].options || [];
      const option = options.filter(
        opt =>
          opt.value === filterValue ||
          // for multiple values separated with comma eg d7429aaa-db64-4216-8649-0fe906d30a36,cd0f729c-9f38-49e0-b480-6e03e33ba3ba
          filterValue.split(',').some(filter => filter === opt.value)
      );

      return option.map(o => o?.displayName).join(',') || filterValue;
    },
    [filtersConfig]
  );

  return (
    <>
      {Object.entries(filters).map(([categoryName, filterValue]) => (
        <Chip
          clickable
          label={`${filtersConfig[categoryName].name}: ${getName(categoryName, filterValue)}`}
          onClick={e => onEdit(e.currentTarget, categoryName)}
          onDelete={() => onDelete(categoryName, filterValue)}
          key={categoryName}
          data-test="chip-button"
        />
      ))}
    </>
  );
}
