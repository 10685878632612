import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ElementType } from 'react';

const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.action.disabled};
` as typeof Typography;

interface ValuePlaceholderProps {
  component?: ElementType;
  dataTestParam?: string;
}

export const ValuePlaceholder = ({
  component = 'span',
  dataTestParam = 'value',
}: ValuePlaceholderProps): JSX.Element => {
  return (
    <StyledTypography component={component} data-test={dataTestParam}>
      N/A
    </StyledTypography>
  );
};
