import moment from 'moment';

import { getOffsetLabel } from './getOffsetLabel';

export type FormatDateParam = {
  date: string;
  dateFormat?: string;
  displayLocalTime?: boolean;
  showOffset?: boolean;
};

export const formatDate = (param: FormatDateParam) => {
  const { date, dateFormat, displayLocalTime, showOffset } = param;

  const tzDate = displayLocalTime ? moment.utc(date).local() : moment(date);
  const formattedTzDate = tzDate.format(dateFormat);
  const fromNow = tzDate.fromNow();

  const dateLabel = showOffset
    ? `${formattedTzDate} (${displayLocalTime ? getOffsetLabel(date) : 'UTC'})`
    : formattedTzDate;

  return { dateLabel, fromNow };
};
