import { useTranslation } from 'react-i18next';

import { Button } from '@openx/components/core';

interface AlertActionProps {
  showErrors: boolean;
  setShowErrors: (isError: boolean) => void;
}

export function AlertAction({ showErrors, setShowErrors }: AlertActionProps) {
  const { t } = useTranslation();

  const alertActionText = showErrors ? t('Hide Details') : t('See Details');

  return (
    <Button type="button" onClick={() => setShowErrors(!showErrors)} variant="text" color="error">
      {alertActionText}
    </Button>
  );
}
