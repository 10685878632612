import { styled } from '@mui/material/styles';

export const PageHeaderContainer = styled('div', { shouldForwardProp: prop => prop !== 'isDetailsPage' })<{
  isDetailsPage?: boolean;
}>`
  ${({ theme }) => theme.breakpoints.down(1000)} {
    & h1.MuiTypography-root {
      font-size: ${({ theme, isDetailsPage }) =>
        isDetailsPage ? theme.typography.pxToRem(32) : theme.typography.pxToRem(60)};
    }
  }

  ${({ theme }) => theme.breakpoints.down(760)} {
    & h1.MuiTypography-root {
      font-size: ${({ theme, isDetailsPage }) =>
        isDetailsPage ? theme.typography.pxToRem(24) : theme.typography.pxToRem(40)};
      line-height: 1.5;
    }

    & h3.MuiTypography-h3 {
      font-size: ${({ theme }) => theme.typography.pxToRem(18)};
    }
  }
`;
