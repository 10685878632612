import { Box } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { GeographicOption, type GeographicTargetingState, BoundingBoxOptions } from '@openx/types';

import { SelectedItems, FiltersTitle } from '../../shared';
import { getEmptyBoundingBoxState } from '../utils';

type BoundingBoxItemsProps = {
  onChange: (newData: GeographicTargetingState) => void;
  readonly: boolean;
  targetingParams: GeographicTargetingState;
};

export const BoundingBoxItems = ({ onChange, readonly, targetingParams }: BoundingBoxItemsProps) => {
  const { t } = useTranslation();
  const { bounding_box } = targetingParams;

  const onBoundingBoxClear = useCallback(() => {
    onChange({ ...targetingParams, [GeographicOption.BOUNDING_BOX]: getEmptyBoundingBoxState() });
  }, [onChange, targetingParams]);

  const getBoundingBoxDisplayName = useMemo(() => {
    const latMin = bounding_box[BoundingBoxOptions.LATMIN];
    const longMin = bounding_box[BoundingBoxOptions.LONGMIN];

    const latLabel = t('Latitude range is {latMin}° to {latMax}°', {
      latMax: bounding_box[BoundingBoxOptions.LATMAX],
      latMin,
    });

    const longLabel = t('Longitude range is {longMin}° to {longMax}°', {
      longMax: bounding_box[BoundingBoxOptions.LONGMAX],
      longMin,
    });

    if (latMin && longMin) return `${latLabel}, ${longLabel}`;
    if (latMin) return `${latLabel}`;

    return `${longLabel}`;
  }, [bounding_box, t]);

  return (
    <Box data-test="sub-section">
      <FiltersTitle onClear={onBoundingBoxClear} title={t('Bounding Box')} readonly={readonly} />

      <SelectedItems
        filtersTitle={t('include')}
        isAddIcon
        selectedItems={[bounding_box]}
        getItemLabel={() => getBoundingBoxDisplayName}
        readonly={readonly}
        handleChange={onBoundingBoxClear}
        isRemovingAll
        dataTest="bounding-box"
      />
    </Box>
  );
};
