import { useMemo } from 'react';

import { CategoryData } from '@openx/types';

import { TargetingReadOnly } from '../shared';
import { TargetingReadOnlyProps } from '../types';

import { CategoryItems } from './CategoryItems';
import { mapCategoryToFormState } from './utils';

type InfoComponentProps = { infoComponent: JSX.Element | null };

export const CategoryReadOnly = ({
  name,
  onOpenDrawerClick,
  onRemoveClick,
  targetingParams,
  readonly,
  isDisabled,
  dataTest,
  infoComponent,
}: TargetingReadOnlyProps<CategoryData> & InfoComponentProps) => {
  const params = useMemo(() => mapCategoryToFormState(targetingParams), [targetingParams]);

  return (
    <TargetingReadOnly
      name={name}
      isEmpty={!targetingParams}
      onOpenDrawerClick={onOpenDrawerClick}
      onRemove={onRemoveClick}
      readonly={readonly}
      isDisabled={isDisabled}
      dataTest={dataTest}
    >
      {infoComponent}
      {targetingParams && <CategoryItems targetingParams={params} />}
    </TargetingReadOnly>
  );
};
