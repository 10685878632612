import { ReactNode } from 'react';

import { Loader } from './Loader';

export interface WhenLoadedProps {
  loading: boolean;
  children: ReactNode;
  loaderClassName?: string;
}

export function WhenLoaded({ loading, children, loaderClassName }: WhenLoadedProps): JSX.Element {
  if (loading) {
    return <Loader className={loaderClassName} />;
  }

  return <>{children}</>;
}
