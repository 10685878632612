import { composeWithDevTools } from '@redux-devtools/extension';
import { combineReducers, createStore, Store } from 'redux';

import { notificationsReducer } from '@openx/utils/state/notifications/reducer';

import { session } from 'state/session/reducer';

export const store: Store = createStore(
  combineReducers({
    notifications: notificationsReducer,
    session,
  }),
  composeWithDevTools()
);
