import React, { createContext, useContext, useMemo } from 'react';

import { Session } from '@openx/types';

import { UseIsAllowed } from './PermissionHookTypes';

export type PermissionConfig = {
  isOa?: boolean;
  session?: Session; // TODO: UIG-540 Temp Workaround for ACL Platform Share
  useIsAllowed: UseIsAllowed;
};
export interface PermissionProviderProps {
  children: React.ReactNode;
  permissionConfig: PermissionConfig;
  session?: Session; // TODO: UIG-540 Temp Workaround for ACL Platform Share
}

const permissionContext = createContext<PermissionConfig>({
  isOa: false,
  useIsAllowed: () => {
    throw new Error('Not implemented. Ensure you are inside the PermissionContext');
  },
});

export const usePermissionContext = () => useContext(permissionContext);

export function PermissionProvider({ children, permissionConfig, session }: PermissionProviderProps) {
  const value: PermissionConfig = useMemo(
    () => ({
      session,
      ...permissionConfig,
    }),
    [permissionConfig, session]
  );

  return <permissionContext.Provider value={value}>{children}</permissionContext.Provider>;
}
