import i18n from 'i18next';

import { RangeOptions } from './types';

export const getOptionParam = (
  option: RangeOptions | null,
  p1: string | number,
  p2?: string | number
): string | null => {
  if (p1 === '' || !option) {
    return null;
  }

  if (option === RangeOptions.RANGE) {
    if (p2 === '') {
      return null;
    }
    return `__range=${p1},${p2}`;
  }

  if (option === RangeOptions.EQUAL) {
    return `__eq=${p1}`;
  }

  return `__${option.toLocaleLowerCase()}=${p1}`;
};

export const formatValueToLabel = (input: string): string => {
  const [prefix, ...val] = input.split('=');
  const value = val.join();

  let p1, p2;
  switch (prefix) {
    case '__range':
      [p1, p2] = value.split(',');
      return i18n.t('In range {p1} to {p2}', { p1, p2 });
    case '__eq':
      return i18n.t('Equal to {value}', { value });
    case '__lt':
      return i18n.t('Less than {value}', { value });
    case '__gt':
      return i18n.t('Greater than {value}', { value });
    default:
      return value;
  }
};
