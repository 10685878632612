import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledSwitchGrid = styled(Grid)`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const StyledSwitchGroupTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 11px;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  margin-left: ${({ theme }) => theme.spacing(1)};
  text-transform: uppercase;
`;
