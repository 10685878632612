export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}
export interface SortCriteria {
  column: string;
  direction: SortDirection;
  sortableColumns: string[];
}

export interface PaginationCriteria {
  pageSize: number;
  pageNumber: number;
  totalCount: number;
  hasMore?: boolean;
}

export enum CriteriaDimension {
  FILTERS = 'filters',
  SORT = 'sort',
  PAGINATION = 'pagination',
}

export type TableCriteria<
  T extends CriteriaDimension,
  FiltersT extends Record<string, unknown> = {}
> = T extends CriteriaDimension.SORT
  ? SortCriteria
  : T extends CriteriaDimension.PAGINATION
  ? PaginationCriteria
  : T extends CriteriaDimension.FILTERS
  ? FiltersT
  : never;

export type AllTableCriteria<FiltersT extends Record<string, unknown> = {}> = {
  [dimension in CriteriaDimension]?: TableCriteria<dimension, FiltersT>;
};

export interface CriteriaChange<D extends CriteriaDimension, FiltersT extends Record<string, unknown> = {}> {
  dimension: D;
  value?: TableCriteria<D, FiltersT>;
}

export type ChangeCriteriaHandler<
  D extends CriteriaDimension = CriteriaDimension,
  FiltersT extends Record<string, unknown> = {}
> = (change: CriteriaChange<D, FiltersT>) => void;

export type ChangeSingleCriteriaAction<T extends CriteriaDimension, FiltersT extends Record<string, unknown> = {}> = (
  criteria: TableCriteria<T, FiltersT>
) => void;

export function currentPageRange(
  pageNumber: number,
  pageSize: number,
  totalCount: number
): { min: number; max: number } {
  if (pageNumber < 1) {
    return { max: 0, min: 0 };
  }

  const min = (pageNumber - 1) * pageSize + 1;
  const maxItemForPage = pageNumber * pageSize;
  const max = totalCount < maxItemForPage ? totalCount : maxItemForPage;

  return { max, min };
}

export function applyCriteriaChange<FiltersT extends Record<string, unknown> = {}>(
  previousCriteria: AllTableCriteria<FiltersT>,
  change: CriteriaChange<CriteriaDimension>
): AllTableCriteria<FiltersT> {
  const resetPagination: boolean = change.dimension !== CriteriaDimension.PAGINATION;

  const pagination = previousCriteria.pagination
    ? {
        ...previousCriteria.pagination,
        pageNumber: resetPagination ? 1 : previousCriteria.pagination.pageNumber,
      }
    : undefined;

  return {
    ...previousCriteria,
    [CriteriaDimension.PAGINATION]: pagination,
    [change.dimension]: change.value,
  };
}
