import { Grid, Skeleton, styled } from '@mui/material';

export const StyledSkeleton = styled(Skeleton)`
  margin-bottom: ${({ theme }) => theme.spacing(0.6)};
`;

export const StyledTextSkeletonGrid = styled(Grid)`
  margin-bottom: ${({ theme }) => theme.spacing(1.2)};
`;

export const StyledButtonsContainerSkeleton = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(2.2)};
  margin-bottom: ${({ theme }) => theme.spacing(0)};
`;

export const StyledButtonSkeleton = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(1)};
`;
