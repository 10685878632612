import { Grid } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Criteria, InventoryAndContentState, InventoryContentOption, OptionEntriesProps } from '@openx/types';

import { useComparisonLabels, mapTypeToIconType } from '../../constants';
import { SelectedItems, Switch, DimensionChip, FiltersTitle, DimensionChipLine } from '../../shared';
import { useInventoryAndContentLabels } from '../../shared/InventoryAndContent/constants';
import {
  setContentCategoryOp,
  resetFilter,
  setComparisonOpValue,
} from '../../shared/InventoryAndContent/inventoryHelpers';
import { TargetingItemsProps, ListIconType } from '../../types';

import { getSelectedOptionsCount } from './utils';

interface DeafaultItemsProps extends TargetingItemsProps<InventoryAndContentState> {
  option: InventoryContentOption.APP_BUNDLE_ID | InventoryContentOption.PAGE_URL;
}

export const DefaultItems = ({
  readonly = true,
  dispatchInventory = () => {},
  targetingParams,
  option,
  isNotLastItem,
  switchState: chipLineSwitchState,
  dataTest,
}: DeafaultItemsProps) => {
  const { t } = useTranslation();
  const labels = useInventoryAndContentLabels();
  const comparisonLabels = useComparisonLabels();

  const handleChange = useCallback(
    (values: string[], inventoryContentOption: string, comparisonType: string) => {
      dispatchInventory({ payload: { comparisonType, values }, type: setComparisonOpValue[inventoryContentOption] });
    },
    [dispatchInventory]
  );

  const { op: dimension, val: optionsMap } = targetingParams[option];
  const switchState = dimension === Criteria.ANY;
  const optionsEntries: Array<OptionEntriesProps> = Object.entries(optionsMap);

  return (
    <Grid key={option} data-test="sub-section">
      <FiltersTitle
        onClear={() =>
          dispatchInventory({
            payload: {
              contentOption: option,
            },
            type: resetFilter,
          })
        }
        title={`${labels[option].label} [ ${getSelectedOptionsCount(optionsEntries)} ]`}
        readonly={readonly}
      />
      {!readonly && (
        <Switch
          switchState={switchState}
          onSwitchChange={() =>
            dispatchInventory({
              payload: switchState ? Criteria.ALL : Criteria.ANY,
              type: setContentCategoryOp[option],
            })
          }
          switchOffLabel={t('all')}
          switchOnLabel={t('any')}
        />
      )}
      {optionsEntries.map(([comparisonType, items], idx) =>
        items?.length ? (
          <Grid
            key={comparisonType}
            data-test={`section-items-${comparisonLabels[comparisonType].toLowerCase().replaceAll(' ', '-')}`}
          >
            <SelectedItems
              filtersTitle={comparisonLabels[comparisonType]}
              isAddIcon={mapTypeToIconType[comparisonType] === ListIconType.INCLUDE}
              selectedItems={items}
              readonly={readonly}
              handleChange={values => handleChange(values, option, comparisonType)}
              isRemovingAll={true}
              dataTest={dataTest}
            />
            {idx < optionsEntries.length - 1 && <DimensionChip targetingDimension={dimension} isInsideOption />}
            {idx === optionsEntries.length - 1 && isNotLastItem && (
              <DimensionChipLine switchState={chipLineSwitchState} />
            )}
          </Grid>
        ) : null
      )}
    </Grid>
  );
};
