import { AudienceOption } from '@openx/types';

import { OptionsFetchResponse, UseAudienceOptionsDetailsFetchFn } from '../context';

import { useAudienceOptionsFetch } from './useAudienceOptionsFetch';

export const useAudienceOptionsDetailsFetch: UseAudienceOptionsDetailsFetchFn = (
  accountUid: string,
  type: AudienceOption | null
): OptionsFetchResponse => {
  return useAudienceOptionsFetch(accountUid, type);
};
