import { ComparisonType } from './targetingValuesTypes';

export enum SelectRateOption {
  VIEWABILITY_SCORE = 'viewability_score',
  VTR_SCORE = 'vtr_score',
}

export type SelectRateDataViewability = {
  [SelectRateOption.VIEWABILITY_SCORE]: {
    op: ComparisonType.GREATER_THAN_OR_EQUAL_TO;
    val: string;
  };
};

export type SelectRateDataViewThroughRate = {
  [SelectRateOption.VTR_SCORE]: {
    op: ComparisonType.GREATER_THAN_OR_EQUAL_TO;
    val: string;
  };
};

export type SelectRateFormState = {
  op: ComparisonType.GREATER_THAN_OR_EQUAL_TO;
  val: string | null;
};
