import InfoRounded from '@mui/icons-material/InfoRounded';
import { Box, IconButton, Typography } from '@mui/material';

type InlineInfoProps = {
  infoText: string | JSX.Element;
};

export const InlineInfo = ({ infoText }: InlineInfoProps) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        marginLeft: theme => theme.spacing(-1),
        marginTop: theme => theme.spacing(1),
      }}
    >
      <IconButton data-test="tooltip-icon" sx={{ marginRight: theme => theme.spacing(1) }}>
        <InfoRounded />
      </IconButton>

      <Typography data-test="targeting-inline-info">{infoText}</Typography>
    </Box>
  );
};
