import { useTranslation } from 'react-i18next';

import { OptionsType } from '@openx/types';

import { useOptionsFetch } from './generics';

export function useDistributionChannelFetch() {
  const { t } = useTranslation();
  const {
    isLoading: isDistributionChannelLoading,
    data: distributionChannel,
    refetch: refetchDistributionChannel,
  } = useOptionsFetch({
    errorMessage: t('Failed to fetch Distribution Channel options'),
    optionType: OptionsType.DISTRIBUTION_CHANNEL,
  });

  return {
    distributionChannel,
    isDistributionChannelLoading,
    refetchDistributionChannel,
  };
}
