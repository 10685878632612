import { SxProps } from '@mui/material';
import { KeyboardEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from '@openx/components/core';
import { AdvancedTargetingData, ComparisonType, CustomVariablesData } from '@openx/types';

import { removeNotAllowedSymbols } from '../../ContextualSegments/helpers';
import { DrawerTopBox } from '../../shared';

interface ContextualSegmentsTopBoxProps {
  items: AdvancedTargetingData;
  onEnter: (customVariables: CustomVariablesData) => void;
  sx: SxProps;
}

interface ErrorText {
  providerName: string | null;
}

export const ContextualSegmentsTopBox = ({ items, onEnter, sx }: ContextualSegmentsTopBoxProps) => {
  const { t } = useTranslation();

  const [providerNameInput, setProviderNameInput] = useState<string>('');
  const [errorText, setErrorText] = useState<ErrorText>({ providerName: null });
  const defaultProviderName = items.contextual[0]?.attr;

  const onKeyPress = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const attr = defaultProviderName ?? removeNotAllowedSymbols(providerNameInput);

        if (attr === '') {
          const attrError = attr === '' ? t('This field cannot be empty.') : null;

          setErrorText({
            providerName: attrError,
          });
          return;
        }

        onEnter({ op: items.op, val: [{ attr, op: 'INTERSECTS' as ComparisonType, val: '' }] });
      }

      setErrorText({ providerName: null });
    },
    [onEnter, t, providerNameInput, defaultProviderName, items]
  );

  const isLimitReached = !!defaultProviderName;

  return (
    <DrawerTopBox sx={sx}>
      <TextField
        label={t('Provider name')}
        fullWidth
        value={defaultProviderName ?? providerNameInput}
        onChange={e => {
          setProviderNameInput(e.target.value);
        }}
        onKeyUp={onKeyPress}
        placeholder={t('Enter a provider name and press Enter')}
        error={!!errorText.providerName}
        helperText={errorText.providerName}
        data-test="contextual-segments-provider-name-input"
        disabled={isLimitReached}
      />
    </DrawerTopBox>
  );
};
