import type { MappedDeviceType } from 'types';

export const mapDeviceTypeIdsToNames = (deviceTypeIdsString: string, deviceTypes: MappedDeviceType[]): string => {
  if (!deviceTypes.length) {
    return '';
  }

  const idArray = deviceTypeIdsString.split(',').map(str => str.trim());
  const nameMap = {};

  for (const deviceType of deviceTypes) {
    nameMap[deviceType.id] = deviceType.name;
  }

  const mappedNames = idArray.map(id => nameMap[id] || id);

  return mappedNames.join(', ');
};
