import i18n from 'i18next';

const MAX_INPUT_LENGTH = 255;
const MAX_ALLOWED_ITEMS = 2000;

type ValidationResult = {
  error?: string;
};

export const validateTextField = (value: string, selectedItems: Set<string>): ValidationResult => {
  if (value.length > MAX_INPUT_LENGTH) {
    return {
      error: i18n.t('Maximum length is 255 characters.') || 'Maximum length is 255 characters.',
    };
  }
  if (selectedItems.has(value.trim())) {
    return {
      error: i18n.t('Value already exists.') || 'Value already exists.',
    };
  }
  if (value.trim().length !== 0 && selectedItems.size >= MAX_ALLOWED_ITEMS) {
    return {
      error: i18n.t('Maximum number of items reached.') || 'Maximum number of items reached.',
    };
  }
  if (value.trim().length === 0) {
    return {
      error: i18n.t('Value cannot be empty.') || 'Value cannot be empty.',
    };
  }

  return { error: undefined };
};
