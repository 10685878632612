import { t } from 'i18next';

import { type OptionsMap, AllowBlockType } from '@openx/types';

import { SelectedItems, DimensionChip } from '../../../../Targeting/shared';

type MetacategoryAllowBlockItemProps = {
  options?: OptionsMap;
  handleChange: (values: string[], type: AllowBlockType) => void;
  includes: string[];
  excludes: string[];
  readonly?: boolean;
  dataTest: string;
};

export const MetacategoryAllowBlockItem = ({
  options,
  handleChange,
  includes,
  excludes,
  readonly,
  dataTest,
}: MetacategoryAllowBlockItemProps) => {
  const hasAllowedItem = includes?.length !== 0;
  const hasBlockedItem = excludes?.length !== 0;
  const hasBothItems = hasAllowedItem && hasBlockedItem;

  return (
    <>
      {hasAllowedItem && (
        <SelectedItems
          filtersTitle={t('Allowed')}
          isRemovingAll
          isAddIcon={true}
          selectedItems={includes}
          readonly={readonly}
          handleChange={values => handleChange(values, AllowBlockType.ALLOW)}
          options={options}
          dataTest={`metacategory-allow-${dataTest}`}
        />
      )}

      {hasBothItems && <DimensionChip targetingDimension={'AND'} isInsideOption={true} />}

      {hasBlockedItem && (
        <SelectedItems
          filtersTitle={t('Blocked')}
          isRemovingAll
          isAddIcon={false}
          selectedItems={excludes}
          readonly={readonly}
          handleChange={values => handleChange(values, AllowBlockType.BLOCK)}
          options={options}
          dataTest={`metacategory-block-${dataTest}`}
        />
      )}
    </>
  );
};
