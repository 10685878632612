import { Table as MaterialTable, TableBody } from '@mui/material';
import { ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Placeholder } from '@openx/components/core/Placeholder';
import {
  BaseRow,
  ChangeSingleCriteriaAction,
  Columns,
  CriteriaDimension,
  RowAction,
  RowActionButtonBaseProps,
  RowActionProps,
  SortCriteria,
  TableColumn,
} from '@openx/types';

import { HighlightRules } from '../../highlightRules';
import { ActionsCell } from '../ActionsCell';
import ColumnFilter from '../ColumnFilter';
import { CustomTableRow } from '../CustomTableRow';
import { TableHeader } from '../TableHeader';
import { TableRow, TableRowPropsWithData } from '../TableRow';
import { TableRowTree } from '../TableRowTree';

import { ACTION_ICON_CELL, CustomColumnConfig } from './config';
import { loadCustomColumnsConfig, recalculateColumsWidth } from './utils';

function renderRowsSkeleton<RowT extends BaseRow>(columns: Columns<RowT>, rowsAmount = 3): JSX.Element[] {
  const result: JSX.Element[] = [];

  for (let i = 0; i < rowsAmount; i += 1) {
    result.push(<TableRow columns={columns} key={i} />);
  }

  return result;
}

export interface DataSectionProps<RowT extends BaseRow> {
  name?: string;
  data: RowT[];
  columns: Columns<RowT>;
  onRowClick?: RowAction<RowT>;
  loading: boolean;
  noItemsLabel?: (data: RowT) => string;
  isTree?: boolean;
  fetchTreeChildren?: (data: RowT) => Promise<RowT[]>;
  isForcedExpanded?: (data: RowT) => boolean;
  highlightRules?: HighlightRules;
  sortCriteria?: SortCriteria;
  onSortChange: ChangeSingleCriteriaAction<CriteriaDimension.SORT>;
  primaryRowAction?: RowActionButtonBaseProps<RowT>;
  secondaryRowAction?: RowActionButtonBaseProps<RowT>;
  dangerRowAction?: RowActionButtonBaseProps<RowT>;
  defaultRowAction?: RowActionButtonBaseProps<RowT>;
  customRowActions?: RowActionButtonBaseProps<RowT>[];
  rowActions?: RowActionProps<RowT>[];
  treeLevelItems?: number;
  stickyTable?: boolean;
  enableTableHeaderCustomization: boolean;
  renderCustomRow?: (rowProps: TableRowPropsWithData<RowT>, isForcedExpanded?: (data: RowT) => boolean) => ReactElement;
}

export function DataSection<RowT extends BaseRow>(props: DataSectionProps<RowT>): JSX.Element {
  const {
    name,
    noItemsLabel,
    isTree,
    fetchTreeChildren,
    isForcedExpanded,
    loading,
    data,
    onRowClick,
    primaryRowAction,
    secondaryRowAction,
    dangerRowAction,
    defaultRowAction,
    customRowActions,
    rowActions,
    treeLevelItems,
    stickyTable,
    enableTableHeaderCustomization,
    onSortChange,
    highlightRules,
    sortCriteria,
    renderCustomRow,
  } = props;

  const [columns, setColumns] = useState<Columns<RowT>>(loadCustomColumnsConfig(name, props.columns));
  const { t } = useTranslation();

  useEffect(() => {
    setColumns(loadCustomColumnsConfig(name, props.columns));
  }, [props.columns, name]);

  const iconCellRenderer = useMemo(() => {
    if (
      rowActions ||
      primaryRowAction ||
      secondaryRowAction ||
      dangerRowAction ||
      defaultRowAction ||
      customRowActions
    ) {
      return (singleRowData: RowT) => (
        <ActionsCell
          rowData={singleRowData}
          actions={rowActions}
          primaryAction={primaryRowAction}
          secondaryAction={secondaryRowAction}
          dangerAction={dangerRowAction}
          defaultAction={defaultRowAction}
          customRowActions={customRowActions}
        />
      );
    }
    return undefined;
  }, [rowActions, primaryRowAction, secondaryRowAction, dangerRowAction, defaultRowAction, customRowActions]);

  const updateLocalStorage = useCallback(
    (cols: Columns<RowT>) => {
      const config: CustomColumnConfig[] = cols.map(column => {
        return { hide: !!column.hide, key: column.key };
      });

      name && localStorage.setItem(`tableHeaderConfig-${name}`, JSON.stringify(config));
    },
    [name]
  );

  const handleFilterColumn = useCallback(
    (column: TableColumn<RowT>) => {
      const updatedColumns = columns.map(singleColumn => {
        if (singleColumn.key === column.key) {
          singleColumn.hide = !singleColumn.hide;
        }
        return singleColumn;
      });

      const calcColsWidth = recalculateColumsWidth(updatedColumns);
      setColumns(calcColsWidth);
      updateLocalStorage(updatedColumns);
    },
    [columns, updateLocalStorage]
  );

  const filterColumnNode: ReactNode = useMemo(
    () => (
      <ColumnFilter
        columns={columns}
        handleFilterColumn={handleFilterColumn}
        updateLocalStorage={updateLocalStorage}
        updateColumns={setColumns}
      />
    ),
    [columns, handleFilterColumn, updateLocalStorage]
  );

  const filteredColumns = columns.filter(singleColumn => singleColumn.hide !== true);

  const headerColumns = useMemo(() => {
    const icon: ReactNode = filterColumnNode;
    const actionCell = enableTableHeaderCustomization ? [{ key: ACTION_ICON_CELL, title: icon }] : [];

    return [...filteredColumns, ...actionCell];
  }, [filteredColumns, filterColumnNode, enableTableHeaderCustomization]);

  const getRowComponent = useCallback(
    (row: RowT, index: number): JSX.Element => {
      const rowProps = {
        columns: filteredColumns,
        dangerRowAction,
        defaultRowAction,
        highlightRules,
        iconCellRenderer,
        key: row.id || index,
        noItemsLabel,
        onRowClick,
        primaryRowAction,
        rowActions,
        rowData: row,
        secondaryRowAction,
        treeLevelItems,
      };

      if (isTree) {
        return (
          <TableRowTree
            {...rowProps}
            key={`${rowProps.key}|${rowProps.rowData?.children?.length || 0}`}
            isForcedExpanded={isForcedExpanded}
            fetchTreeChildren={fetchTreeChildren}
          />
        );
      }

      if (renderCustomRow) {
        return (
          <CustomTableRow
            key={rowProps.key}
            isForcedExpanded={isForcedExpanded}
            renderCustomRow={renderCustomRow}
            rowProps={rowProps}
          />
        );
      }

      const { key, ...tableProps } = rowProps;

      return <TableRow {...tableProps} key={key} />;
    },
    [
      filteredColumns,
      dangerRowAction,
      defaultRowAction,
      noItemsLabel,
      highlightRules,
      iconCellRenderer,
      onRowClick,
      primaryRowAction,
      rowActions,
      secondaryRowAction,
      treeLevelItems,
      isTree,
      isForcedExpanded,
      fetchTreeChildren,
      renderCustomRow,
    ]
  );

  const rows = useMemo(() => data.map(getRowComponent), [data, getRowComponent]);
  const resultNotFound = !loading && data.length === 0;

  if (resultNotFound) {
    return (
      <Placeholder
        title={t('No results found')}
        subtitle={t('Please try different criteria in order to find matching results')}
      />
    );
  }

  const tableBody = loading ? renderRowsSkeleton(headerColumns) : rows;

  return (
    <MaterialTable>
      <TableHeader onChange={onSortChange} columns={headerColumns} criteria={sortCriteria} stickyTable={stickyTable} />
      <TableBody data-test={loading ? 'table-body-loading' : 'table-body'}>{tableBody}</TableBody>
    </MaterialTable>
  );
}
