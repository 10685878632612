import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { styled } from '@mui/material/styles';

export const StyledInfoIcon = styled(InfoOutlinedIcon)`
  color: ${({ theme }) => theme.palette.info.main};
  height: ${({ theme }) => theme.spacing(2.25)};
  width: ${({ theme }) => theme.spacing(2.25)};
  margin-right: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(0.5, 0.25)};
  transform: translateY(30%);
`;
