import { DealLibraryItem, MappedSortCriteria } from 'types';

import { ColumnKey } from '../constants';

import { filterList } from './filterList';

export type ListState = {
  criteria: MappedSortCriteria;
  dealTemplates: DealLibraryItem[];
  loadingList: boolean;
};

export enum ListActions {
  SET_LIST_DATA = 'SET_LIST_DATA',
  SET_LOADING = 'SET_LOADING',
}

export type ListAction =
  | {
      type: ListActions.SET_LIST_DATA;
      payload: Omit<ListState, 'loadingList'>;
    }
  | {
      type: ListActions.SET_LOADING;
    };

export const listReducer = (state: ListState, action: ListAction): ListState => {
  switch (action.type) {
    case ListActions.SET_LIST_DATA: {
      const filteredList = filterList(
        action.payload.dealTemplates,
        action.payload.criteria.phrase,
        action.payload.criteria.sort.direction,
        action.payload.criteria.sort.column as ColumnKey
      );

      const maxPageNumber = Math.ceil(filteredList.length / action.payload.criteria.pagination.pageSize);
      const pageNumber = Math.min(action.payload.criteria.pagination.pageNumber, maxPageNumber);

      const displayList = filteredList.slice(
        (pageNumber - 1) * action.payload.criteria.pagination.pageSize,
        action.payload.criteria.pagination.pageNumber * action.payload.criteria.pagination.pageSize
      );

      return {
        criteria: {
          pagination: {
            ...action.payload.criteria.pagination,
            pageNumber: pageNumber || 1,
            totalCount: filteredList.length,
          },
          phrase: action.payload.criteria.phrase,
          sort: action.payload.criteria.sort,
        },
        dealTemplates: displayList,
        loadingList: false,
      };
    }

    case ListActions.SET_LOADING: {
      return {
        ...state,
        loadingList: true,
      };
    }

    default:
      return state;
  }
};
