import { Grid } from '@mui/material';
import { ReactNode } from 'react';

import {
  StyledChildrenGrid,
  StyledContainerGrid,
  StyledExcludeItemsRemoveIcon,
  StyledExcludeItemsRemoveIconButton,
} from './styled/ExcludeItems.styled';
import { Title } from './Title';

type ExcludeItemsProps = {
  children: ReactNode;
  title: string;
};

export const ExcludeItems = ({ children, title }: ExcludeItemsProps): JSX.Element => {
  return (
    <StyledContainerGrid>
      <Title title={title} data-test="exclude-subset-allow" />
      <Grid container alignItems="flex-start" wrap="nowrap">
        <StyledExcludeItemsRemoveIconButton disabled size="large">
          <StyledExcludeItemsRemoveIcon fontSize="small" />
        </StyledExcludeItemsRemoveIconButton>
        <StyledChildrenGrid>{children}</StyledChildrenGrid>
      </Grid>
    </StyledContainerGrid>
  );
};
