import { cloneDeep, isEqual } from 'lodash';
import { useState, useCallback, useMemo, memo } from 'react';

import type { CategoryData, CategoryFormState } from '@openx/types';

import { TargetingDrawer, useDrawer } from '../shared';
import { TargetingProps } from '../types';

import { CategoryItems } from './CategoryItems';
import { CategoryReadOnly } from './CategoryReadOnly';
import { CategoryTopBox } from './CategoryTopBox';
import { useResetCategoryToAccountDefault } from './hooks';
import { mapCategoryToFormState, mapCategoryToApi, getDefaultParams } from './utils';

const defaultState = getDefaultParams();

export const Category = memo(function Category({
  targetingParams,
  onFieldUpdate = () => {},
  name,
  readonly,
  field,
  isDisabled,
  accountUid,
}: TargetingProps<CategoryData>) {
  const initState = useMemo(() => mapCategoryToFormState(cloneDeep(targetingParams)), [targetingParams]);
  const [params, updateParams] = useState<CategoryFormState>(initState);
  const isDataUpdated = useMemo(
    () => !isEqual(targetingParams, params.val.size ? mapCategoryToApi(params) : null),
    [params, targetingParams]
  );

  const { isOpen, onDrawerClose, onDrawerOpen, onDrawerApply } = useDrawer({
    onApply: () => onFieldUpdate(field, params.val.size ? mapCategoryToApi(params) : null),
    restoreParams: () => updateParams({ ...initState }),
  });

  const onRemoveAll = useCallback(() => {
    onFieldUpdate(field, null);
    updateParams(defaultState);
  }, [onFieldUpdate, field]);

  const onCategoryChange = useCallback(
    (categories: Set<string>) => {
      updateParams({ op: params.op, val: categories });
    },
    [params.op]
  );

  const { handleResetToAccountDefault, inheritanceInfoComponent, shouldDisplayResetButton } =
    useResetCategoryToAccountDefault({
      accountUid,
      field,
      initState,
      onFieldUpdate,
      onRemoveAll,
      updateParams,
    });

  return (
    <>
      <CategoryReadOnly
        name={name}
        readonly={readonly}
        targetingParams={targetingParams}
        onOpenDrawerClick={onDrawerOpen}
        onRemoveClick={onRemoveAll}
        isDisabled={isDisabled}
        infoComponent={inheritanceInfoComponent}
      />

      {isOpen && (
        <TargetingDrawer name={name} onClose={onDrawerClose} onApply={onDrawerApply} isDataUpdated={isDataUpdated}>
          <CategoryTopBox
            items={params.val}
            onChange={onCategoryChange}
            handleReset={handleResetToAccountDefault}
            shouldDisplayResetButton={shouldDisplayResetButton}
          />

          <CategoryItems targetingParams={params} readonly={false} onChange={updateParams} />
        </TargetingDrawer>
      )}
    </>
  );
});
