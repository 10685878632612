import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from '@openx/components/core';
import { type ContentObjectOption, type ContentObjectProps, type IntersectFormState, Intersect } from '@openx/types';

import { OptionItem, UseContentObjectOptionsConfig, UseOptionFetch, useTargetingContext } from '../../../../utils';
import { SelectedItems, Switch, FiltersTitle, EmptyFilterMessage } from '../../../shared';
import type { TargetingItemsProps } from '../../../types';
import { itemTitle } from '../../constants';

export const IntersectOptionsItems = (
  props: TargetingItemsProps<ContentObjectProps> & {
    type: ContentObjectOption;
  }
) => {
  const { useContentObjectOptionsConfig } = useTargetingContext();

  if (!useContentObjectOptionsConfig) {
    throw new Error('useContentObjectOptionsConfig is not provided');
  }

  return <IntersectOptionsItemsHooksRender {...props} useContentObjectOptionsConfig={useContentObjectOptionsConfig} />;
};

const IntersectOptionsItemsHooksRender = ({
  targetingParams,
  readonly = true,
  onChange = () => {},
  type,
  useContentObjectOptionsConfig,
}: TargetingItemsProps<ContentObjectProps> & {
  type: ContentObjectOption;
  useContentObjectOptionsConfig: UseContentObjectOptionsConfig;
}) => {
  const props = { onChange, readonly, targetingParams, type };
  const optionsConfig = useContentObjectOptionsConfig();
  const { useOptionFetch, ...optionsGroup } = optionsConfig;

  const optionItem = optionsGroup[type];

  if (optionItem === true) {
    return <IntersectFetchOptionsItemsRender useOptionFetch={useOptionFetch} {...props} />;
  }

  return <IntersectOptionsItemsRender optionItem={optionItem} {...props} />;
};

const IntersectFetchOptionsItemsRender = ({
  useOptionFetch,
  ...props
}: TargetingItemsProps<ContentObjectProps> & {
  type: ContentObjectOption;
  useOptionFetch: UseOptionFetch;
}) => {
  const optionItem = useOptionFetch(props.type);
  return <IntersectOptionsItemsRender optionItem={optionItem} {...props} />;
};

const IntersectOptionsItemsRender = ({
  targetingParams,
  readonly = true,
  onChange = () => {},
  type,
  optionItem,
}: TargetingItemsProps<ContentObjectProps> & {
  type: ContentObjectOption;
  optionItem: OptionItem;
}) => {
  const { t } = useTranslation();

  const { options, loading } = optionItem;

  const { op, val } = (targetingParams[type] as IntersectFormState) || {
    op: Intersect.INTERSECTS,
    val: new Set<string>(),
  };

  const handleChange = useCallback(
    (values: string[]) => {
      const selected = new Set(values);
      const returnValue = values.length === 0 ? null : { op, val: selected };

      onChange((prevState: ContentObjectProps) => {
        return {
          ...prevState,
          [type]: returnValue,
        };
      });
    },
    [onChange, op, type]
  );

  const selectedItems = useMemo(() => [...val], [val]);

  if (loading) {
    return <Loader />;
  }

  if (val.size === 0) {
    return <EmptyFilterMessage text={t('No filters applied.')} />;
  }

  const typeLabel = op === Intersect.NOT_INTERSECTS ? t('blocked') : t('allowed');
  const filtersTitle = val.size ? typeLabel : '';
  const switchState = op === Intersect.NOT_INTERSECTS;

  return (
    <div data-test="sub-section">
      <FiltersTitle onClear={() => handleChange([])} title={itemTitle(type, val.size)} readonly={readonly} />

      {!readonly && (
        <Switch
          switchState={switchState}
          onSwitchChange={() =>
            onChange(prevState => ({
              ...prevState,
              [type]: {
                op: switchState ? Intersect.INTERSECTS : Intersect.NOT_INTERSECTS,
                val,
              },
            }))
          }
          switchOffLabel={t('allow')}
          switchOnLabel={t('block')}
          groupTitle={t('the following:')}
        />
      )}

      <SelectedItems
        filtersTitle={filtersTitle}
        isAddIcon={!switchState}
        selectedItems={selectedItems}
        readonly={readonly}
        handleChange={handleChange}
        options={options}
        dataTest={type}
      />
    </div>
  );
};
