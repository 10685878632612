import { KeyboardEvent, useCallback, useMemo, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoCompleteVirtualize } from '@openx/components/core/AutoComplete';
import { ChipFilterValue, FilterOption, FilterConfig } from '@openx/types';

interface MultipleSelectProps {
  setCurrentFilter: (value: ChipFilterValue) => void;
  applyCurrentFilter: (value: ChipFilterValue) => void;
  currentValue: ChipFilterValue;
  filterConfig: Pick<FilterConfig, 'options' | 'loading' | 'onInputChange' | 'noOptionsText'>;
  filteredEmptyOptions: FilterOption[];
  handleBlur: () => void;
  getOptionLabel: (option: FilterOption) => string;
  handleOnInputChange: (event: ChangeEvent<{}>, value: string) => void;
}

export function MultipleSelectFilter({
  setCurrentFilter,
  currentValue,
  filterConfig,
  filteredEmptyOptions,
  handleBlur,
  getOptionLabel,
  handleOnInputChange,
  applyCurrentFilter,
}: MultipleSelectProps) {
  const { options = [], loading, onInputChange, noOptionsText } = filterConfig;
  const { t } = useTranslation();

  const initOption = useMemo(() => {
    if (!currentValue) {
      return [];
    }

    return options.filter(
      option =>
        currentValue.toString().split(',').includes(option?.value.toString()) ||
        (option?.displayName && currentValue.toString().split(',').includes(option?.displayName?.toString()))
    );
  }, [currentValue, options]);

  const [currentOption, setCurrentOption] = useState<FilterOption[]>(initOption);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter' && currentOption.length) {
        applyCurrentFilter(currentOption.map(o => o.value).join(','));
      }
    },
    [currentOption, applyCurrentFilter]
  );

  const onChange = useCallback(
    (_, option) => {
      setCurrentOption(option);
      setCurrentFilter(option.map(o => o.value).join(','));
    },
    [setCurrentFilter, setCurrentOption]
  );

  return (
    <AutoCompleteVirtualize<FilterOption, true>
      data-test="filter-list"
      textFieldProps={{
        placeholder: t('Select or search...'),
      }}
      options={filteredEmptyOptions}
      noOptionsText={noOptionsText || t('No options')}
      onBlur={handleBlur}
      getOptionLabel={getOptionLabel}
      value={currentOption}
      loading={loading}
      disabled={!onInputChange && loading}
      onChange={onChange}
      onInputChange={handleOnInputChange}
      onKeyDown={handleKeyDown}
      renderOptions={{
        dataTest: 'filter-item',
        getOptionValue: option => option.displayName || option.value.toString(),
        getPaddingLevel: option => (option.padding ? option.padding : 0),
        tooltip: true,
      }}
      multiple={true}
      limitTags={99}
    />
  );
}
