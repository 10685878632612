import { Box } from '@mui/system';
import { isEmpty, omitBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IndentedSections } from '@openx/components/core';
import { BoundingBoxOptions, Criteria, GeographicOption, type GeographicTargetingState } from '@openx/types';
import { getGeoLocationDisplayName } from '@openx/utils/state/geoLocationSearch';

import { Switch, FiltersTitle, DimensionChipLine } from '../shared';
import type { TargetingItemsProps } from '../types';

import {
  GeographicLocationItems,
  ExcludeSubsetItems,
  LocationSourceItems,
  CirclesItems,
  BoundingBoxItems,
} from './GeographicLocationItems';
import { useHandleGeoChange } from './hooks';
import { getEmptyLocationState } from './utils';

export const GeographicItems = (props: TargetingItemsProps<GeographicTargetingState>) => {
  const { targetingParams, readonly = true, onChange = () => {} } = props;
  const { t } = useTranslation();
  const { bounding_box, circles, includes, excludes, excludes_subset, location_source } = targetingParams;

  const includesKeys = useMemo(() => Object.keys(includes).filter(key => !isEmpty(includes[key])), [includes]);
  const excludesKeys = useMemo(() => Object.keys(excludes).filter(key => !isEmpty(excludes[key])), [excludes]);

  const includesCount = includesKeys.reduce((acc, key) => acc + includes[key].length, 0);
  const excludesCount = excludesKeys.reduce((acc, key) => acc + excludes[key].length, 0);
  const excludesSubsetCount = excludes_subset.length;
  const locationCount = includesCount + excludesCount + excludesSubsetCount;
  const locationsSwitchState = !includesCount && !!excludesCount;

  const isCirclesOptionSelected = !!circles.length;
  const isLocationSourceSelected = !!location_source.val.size;
  const isBoundingBoxEmpty = !bounding_box[BoundingBoxOptions.LATMIN] && !bounding_box[BoundingBoxOptions.LONGMIN];

  const { handleChange, onLocationRemove, onLocationClear } = useHandleGeoChange(
    targetingParams,
    onChange,
    locationsSwitchState
  );

  const handleLocationSwitchChange = useCallback(() => {
    onChange({
      ...targetingParams,
      [GeographicOption.INCLUDES]: locationsSwitchState
        ? { ...targetingParams[GeographicOption.EXCLUDES] }
        : getEmptyLocationState(),
      [GeographicOption.EXCLUDES_SUBSET]: locationsSwitchState
        ? [...targetingParams[GeographicOption.EXCLUDES_SUBSET]]
        : [],
      [GeographicOption.EXCLUDES]: locationsSwitchState
        ? getEmptyLocationState()
        : { ...targetingParams[GeographicOption.INCLUDES] },
    });
  }, [onChange, locationsSwitchState, targetingParams]);

  const geographicInventoryOptions = {
    [GeographicOption.INCLUDES]: locationCount
      ? (isNotLastItem: boolean) => (
          <div data-test="sub-section">
            <FiltersTitle
              onClear={onLocationClear}
              title={t('Location [ {count} ]', { count: locationCount })}
              readonly={readonly}
            />
            {!readonly && (
              <Switch
                switchState={locationsSwitchState}
                onSwitchChange={handleLocationSwitchChange}
                switchOffLabel={t('include')}
                switchOnLabel={t('exclude')}
                groupTitle={t('the following:')}
              />
            )}

            {locationsSwitchState ? (
              <GeographicLocationItems
                locationKeys={excludesKeys}
                locations={excludes}
                readonly={readonly}
                handleChange={handleChange}
                onLocationRemove={onLocationRemove}
                locationsSwitchState={false}
                getGeoLocationDisplayName={getGeoLocationDisplayName}
              />
            ) : (
              <GeographicLocationItems
                locationKeys={includesKeys}
                locations={includes}
                readonly={readonly}
                handleChange={handleChange}
                onLocationRemove={onLocationRemove}
                locationsSwitchState={true}
                getGeoLocationDisplayName={getGeoLocationDisplayName}
              />
            )}

            <ExcludeSubsetItems {...props} />
            {isNotLastItem && <DimensionChipLine targetingDimension={Criteria.ANY} />}
          </div>
        )
      : null,

    [GeographicOption.CIRCLES]: isCirclesOptionSelected
      ? (isNoLastItem: boolean) => (
          <CirclesItems
            isNotLastItem={isNoLastItem}
            targetingParams={targetingParams}
            readonly={readonly}
            onChange={onChange}
          />
        )
      : null,

    [GeographicOption.BOUNDING_BOX]: !isBoundingBoxEmpty
      ? () => <BoundingBoxItems targetingParams={targetingParams} readonly={readonly} onChange={onChange} />
      : null,
  };

  const selectedInventories = Object.keys(omitBy(geographicInventoryOptions, item => item === null));

  if (isLocationSourceSelected) {
    return (
      <>
        {selectedInventories.length !== 0 && (
          <>
            <IndentedSections>
              {selectedInventories.map((inventory, index) => (
                <Box key={`k-${index}`}>
                  {inventory ? geographicInventoryOptions[inventory](selectedInventories.length - 1 > index) : null}
                </Box>
              ))}
            </IndentedSections>
            <DimensionChipLine targetingDimension={Criteria.ALL} />
          </>
        )}
        <LocationSourceItems targetingParams={targetingParams} readonly={readonly} onChange={onChange} />
      </>
    );
  }

  return (
    <>
      {selectedInventories.map((inventory, index) => (
        <Box key={`k-${index}`}>
          {inventory ? geographicInventoryOptions[inventory](selectedInventories.length - 1 > index) : null}
        </Box>
      ))}
    </>
  );
};
