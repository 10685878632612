import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { ErrorPage } from 'components/ErrorPage';
import { PageHeader } from 'components/PageHeader';
import type { DealLibraryItem } from 'types';

import { LoadingSkeleton } from './components';
import { DealDetailsSection, TargetingCriteriaSection } from './sections';

type DetailsProps = {
  dealTemplates: DealLibraryItem[];
  isLoading: boolean;
  searchParams: string;
};

export const Details = ({ dealTemplates, isLoading, searchParams }: DetailsProps): JSX.Element => {
  const params = useParams();
  const deal = useMemo(
    () => dealTemplates.find(dealTemplate => dealTemplate.id === params.id),
    [dealTemplates, params.id]
  );

  /**
   * Scroll to the top of the page when the component mounts.
   * This is essential when dealing with a larger list of dynamically loaded targeting elements,
   * ensuring a consistent and optimal user experience by presenting the page starting from the top.
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading)
    return (
      <>
        <PageHeader isDetailsPage isLoading />
        <LoadingSkeleton />
      </>
    );

  if (!deal) return <ErrorPage errorType={404} />;

  return (
    <>
      <PageHeader isDetailsPage title={deal.name} searchParams={searchParams} />
      <DealDetailsSection deal={deal} />
      <TargetingCriteriaSection deal={deal} />
    </>
  );
};
