import { useTranslation } from 'react-i18next';

import type { FormatTypeData } from '@openx/types';

import { CtvAlert, TargetingReadOnly, type CtvValidationReturnType } from '../shared';
import type { TargetingReadOnlyProps } from '../types';

import { Items } from './Items';

type CtvValidationMethods = Pick<
  CtvValidationReturnType,
  'setIsTechnographicOpen' | 'shouldDisplayWarningInFormatType'
>;

const defaultCustomValidationMethods: CtvValidationMethods = {
  setIsTechnographicOpen: () => {},
  shouldDisplayWarningInFormatType: false,
};

export const FormatTypeReadOnly = ({
  name,
  onOpenDrawerClick,
  onRemoveClick,
  targetingParams,
  readonly,
  isDisabled,
  customValidationMethods = defaultCustomValidationMethods,
}: TargetingReadOnlyProps<FormatTypeData, CtvValidationMethods>) => {
  const { t } = useTranslation();
  const { shouldDisplayWarningInFormatType, setIsTechnographicOpen } = customValidationMethods;

  return (
    <TargetingReadOnly
      name={name}
      isEmpty={!targetingParams}
      onOpenDrawerClick={onOpenDrawerClick}
      onRemove={onRemoveClick}
      readonly={readonly}
      isDisabled={isDisabled}
    >
      {targetingParams && <Items targetingParams={targetingParams} />}

      {shouldDisplayWarningInFormatType && (
        <CtvAlert
          invalidField={t('Technology and Devices')}
          invalidParams={[t('Device Type = Connected TV, Set Top Box')]}
          onOpen={setIsTechnographicOpen}
          dataTest={'ctv-alert-in-format-type'}
        />
      )}
    </TargetingReadOnly>
  );
};
