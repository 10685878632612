import { useTranslation } from 'react-i18next';

import { Criteria, type TargetingDimension, ComparisonType, type CriteriaValue } from '@openx/types';

import { ListIconType } from './types';

export const ITEMS_CHARACTER_LIMIT = 500;
export const ITEMS_TEXTAREA_LIMIT = 2000;
export const GROUPED_ITEMS_ROWS_LIMIT = 7;

export const targetingDimension: Record<string, TargetingDimension> = {
  [Criteria.ALL]: { inter_dimension_operator: 'AND' },
  [Criteria.ANY]: { inter_dimension_operator: 'OR' },
};

export const criteriasOptions = (t): { label: string; value: CriteriaValue }[] => [
  { label: t('MATCH ALL'), value: Criteria.ALL },
  { label: t('MATCH ANY'), value: Criteria.ANY },
];

export const useComparisonLabels = () => {
  const { t } = useTranslation();
  return {
    [ComparisonType.EQUALS]: t('EQUALS'),
    [ComparisonType.DOES_NOT_EQUAL]: t('DOES NOT EQUAL'),
    [ComparisonType.ENDS_WITH]: t('ENDS WITH'),
    [ComparisonType.CONTAINS]: t('CONTAINS'),
    [ComparisonType.BEGINS_WITH]: t('BEGINS WITH'),
    [ComparisonType.DOES_NOT_END_WITH]: t('DOES NOT END WITH'),
    [ComparisonType.DOES_NOT_CONTAIN]: t('DOES NOT CONTAIN'),
    [ComparisonType.DOES_NOT_BEGIN_WITH]: t('DOES NOT BEGIN WITH'),
    [ComparisonType.LESS_THAN]: t('LESS THAN'),
    [ComparisonType.LESS_THAN_OR_EQUAL_TO]: t('LESS THAN OR EQUAL TO'),
    [ComparisonType.GREATER_THAN]: t('GREATER THAN'),
    [ComparisonType.GREATER_THAN_OR_EQUAL_TO]: t('GREATER THAN OR EQUAL TO'),
    [ComparisonType.BEFORE]: t('BEFORE'),
    [ComparisonType.AFTER]: t('AFTER'),
    [ComparisonType.INTERSECTS]: t('CONTAINS ANY OF'),
    [ComparisonType.INVERSE_IN]: t('CONTAINS ALL OF'),
    [ComparisonType.EQ]: t('CONTAINS ONLY'),
    [ComparisonType.NOT_INTERSECTS]: t('CONTAINS NONE OF'),
  };
};

export const mapTypeToIconType = {
  [ComparisonType.EQUALS]: ListIconType.INCLUDE,
  [ComparisonType.DOES_NOT_EQUAL]: ListIconType.EXCLUDE,
  [ComparisonType.ENDS_WITH]: ListIconType.INCLUDE,
  [ComparisonType.CONTAINS]: ListIconType.INCLUDE,
  [ComparisonType.BEGINS_WITH]: ListIconType.INCLUDE,
  [ComparisonType.DOES_NOT_END_WITH]: ListIconType.EXCLUDE,
  [ComparisonType.DOES_NOT_CONTAIN]: ListIconType.EXCLUDE,
  [ComparisonType.DOES_NOT_BEGIN_WITH]: ListIconType.EXCLUDE,
  [ComparisonType.LESS_THAN]: ListIconType.INCLUDE,
  [ComparisonType.LESS_THAN_OR_EQUAL_TO]: ListIconType.INCLUDE,
  [ComparisonType.GREATER_THAN]: ListIconType.INCLUDE,
  [ComparisonType.GREATER_THAN_OR_EQUAL_TO]: ListIconType.INCLUDE,
  [ComparisonType.BEFORE]: ListIconType.INCLUDE,
  [ComparisonType.AFTER]: ListIconType.INCLUDE,
  [ComparisonType.INTERSECTS]: ListIconType.INCLUDE,
  [ComparisonType.INVERSE_IN]: ListIconType.INCLUDE,
  [ComparisonType.EQ]: ListIconType.INCLUDE,
  [ComparisonType.NOT_INTERSECTS]: ListIconType.INCLUDE,
};
