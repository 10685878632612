import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { AutoComplete } from '@openx/components/core';
import {
  AdvancedTargetingData,
  AdvancedTargetingType,
  AdvancedTargetingTypeLabel,
  TargetingFields,
  type CustomVariablesData,
} from '@openx/types';

import { DrawerTopBox } from '../shared';

import { ContextualSegmentsTopBox } from './ContextualSegments/ContextualSegmentsTopBox';
import { CustomVariablesTopBox } from './CustomVariables/CustomVariablesTopBox';

interface AdvancedTargetingTopBoxProps {
  items: AdvancedTargetingData;
  onEnter: (items: CustomVariablesData) => void;
  onTypeChange: (value: AdvancedTargetingType) => void;
  type?: AdvancedTargetingType;
}

export const AdvancedTargetingTopBox = ({ items, onEnter, onTypeChange, type }: AdvancedTargetingTopBoxProps) => {
  const { t } = useTranslation();

  return (
    <DrawerTopBox>
      <Grid container>
        <Grid item xs={4}>
          <AutoComplete
            textFieldProps={{ label: 'Targeting Type', placeholder: t('[ Select Targeting Type ]') }}
            disableClearable
            options={Object.keys(AdvancedTargetingTypeLabel)}
            fullWidth
            renderOptions={{
              dataTest: 'type-option',
              tooltip: true,
            }}
            getOptionLabel={option => AdvancedTargetingTypeLabel[option] || option}
            onChange={(_, value) => onTypeChange(value as AdvancedTargetingType)}
            value={type ?? ''}
            textFieldReadOnly
            data-test="select-targeting-type"
          />
        </Grid>
        <Grid item xs={8} sx={{ paddingLeft: '0.8rem' }}>
          {type === TargetingFields.CONTEXTUAL_SEGMENTS && (
            <ContextualSegmentsTopBox items={items} onEnter={onEnter} sx={{ borderBottom: 0, paddingTop: 0 }} />
          )}
          {type === TargetingFields.CUSTOM_VARIABLES && (
            <CustomVariablesTopBox items={items} onEnter={onEnter} sx={{ borderBottom: 0, paddingTop: 0 }} />
          )}
        </Grid>
      </Grid>
    </DrawerTopBox>
  );
};
