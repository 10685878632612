import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from '@openx/components/core';
import { Intersect, TechnologyAndDevicesFormState, TechnologyAndDevicesOption } from '@openx/types';

import { useTargetingContext } from '../../../utils';
import { SelectedItems, Switch, FiltersTitle } from '../../shared';
import type { TargetingItemsProps } from '../../types';
import { MobileCarrierOptions, MobileCarrierWifiOption } from '../constants';

export const MobileCarrierItems = ({
  targetingParams,
  readonly = true,
  onChange = () => {},
}: TargetingItemsProps<TechnologyAndDevicesFormState>) => {
  const { t } = useTranslation();
  const { useTechnologyOptionsFetch } = useTargetingContext();
  const { options, isLoading } = useTechnologyOptionsFetch(TechnologyAndDevicesOption.MOBILE_CARRIER, true);

  const { op, val } = targetingParams[TechnologyAndDevicesOption.MOBILE_CARRIER];
  const switchState = op === Intersect.NOT_INTERSECTS;
  const filtersTitle = switchState ? t('Is not any of') : t('Is any of');
  const isMobileCarierWifiOnlySelected =
    String(op) === MobileCarrierWifiOption.wifi && Array.from(val).join('') === MobileCarrierOptions.isWifiOnly;

  const handleChange = useCallback(
    (values: string[]) => {
      const selected = new Set(values);

      onChange(state => ({
        ...state,
        [TechnologyAndDevicesOption.MOBILE_CARRIER]: { op, val: selected },
      }));
    },
    [onChange, op]
  );

  const onSwitchChange = useCallback(() => {
    onChange(state => ({
      ...state,
      [TechnologyAndDevicesOption.MOBILE_CARRIER]: {
        op: op === Intersect.INTERSECTS ? Intersect.NOT_INTERSECTS : Intersect.INTERSECTS,
        val,
      },
    }));
  }, [onChange, op, val]);

  const selectedItems = useMemo(() => {
    return [...val];
  }, [val]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div data-test="sub-section">
      <FiltersTitle
        onClear={() => handleChange([])}
        title={t('Mobile Carrier [ {count} ]', { count: val.size })}
        readonly={readonly}
      />

      {!readonly && !isMobileCarierWifiOnlySelected && (
        <Switch
          switchState={switchState}
          onSwitchChange={onSwitchChange}
          switchOffLabel={t('Is any of')}
          switchOnLabel={t('Is not any of')}
        />
      )}

      <SelectedItems
        filtersTitle={readonly && !isMobileCarierWifiOnlySelected ? filtersTitle : ''}
        isAddIcon={!switchState}
        selectedItems={selectedItems}
        readonly={readonly}
        handleChange={handleChange}
        options={options}
        dataTest={'mobile-carrier'}
      />
    </div>
  );
};
