import { OptionsType, SelectRateFormState } from '@openx/types';

import { TargetingReadOnlyProps } from '../../types';
import { TargetingReadOnly } from '../TargetingReadOnly';

import { Items } from './Items';

interface SelectRateReadOnlyProps extends TargetingReadOnlyProps<SelectRateFormState> {
  filterTitle: string;
  dataTest?: string;
  optionsFetch: OptionsType.VIEWABILITY | OptionsType.VIEW_THROUGH_RATE;
}

export const SelectRateReadOnly = ({
  name,
  onOpenDrawerClick,
  onRemoveClick,
  targetingParams,
  readonly,
  isDisabled,
  optionsFetch,
  filterTitle,
  dataTest,
}: SelectRateReadOnlyProps) => {
  const isEmpty = !targetingParams || !targetingParams?.val;

  return (
    <TargetingReadOnly
      name={name}
      isEmpty={isEmpty}
      onOpenDrawerClick={onOpenDrawerClick}
      onRemove={onRemoveClick}
      readonly={readonly}
      isDisabled={isDisabled}
    >
      {!isEmpty && (
        <Items
          targetingParams={targetingParams as SelectRateFormState}
          optionsFetch={optionsFetch}
          title={filterTitle}
          dataTest={dataTest}
        />
      )}
    </TargetingReadOnly>
  );
};
