import { Grid } from '@mui/material';
import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  GeoLocationTree,
  GeoLocation,
  GeoLocationTypes,
  GeographicOption,
  GeographicTargetingState,
} from '@openx/types';
import { getGeoLocationDisplayName, getType } from '@openx/utils/state/geoLocationSearch';

import { ExcludeItems, ExcludeItemsBox, TreeItem, Item } from '../../shared';
import { TargetingItemsProps } from '../../types';
import { GeographicAutoComplete } from '../GeographicTopBox/GeographicAutoComplete';
import {
  removeContainedLocalisations,
  groupByUncontainedLocalisations,
  getChildrenDisplayName,
  convertGeoObjToArray,
  getEmptyLocationState,
} from '../utils';

interface ExcludeSubsetItemsProps extends TargetingItemsProps<GeographicTargetingState> {
  geoLocationType?: GeoLocationTypes;
}

export const ExcludeSubsetItems = ({
  targetingParams,
  readonly = true,
  onChange = () => {},
}: ExcludeSubsetItemsProps) => {
  const { includes, excludes_subset } = targetingParams;
  const { t } = useTranslation();

  const includesAsArray = useMemo(() => convertGeoObjToArray(includes), [includes]);
  const uncontainedLocalisations = useMemo(() => removeContainedLocalisations(includesAsArray), [includesAsArray]);
  const groupedExcludes = useMemo(
    () => groupByUncontainedLocalisations(excludes_subset, uncontainedLocalisations),
    [excludes_subset, uncontainedLocalisations]
  );
  const { grouped, other } = groupedExcludes;

  const onParentDelete = useCallback(
    (parent: GeoLocationTree) => {
      const filteredExcludes = excludes_subset.filter(
        geo => !((geo.id === parent.id && geo.type === parent.type) || geo[parent.type] === parent.name)
      );

      onChange({
        ...targetingParams,
        [GeographicOption.EXCLUDES]: getEmptyLocationState(),
        [GeographicOption.EXCLUDES_SUBSET]: filteredExcludes,
      });
    },
    [excludes_subset, onChange, targetingParams]
  );

  const onItemDelete = useCallback(
    (item: GeoLocation) => {
      const filteredExcludes = excludes_subset.filter(geo => !(geo.id === item.id && geo.type === item.type));

      onChange({
        ...targetingParams,
        [GeographicOption.EXCLUDES]: getEmptyLocationState(),
        [GeographicOption.EXCLUDES_SUBSET]: filteredExcludes,
      });
    },
    [excludes_subset, onChange, targetingParams]
  );

  const otherItems = useMemo(() => {
    return (
      <Grid>
        {other.map((geo, index) => (
          <Item
            testId="exclude-subset-item"
            key={geo['type-id']}
            readonly={readonly}
            item={geo}
            onRemove={onItemDelete}
          >
            {getGeoLocationDisplayName(geo)}
            {index < other.length - 1 ? ',' : ''}
          </Item>
        ))}
      </Grid>
    );
  }, [onItemDelete, other, readonly]);

  return (
    <ExcludeItemsBox>
      {!readonly && includesAsArray.length ? (
        <div data-test="exclude-location">
          <GeographicAutoComplete
            targetingParams={targetingParams}
            onSelect={item =>
              onChange({
                ...targetingParams,
                [GeographicOption.EXCLUDES_SUBSET]: [...targetingParams[GeographicOption.EXCLUDES_SUBSET], item],
              })
            }
            includeArea={uncontainedLocalisations}
            inputLabel={t('Exclude subset of selected Location')}
            excludeSubset
          />
        </div>
      ) : null}
      {includesAsArray.length && excludes_subset.length ? (
        <ExcludeItems title={t('excluded subset of selected location')}>
          {grouped.map(parent => (
            <Grid key={parent['type-id']}>
              <Item testId="exclude-subset-item" readonly={readonly} item={parent} onRemove={onParentDelete}>
                {getType(parent)}
              </Item>
              {parent.childrens.map(geo => (
                <TreeItem
                  testId="exclude-subset-item"
                  readonly={readonly}
                  onRemove={onItemDelete}
                  item={geo}
                  key={geo['type-id']}
                >
                  {getChildrenDisplayName(geo, parent.type as GeoLocationTypes)}
                </TreeItem>
              ))}
            </Grid>
          ))}
          {otherItems}
        </ExcludeItems>
      ) : null}
    </ExcludeItemsBox>
  );
};
