import { Grid, Typography } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import {
  NoFiltersInfoAlert,
  DimensionChip,
  TargetingConfig,
  TargetingFields,
  ExcludeIndirect,
  useTargetingContext,
  ExcludeMFA,
} from '@openx/components/targeting';

import { PackageFields, PackageFormState } from '../../PackageForm';

export type TargetingReadOnlyListProps = {
  targeting: TargetingConfig[];
  packageState: PackageFormState;
};

const StyledTargetingSubSectionTitle = props => <Typography variant="h3" width="100%" mb={1.5} {...props} />;

export function TargetingReadOnlyList({ targeting, packageState }: TargetingReadOnlyListProps): JSX.Element {
  const { t } = useTranslation();
  const { useCurrentInstanceState } = useTargetingContext();
  const { isBefInstance } = useCurrentInstanceState();

  const {
    [TargetingFields.METACATEGORY]: metacategory,
    [TargetingFields.EXCLUDE_INDIRECT]: excludeIndirectValue,
    [TargetingFields.INCLUDE_OPEN_BIDDING_INVENTORY]: includeOpenBiddingInventoryValue,
  } = packageState[PackageFields.TARGETING];

  const excludeMFAValue = metacategory?.[TargetingFields.EXCLUDE_MFA] || false;
  const noTargetingApplied = !targeting.length && !excludeIndirectValue && !excludeMFAValue;

  if (noTargetingApplied) {
    return <NoFiltersInfoAlert />;
  }

  return (
    <>
      <Grid alignItems="center" mb={3} container>
        <StyledTargetingSubSectionTitle data-test="targeting-settings-quality-filters-title">
          {t('Inventory Quality Filter')}
        </StyledTargetingSubSectionTitle>
        {isBefInstance && excludeIndirectValue && (
          <ExcludeIndirect
            excludeIndirectValue={excludeIndirectValue}
            includeOpenBiddingInventory={includeOpenBiddingInventoryValue}
            readonly
          />
        )}

        <Grid container mt={1.5}>
          {metacategory && <ExcludeMFA excludeMFAValue={excludeMFAValue} readonly />}
        </Grid>
      </Grid>

      <StyledTargetingSubSectionTitle data-test="targeting-settings-title" mt={1.5}>
        {t('Inventory Targeting')}
      </StyledTargetingSubSectionTitle>
      {targeting.map(({ field, name, TargetingComponent }, index) => {
        let targetingParams = packageState[PackageFields.TARGETING][field];
        if (field === TargetingFields.CUSTOM_VARIABLES) {
          const custom = packageState[PackageFields.TARGETING][TargetingFields.CUSTOM_VARIABLES];
          const op = custom?.op;
          targetingParams = {
            contextual: custom?.contextual.length ? custom?.contextual : null,
            custom: custom?.custom.length ? custom?.custom : null,
            op,
          };
        }

        const shouldShowChip = index < targeting.length - 1;

        return (
          <Fragment key={field}>
            <TargetingComponent
              name={name}
              field={field}
              targetingParams={targetingParams}
              accountUid={packageState.account_uid}
              readonly
            />
            {shouldShowChip && <DimensionChip targetingDimension={packageState.targeting.dimension} />}
          </Fragment>
        );
      })}
    </>
  );
}
