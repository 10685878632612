import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface EmptyFilterMessageProps {
  text: string | JSX.Element;
}

const StyledTypography = styled(Typography)`
  margin-top: 25px;
  color: #0d0d0d;
`;

export const EmptyFilterMessage = ({ text }: EmptyFilterMessageProps) => {
  return (
    <StyledTypography data-test="empty-section" variant="body1">
      {text}
    </StyledTypography>
  );
};
