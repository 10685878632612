import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { type InvalidItemsError, type ValidationResult } from '../BulkAdd';

export type AllExistingKeywords =
  | {
      generalKeywords: string[];
    }
  | {
      allowKeywords: string[];
      blockKeywords: string[];
    };

export type AllExistingKeywordsBulk = AllExistingKeywords & {
  existingValues: string[];
};

export const formatKeyword = (keyword: string | undefined): string => {
  if (!keyword) return '';

  let formattedValue = keyword.trim().toLowerCase();

  //Replace sequences of multiple dashes with a single dash
  formattedValue = formattedValue.replace(/-+/g, '-');

  //Remove leading and trailing dashes
  formattedValue = formattedValue.replace(/^-|-$/g, '');

  return formattedValue;
};

export const useKeywordValidation = () => {
  const { t } = useTranslation();

  const validateKeyword = useCallback(
    ({
      allExistingKeywords,
      keyword,
      keywordsLimit,
      keywords,
    }: {
      allExistingKeywords: AllExistingKeywords | AllExistingKeywordsBulk;
      keyword: string | undefined;
      keywords: string[];
      keywordsLimit?: number;
    }) => {
      let errorMessage = '';

      if (!keyword) {
        errorMessage = t('This field cannot be empty.');
      } else if ('allowKeywords' in allExistingKeywords && allExistingKeywords.allowKeywords.includes(keyword)) {
        errorMessage = t('This keyword already exists on allow list.');
      } else if ('blockKeywords' in allExistingKeywords && allExistingKeywords.blockKeywords.includes(keyword)) {
        errorMessage = t('This keyword already exists on block list.');
      } else if ('generalKeywords' in allExistingKeywords && allExistingKeywords.generalKeywords.includes(keyword)) {
        errorMessage = t('This keyword already exists on keyword list.');
      } else if ('existingValues' in allExistingKeywords && allExistingKeywords.existingValues.includes(keyword)) {
        errorMessage = t('Duplicated value on bulk list.');
      } else if (!/[a-zA-Z0-9]/.test(keyword)) {
        errorMessage = t('Keyword must contain at least one letter or number.');
      } else if (/[^a-zA-Z0-9-]/.test(keyword)) {
        errorMessage = t('Keyword can only contain letters, numbers, or "-".');
      } else if (keywordsLimit && keywords.length >= keywordsLimit) {
        errorMessage = t(`Keywords limit ${keywordsLimit} reached.`);
      }

      return errorMessage;
    },
    [t]
  );

  const validateBulkKeywords = useCallback(
    (values: string[], allExistingKeywords: AllExistingKeywords): Promise<ValidationResult> => {
      const errors: InvalidItemsError[] = [];
      const validItems: string[] = [];

      const allExistingKeywordsBulk = {
        ...allExistingKeywords,
        existingValues: [],
      } as AllExistingKeywordsBulk;

      values.forEach(value => {
        const formattedKeyword = formatKeyword(value);
        const errorMessage = validateKeyword({
          allExistingKeywords: allExistingKeywordsBulk,
          keyword: formattedKeyword,
          keywords: [],
        });

        if (errorMessage) {
          const errorObject = errors.find(singleError => singleError.error === errorMessage);

          if (errorObject) {
            errorObject.invalidItems.push(value);
          } else {
            errors.push({ error: errorMessage, invalidItems: [value] });
          }
        } else {
          validItems.push(formattedKeyword);
          allExistingKeywordsBulk.existingValues.push(formattedKeyword);
        }
      });

      return new Promise<ValidationResult>(res => res({ errors, validItems }));
    },
    [validateKeyword]
  );

  return { validateBulkKeywords, validateKeyword };
};
