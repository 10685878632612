import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoComplete } from '@openx/components/core';
import { VideoOption } from '@openx/types';

type Props = {
  onChange: (type: VideoOption) => void;
  value: VideoOption;
};

export const TypeOptionDropdown = (props: Props): JSX.Element => {
  const { onChange, value } = props;
  const { t } = useTranslation();

  const labels = useMemo(
    () => ({
      [VideoOption.IN_STREAM_VIDEO_POSITION]: t('In-stream Video Position'),
      [VideoOption.MAX_AD_DURATION]: t('Maximum Ad Duration'),
      [VideoOption.PRESENTATION_FORMAT]: t('Presentation Formats'),
      [VideoOption.PLACEMENT]: t('Placement'),
      [VideoOption.REWARDED_VIDEO]: t('Rewarded Video'),
      [VideoOption.VIDEO_SKIPABILITY]: t('Video Skippability'),
    }),
    [t]
  );

  return (
    <AutoComplete<VideoOption, false, true, false>
      textFieldProps={{ label: t('Type') }}
      options={Object.keys(labels) as VideoOption[]}
      renderOptions={{
        dataTest: 'type-option',
      }}
      getOptionLabel={option => labels[option]}
      textFieldReadOnly
      disableClearable
      filterOptions={option => option}
      onChange={(e, value) => onChange(value)}
      value={value}
      fullWidth
      data-test="video-type"
    />
  );
};
