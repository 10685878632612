import { CustomVariablesDataPayload } from './customVariables';
import { TargetingFields } from './targeting';
import { CriteriaValue } from './targetingValuesTypes';

export const AdvancedTargetingTypeLabel = {
  [TargetingFields.CUSTOM_VARIABLES]: 'Custom Variables',
  [TargetingFields.CONTEXTUAL_SEGMENTS]: 'Contextual Segments',
} as const;
export type AdvancedTargetingType = keyof typeof AdvancedTargetingTypeLabel;

export interface AdvancedTargetingData {
  op: CriteriaValue;
  contextual: CustomVariablesDataPayload[];
  custom: CustomVariablesDataPayload[];
}
