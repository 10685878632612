import { ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material';

import { StyledSwitchGrid, StyledSwitchGroupTitle } from './Switch.styled';

export type SwitchProps = {
  switchState: boolean;
  onSwitchChange: ToggleButtonGroupProps['onChange'];
  switchOffLabel: string;
  switchOnLabel: string;
  groupTitle?: string;
  disabled?: boolean;
};

export const Switch = ({
  switchState,
  onSwitchChange,
  switchOffLabel,
  switchOnLabel,
  groupTitle,
  disabled,
}: SwitchProps): JSX.Element => {
  return (
    <StyledSwitchGrid data-test="switch-container" container alignItems="center">
      <ToggleButtonGroup
        exclusive
        onChange={onSwitchChange}
        value={switchState}
        disabled={disabled}
        data-test="toggle-button-group"
      >
        <ToggleButton data-test="switch-one" id={switchOffLabel} value={false}>
          {switchOffLabel}
        </ToggleButton>
        <ToggleButton data-test="switch-two" id={switchOnLabel} value={true}>
          {switchOnLabel}
        </ToggleButton>
      </ToggleButtonGroup>
      {groupTitle && <StyledSwitchGroupTitle data-test="switch-group-title">{groupTitle}</StyledSwitchGroupTitle>}
    </StyledSwitchGrid>
  );
};
