import { useNavigate } from 'react-router-dom';

type ErrorProps = {
  buttonLabel: string;
  navigateTo: () => void;
  subtitle: string;
  title: string;
};

type ErrorTextObject = {
  [key: number]: ErrorProps;
};

export const useGetErrorProps = (errorType: number): ErrorProps => {
  const navigate = useNavigate();

  const errorTextObject: ErrorTextObject = {
    404: {
      buttonLabel: 'Take me to the Deal Library list',
      navigateTo: () => navigate('/'),
      subtitle: "We've looked everywhere and we can't find the page you're looking for.",
      title: 'Page Not Found',
    },
    500: {
      buttonLabel: 'Refresh page',
      navigateTo: () => navigate(0),
      subtitle: 'Something went wrong. Please refresh your browser.',
      title: 'Ooops!',
    },
  };

  return errorTextObject[errorType];
};
