import type { TFunction } from 'i18next';
import _, { isEmpty } from 'lodash';

import {
  type InventoryOptionPayloadApiFormat,
  type TargetableInventory,
  type TargetableInventoryList,
  AccountType,
  type InventoryAndContentState,
  type InventoryAndContentApi,
  InventoryContentOption,
  TargetableInventoryOption,
  TargetableInventoryType,
  type TargetableInventoryExcludes,
  TargetableInventoryParentSearch,
  type TargetableInventoryEntities,
  type InstancesByIdObject,
  type inventoryTargetingContent,
  type IntersectOptionPayload,
  type ComparisonList,
  type ComparisonMap,
  ComparisonType,
  type InventoryContentIdsTypeOption,
} from '@openx/types';

import { InvalidItemsError, ValidationResult } from '../BulkAdd';

import { INSTANCE_HASH_REGEX, ID_REGEX } from './constants';
import { getEmptyInventoryState } from './inventoryHelpers';

export function getInventoryDisplayName(inventory: TargetableInventory, noParent?: boolean): string {
  const type = inventory.type;
  const parentName = noParent ? '' : `${inventory.parent_name} > `;

  switch (type) {
    case TargetableInventoryType.ADUNIT:
      return `${parentName}${inventory.name} (Ad Unit)`;
    case TargetableInventoryType.SITESECTION:
      return `${parentName}${inventory.name} (Section)`;
    case TargetableInventoryType.ADUNITGROUP:
      return `${parentName}${inventory.name} (Ad Unit Group)`;
    case TargetableInventoryType.SITE:
      return `${inventory.name} (Site)`;
    case TargetableInventoryType.ACCOUNT:
      if (inventory.type_full === AccountType.AD_NETWORK) {
        return `${inventory.name} (Network)`;
      }
      return `${inventory.name} (Publisher)`;
    default:
      return `${inventory.name} (unknown type)`;
  }
}

export const mapInventoriesListToApiFormat = (list: TargetableInventoryList): InventoryOptionPayloadApiFormat => {
  const data: InventoryOptionPayloadApiFormat = {};

  for (const item of list) {
    let type = item.type as string;
    if (item.type_full === AccountType.AD_NETWORK) {
      type = TargetableInventoryType.NETWORK;
    }
    if (item.type === TargetableInventoryType.ADUNITGROUP) {
      type = 'page';
    }
    data[type] = data[type] ? `${data[type]},${item.id}` : item.id;
  }

  return data;
};

export const prepareInstancesById = (
  includes: TargetableInventoryList,
  excludes: TargetableInventoryList
): InstancesByIdObject => {
  const includesPart = prepareIdByInstancesForInventoryList(includes, 'includes');
  const excludesPart = prepareIdByInstancesForInventoryList(excludes, 'excludes');

  return _.merge(includesPart, excludesPart);
};

const prepareIdByInstancesForInventoryList = (list: TargetableInventoryList, option: string): InstancesByIdObject => {
  const data: InstancesByIdObject = {};

  for (const item of list) {
    let type = item.type as string;
    const instanceId = item.instance_uid as string;
    if (item.type_full === AccountType.AD_NETWORK) {
      type = TargetableInventoryType.NETWORK;
    }

    if (data[instanceId]) {
      data[instanceId][option][type] = data[instanceId][option][type]
        ? `${data[instanceId][option][type]},${item.id}`
        : item.id;
    } else {
      data[instanceId] = { [option]: {} };
      data[instanceId][option][type] = data[instanceId][option][type]
        ? `${data[instanceId][option][type]},${item.id}`
        : item.id;
    }
  }
  return data;
};

export const inventoryApiToState = (
  inventoryApiData: InventoryAndContentApi | null | undefined
): InventoryAndContentState => {
  const initState: InventoryAndContentState = getEmptyInventoryState();

  if (!inventoryApiData) {
    return initState;
  }

  Object.keys(inventoryApiData).forEach(contentOption => {
    const data = inventoryApiData[contentOption];

    if (!data) {
      return initState;
    }

    switch (contentOption) {
      case InventoryContentOption.CONTENT_INTER_DIMENSION_OPERATOR: {
        initState[contentOption] = data;
        break;
      }
      case InventoryContentOption.ADUNIT:
      case InventoryContentOption.ADUNIT_SIZE:
      case InventoryContentOption.PUBLISHER_ID:
      case InventoryContentOption.INSTANCE_HASH:
      case InventoryContentOption.LINE_ITEM_APP_BUNDLE_ID:
      case InventoryContentOption.SITE: {
        initState[contentOption] = {
          op: data.op,
          val: data.val.split(','),
        };
        break;
      }
      case InventoryContentOption.KEYWORDS: {
        initState[contentOption] = {
          op: data.op,
          val: data.val,
        };
        break;
      }
      case InventoryContentOption.INVENTORY:
        initState[InventoryContentOption.INVENTORY][TargetableInventoryOption.INCLUDES] = [];
        initState[InventoryContentOption.INVENTORY][TargetableInventoryOption.EXCLUDES] = [];
        break;
      default: {
        if (data.op && Array.isArray(data.val)) {
          initState[contentOption] = {
            op: data.op,
            val: mapByComparison(data.val),
          };
        }
        break;
      }
    }
  });

  return initState;
};

export const inventoryStateToApi = (
  inventoryState: InventoryAndContentState,
  addIdByInstances: boolean
): InventoryAndContentApi | null => {
  const apiData: InventoryAndContentApi = {};

  const includes = inventoryState[InventoryContentOption.INVENTORY][TargetableInventoryOption.INCLUDES];
  const excludes = inventoryState[InventoryContentOption.INVENTORY][TargetableInventoryOption.EXCLUDES];
  const appendInventories = !isEmpty(includes) || !isEmpty(excludes);
  const setContentOperatorToNull =
    isEmpty(inventoryState[InventoryContentOption.ADUNIT].val) &&
    isEmpty(inventoryState[InventoryContentOption.ADUNIT_SIZE].val) &&
    isEmpty(inventoryState[InventoryContentOption.APP_BUNDLE_ID].val) &&
    !appendInventories &&
    isEmpty(inventoryState[InventoryContentOption.PAGE_URL].val) &&
    isEmpty(inventoryState[InventoryContentOption.KEYWORDS].val) &&
    isEmpty(inventoryState[InventoryContentOption.PUBLISHER_ID].val) &&
    isEmpty(inventoryState[InventoryContentOption.INSTANCE_HASH].val) &&
    isEmpty(inventoryState[InventoryContentOption.LINE_ITEM_APP_BUNDLE_ID].val) &&
    isEmpty(inventoryState[InventoryContentOption.SITE].val);

  Object.keys(inventoryState).forEach(contentOption => {
    const data = inventoryState[contentOption];

    switch (contentOption) {
      case InventoryContentOption.PUBLISHER_ID:
      case InventoryContentOption.INSTANCE_HASH:
      case InventoryContentOption.ADUNIT:
      case InventoryContentOption.ADUNIT_SIZE:
      case InventoryContentOption.LINE_ITEM_APP_BUNDLE_ID:
      case InventoryContentOption.SITE: {
        if (data.val.length) {
          apiData[contentOption] = {
            op: data.op,
            val: data.val.join(','),
          };
        }
        break;
      }
      case InventoryContentOption.CONTENT_INTER_DIMENSION_OPERATOR: {
        apiData[contentOption] = setContentOperatorToNull ? null : data;
        break;
      }
      case InventoryContentOption.KEYWORDS: {
        if (data.val.length) {
          apiData[contentOption] = {
            op: data.op,
            val: data.val,
          };
        }
        break;
      }
      default: {
        const itemsList = comparisonMapToList(data.val);
        // only add content option if it contains any items
        if (itemsList.length) {
          apiData[contentOption] = {
            op: data.op,
            val: comparisonMapToList(data.val),
          };
        }
        break;
      }
      case InventoryContentOption.INVENTORY:
        if (appendInventories) {
          apiData[TargetableInventoryOption.INCLUDES] = mapInventoriesListToApiFormat(includes);
          apiData[TargetableInventoryOption.EXCLUDES] = mapInventoriesListToApiFormat(excludes);
          if (addIdByInstances)
            apiData[TargetableInventoryOption.ID_BY_INSTANCES] = prepareInstancesById(includes, excludes);
        }
        break;
    }
  });

  const inventoryAndContentKeys = Object.keys(apiData);
  const removeContentInterDimOperator =
    inventoryAndContentKeys.length === 1 &&
    inventoryAndContentKeys[0] === InventoryContentOption.CONTENT_INTER_DIMENSION_OPERATOR;

  if (removeContentInterDimOperator) {
    inventoryAndContentKeys.pop();
  }

  return inventoryAndContentKeys.length === 0 ? null : apiData;
};

export function groupExcludes(excludesList: TargetableInventoryList): TargetableInventoryExcludes {
  const typesToGroup: string[] = [
    TargetableInventoryType.ADUNIT,
    TargetableInventoryType.ADUNITGROUP,
    TargetableInventoryType.SITESECTION,
  ];

  const { itemsToGroup, other } = excludesList.reduce<Record<string, TargetableInventoryList>>(
    (result, element) => {
      if (typesToGroup.includes(element.type)) {
        result.itemsToGroup.push(element);
      } else {
        result.other.push(element);
      }

      return result;
    },
    { itemsToGroup: [], other: [] }
  );

  const grouped = itemsToGroup.reduce<Record<string, TargetableInventoryList>>((result, item) => {
    return { ...result, ['' + item.parent_name]: [...(result['' + item.parent_name] || []), item] };
  }, {});

  return { grouped, other };
}

export const getGroupedLimit = (
  grouped: Record<string, TargetableInventoryList>,
  rowsLimit: number
): { groupedDisplayLimit: number; groupedRowsUsed: number; isMoreGroupedToDisplay: boolean } => {
  let itemsCount = 0;
  let keysCount = 0;
  let groupedRowsUsed = 0;

  for (const key in grouped) {
    keysCount++;
    itemsCount += grouped[key].length;
    groupedRowsUsed = itemsCount + keysCount;

    if (groupedRowsUsed > rowsLimit) {
      const groupedDisplayLimit = keysCount > 1 ? keysCount - 1 : 1;
      return { groupedDisplayLimit, groupedRowsUsed, isMoreGroupedToDisplay: true };
    }
  }

  return { groupedDisplayLimit: keysCount, groupedRowsUsed, isMoreGroupedToDisplay: false };
};

export const getExtendQuery = (includes: TargetableInventoryList): string => {
  if (!includes.length) {
    return '';
  }

  const data: TargetableInventoryEntities = {};

  for (const item of includes) {
    if (TargetableInventoryParentSearch[item.type]) {
      data[item.type] = data[item.type] ? `${data[item.type]} OR ${item.uid}` : item.uid;
    }
  }

  const query = Object.entries(data)
    .map(([type, values]) => {
      if (values.includes('OR')) {
        values = `(${values})`;
      }
      return `${TargetableInventoryParentSearch[type]}:${values}`;
    })
    .join(' OR ');

  return `AND (${query})`;
};

export const splitIncludedExcluded = (
  inventories: TargetableInventoryList[],
  excludes: TargetableInventoryEntities
): inventoryTargetingContent<TargetableInventoryList> => {
  const inventoriesAll = [...inventories].flat();

  const excludedIdsArray: Array<string> = Object.values(excludes).length
    ? Object.values(excludes)
        .map(excludesGroup => excludesGroup.split(','))
        .flat()
    : [];

  const splitedIncludedExcludedObject: inventoryTargetingContent<TargetableInventoryList> = {
    excludes: [],
    includes: [],
  };
  inventoriesAll.forEach(inventory => {
    const key = excludedIdsArray.includes(inventory.id) ? 'excludes' : 'includes';
    splitedIncludedExcludedObject[key] = [...splitedIncludedExcludedObject[key], inventory];
  });

  return splitedIncludedExcludedObject;
};

export const mapByComparison = (opsList: ComparisonList): ComparisonMap => {
  const mappedOps = {};
  opsList.forEach(el => (mappedOps[el.op] = mappedOps[el.op] || []).push(el.val));
  return mappedOps;
};

export const comparisonMapToList = (optionsMap: ComparisonMap): ComparisonList => {
  const opsList: ComparisonList = [];

  Object.entries(optionsMap).forEach(([op, values]) => {
    values &&
      values.forEach(val => {
        opsList.push({
          op: op as ComparisonType,
          val,
        });
      });
  });
  return opsList;
};

export function getBulkPlaceholder(t: TFunction, type: InventoryContentOption, label: string) {
  switch (type) {
    case InventoryContentOption.INSTANCE_HASH:
      return t(
        `Instance Hash may be entered as either comma, semicolon, or new line separated values.

Examples:
Instance_#1,Instance_#2
or
Instance_#1;Instance_#2
or
Instance_#1
Instance_#2`
      );
    case InventoryContentOption.PUBLISHER_ID:
      return t(
        `Publisher ID may be entered as either comma, semicolon, or new line separated values.

Examples:
Publisher_id1,Publisher_id2
or
Publisher_id1;Publisher_id2
or
Publisher_id1
Publisher_id2`
      );
    case InventoryContentOption.ADUNIT:
      return t(
        `Ad Unit ID may be entered as either comma, semicolon, or new line separated values.

Examples:
AdUnit_id1,AdUnit_id2
or
AdUnit_id1;AdUnit_id2
or
AdUnit_id1
AdUnit_id2`
      );
    case InventoryContentOption.SITE:
      return t(
        `Site ID may be entered as either comma, semicolon, or new line separated values.

Examples:
Site_id1,Site_id2
or
Site_id1;Site_id2
or
Site_id1
Site_id2`
      );
    case InventoryContentOption.KEYWORDS:
      return t(
        `Keywords may be entered as either comma, semicolon, or new line separated values.

Examples:
keyword-one,keyword-two
or
keyword-one;keyword-two
or
keyword-one
keyword-two`
      );
    default:
      return t(
        `{label} may be entered as either comma, semicolon, or new line separated values.

Examples:
example.com,site.eu
or
example.com;site.eu
or
example.com
site.eu`,
        { label }
      );
  }
}

export function getTypeInventoryIDsPlaceholder(
  t: TFunction<'translation', undefined>,
  type: InventoryContentIdsTypeOption
) {
  return {
    [InventoryContentOption.INSTANCE_HASH]: t('Enter a Instance Hash and press Enter'),
    [InventoryContentOption.PUBLISHER_ID]: t('Enter a Publisher ID and press Enter'),
    [InventoryContentOption.ADUNIT]: t('Enter a Ad Unit ID and press Enter'),
    [InventoryContentOption.SITE]: t('Enter a Site ID and press Enter'),
    [InventoryContentOption.LINE_ITEM_APP_BUNDLE_ID]: t('Enter a App Bundle ID and press Enter'),
  }[type];
}

export const validateBulkInventoryIds = (
  values: string[],
  currentSet: IntersectOptionPayload,
  type: InventoryContentIdsTypeOption
): Promise<ValidationResult> => {
  const errors: InvalidItemsError[] = [];
  const validItems: string[] = [];
  const inventoryIDsSet = new Set(currentSet.val);

  values.forEach(value => {
    const validationResult = validateSingleRecordSubmissionInventoryIDs(value, inventoryIDsSet, type);

    if (validationResult?.error) {
      const errorObject = errors.find(singleError => singleError.error === validationResult.errorMessage);

      if (errorObject) {
        errorObject.invalidItems.push(value);
      } else {
        errors.push({ error: validationResult.errorMessage, invalidItems: [value] });
      }
    } else {
      validItems.push(value);
    }
  });

  return new Promise<ValidationResult>(res => res({ errors, validItems }));
};

export function validateSingleRecordSubmissionInventoryIDs(
  value = '',
  currentSet: Set<string>,
  type: InventoryContentIdsTypeOption
): { error: boolean; errorMessage: string } | undefined {
  value = value.trim();

  if (!value) {
    return { error: true, errorMessage: 'This field cannot be empty.' };
  }

  const alreadyExists = currentSet.has(value);

  if (alreadyExists) {
    return { error: true, errorMessage: 'This item is already on the list.' };
  }

  if (type === InventoryContentOption.INSTANCE_HASH && !value.match(INSTANCE_HASH_REGEX)) {
    return { error: true, errorMessage: 'Please enter a valid Instance Hash' };
  }

  if (!value.match(ID_REGEX)) {
    switch (type) {
      case InventoryContentOption.PUBLISHER_ID:
        return { error: true, errorMessage: 'Please enter a valid Publisher ID' };
      case InventoryContentOption.SITE:
        return { error: true, errorMessage: 'Please enter a valid Site ID' };
      case InventoryContentOption.ADUNIT:
        return { error: true, errorMessage: 'Please enter a valid Ad Unit ID' };
    }
  }

  currentSet.add(value);
}
