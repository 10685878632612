import { TableRow as MaterialTableRow, TableRowProps as MaterialTableRowProps } from '@mui/material';
import { ReactNode } from 'react';

import { BaseRow, Columns, RowAction } from '@openx/types';

import { HighlightRules } from '../highlightRules';

import { GenericTableCell as TableCell } from './TableCell';

export interface TableRowProps<RowT extends BaseRow> extends MaterialTableRowProps {
  rowData?: RowT;
  onRowClick?: RowAction<RowT>;
  columns: Columns<RowT>;
  highlightRules?: HighlightRules;
  ['data-test']?: string;
  selected?: boolean;
  iconCellRenderer?: (data: RowT) => ReactNode;
  className?: string;
  isClickable?: boolean;
}

export type TableRowPropsWithData<RowT extends BaseRow> = TableRowProps<RowT> & { rowData: RowT };

export function TableRow<RowT extends BaseRow>(props: Readonly<TableRowProps<RowT>>): JSX.Element {
  const { onRowClick, columns, rowData, highlightRules, selected, iconCellRenderer, className, isClickable } = props;
  const onClick = onRowClick && rowData ? e => onRowClick(rowData, e) : undefined;

  return (
    <MaterialTableRow
      onClick={onClick}
      hover
      className={className}
      data-test={props['data-test'] ?? 'table-row'}
      selected={selected}
      sx={{
        cursor: isClickable ? 'pointer' : 'default',
        ...props.sx,
      }}
    >
      {columns.map(column => (
        <TableCell column={column} rowData={rowData} highlightRules={highlightRules} key={column.key} />
      ))}
      {rowData && iconCellRenderer?.(rowData)}
    </MaterialTableRow>
  );
}
