import { AudienceOption } from '@openx/types';

export const AudienceTypeOption = {
  AllAudiences: '1,2,3',
  Over_12: '1,2',
  Over_17: '1',
  Under_13: '3',
  Under_18: '2,3',
} as const;

export const MatchedUsersOption = {
  Matched: '1',
  MatchedByCookie: '3',
  MatchedByEids: '4',
  Unmatched: '5',
} as const;

export type MatchedUsersOptions = (typeof MatchedUsersOption)[keyof typeof MatchedUsersOption];

export const audienceDefaultOptions = {
  availableOptions: [AudienceOption.DMP_SEGMENTS, AudienceOption.LIVEINTENT, AudienceOption.OPEN_AUDIENCES],
  defaultOption: AudienceOption.DMP_SEGMENTS,
};
