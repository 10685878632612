import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { type VideoFormState, VideoOption, VideoSkipabilityPayload, VideoSkipabilityValue } from '@openx/types';

import { SelectedItems, FiltersTitle } from '../../../shared';
import { getVideoSkipabilityOptions } from '../../utils';

type Props = {
  readonly?: boolean;
  params: VideoSkipabilityPayload;
  onChange?: (newData: VideoFormState | ((prevState: VideoFormState) => VideoFormState)) => void;
};

export const VideoSkipabilityItem = ({ params, readonly = true, onChange = () => {} }: Props): JSX.Element => {
  const { t } = useTranslation();

  const videoOptions = useMemo(() => getVideoSkipabilityOptions(t), [t]);
  const { val } = params;

  const handleChange = useCallback(() => {
    onChange(state => ({
      ...state,
      [VideoOption.VIDEO_SKIPABILITY]: null,
    }));
  }, [onChange]);

  return (
    <>
      <FiltersTitle onClear={handleChange} title={t('Video Skippability')} readonly={readonly} />
      <SelectedItems
        isAddIcon={val === VideoSkipabilityValue.SKIPPABLE}
        selectedItems={[val]}
        readonly={readonly}
        handleChange={handleChange}
        options={videoOptions}
        dataTest="skippable-video"
      />
    </>
  );
};
