import { Grid } from '@mui/material';
import type { TFunction } from 'i18next';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { InventoryContentOption, ComparisonType } from '@openx/types';
import { bigNumberWithCommas } from '@openx/utils';
import { removeWhiteSpaces } from '@openx/utils';

import { BulkAddDrawer, InvalidItemsError, ValidationResult, getBulkPlaceholder } from '../../../shared';
import { useInventoryAndContentLabels, INVENTORY_LIMITS } from '../../../shared/InventoryAndContent/constants';
import { InventoryComparisonTypeDropdown } from '../InventoryComparisonTypeDropdown';
import { InventoryContentOptionDropdown } from '../InventoryContentOptionDropdown';

const helperPlaceholders = (limit: number, t: TFunction) => ({
  [InventoryContentOption.APP_BUNDLE_ID]: t(
    'App Bundle ID targeting is limited to {formatLimit} entries and it is case-sensitive.',
    { formatLimit: bigNumberWithCommas(limit) }
  ),
  [InventoryContentOption.PAGE_URL]: t(
    'Page URL targeting is limited to {formatLimit} entries and it is case-sensitive.',
    { formatLimit: bigNumberWithCommas(limit) }
  ),
});

type BulkDrawerProps = {
  contentOption: InventoryContentOption;
  itemsCount: number;
  comparisonFieldCriteria?: Array<string>;
  comparisonType: ComparisonType;
  setContentOption: (type: InventoryContentOption) => void;
  setComparisonType: (type: ComparisonType) => void;
  onBulkAdd: (items: Array<string>) => void;
  onBulkClose: () => void;
  customValidation?: ((list: string[]) => Promise<ValidationResult>) | null;
  parentAccountFeatures: Record<string, boolean>;
};

export const BulkDrawer = ({
  contentOption,
  itemsCount,
  comparisonFieldCriteria = [],
  comparisonType,
  setContentOption,
  setComparisonType,
  onBulkAdd,
  onBulkClose,
  customValidation,
  parentAccountFeatures,
}: BulkDrawerProps): JSX.Element => {
  const { t } = useTranslation();
  const labels = useInventoryAndContentLabels();
  const bulkPlaceholder = getBulkPlaceholder(t, contentOption, labels[contentOption].label);

  const validateBulk = useCallback(
    async (list: Array<string>) => {
      const added: Set<string> = new Set(comparisonFieldCriteria);
      const duplicates: Array<string> = [];
      const validItems: Array<string> = [];

      list.forEach(item => {
        if (contentOption === InventoryContentOption.PAGE_URL) {
          item = removeWhiteSpaces(item);
        }

        if (added.has(item)) {
          duplicates.push(item);
        } else {
          validItems.push(item);
          added.add(item);
        }
      });

      const errors: InvalidItemsError[] = [];

      if (duplicates.length) errors.push({ error: t('Duplicates.'), invalidItems: duplicates });

      return { errors, validItems };
    },
    [comparisonFieldCriteria, t, contentOption]
  );

  const currentLimit = INVENTORY_LIMITS[contentOption] - itemsCount;

  const showComparsionDropdown =
    contentOption !== InventoryContentOption.PUBLISHER_ID &&
    contentOption !== InventoryContentOption.INSTANCE_HASH &&
    contentOption !== InventoryContentOption.ADUNIT &&
    contentOption !== InventoryContentOption.SITE &&
    contentOption !== InventoryContentOption.LINE_ITEM_APP_BUNDLE_ID &&
    contentOption !== InventoryContentOption.KEYWORDS;

  return (
    <BulkAddDrawer
      onApply={onBulkAdd}
      onClose={onBulkClose}
      placeholder={bulkPlaceholder}
      validate={customValidation ? customValidation : validateBulk}
      helperText={helperPlaceholders(currentLimit, t)[contentOption]}
      limit={currentLimit}
      childParams={contentOption}
    >
      <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={6}>
          <InventoryContentOptionDropdown
            value={contentOption}
            onChange={setContentOption}
            isBulk={true}
            parentAccountFeatures={parentAccountFeatures}
          />
        </Grid>
        {showComparsionDropdown && (
          <Grid item xs={6}>
            <InventoryComparisonTypeDropdown
              option={contentOption}
              value={comparisonType}
              onChange={setComparisonType}
            />
          </Grid>
        )}
      </Grid>
    </BulkAddDrawer>
  );
};
