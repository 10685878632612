import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { fetchApiResponseHandler, QueryKeys } from '@openx/utils/api';

export const useKeywordsOptionsFetch = (keyword?: string) => {
  const { t } = useTranslation();

  const { isFetching, data, error } = useQuery({
    meta: {
      errorMessage: t('Failed to request keywords options'),
    },
    queryFn: () =>
      fetchApiResponseHandler(axios.get(`/ocs_keyword_suggestions?query=${keyword}`, { baseURL: '/apigee' })),
    queryKey: [QueryKeys.KEYWORDS, keyword],
    retry: false,
  });

  const keywordsOptions = useMemo(() => {
    return (data || []).reduce((acc, item) => {
      acc[item] = { id: item, name: item };
      return acc;
    }, {});
  }, [data]);

  return {
    error,
    keywordsOptions,
    keywordsOptionsLoading: isFetching,
  };
};
