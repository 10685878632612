import { Close } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactEventHandler } from 'react';

import { Ellipsis } from '../Ellipsis';

import { DrawerDivider } from './DrawerDivider';

export interface DrawerHeaderProps {
  children?: string | JSX.Element;
  onClose?: ReactEventHandler;
  marginBottom?: number;
  dataTest?: string;
}

const StyledContainerDiv = styled('div')<{ marginBottom: number }>`
  margin-bottom: ${({ theme, marginBottom }) => theme.spacing(marginBottom)};
`;

const StyledContentContainerDiv = styled('div')`
  align-items: center;
  display: flex;
  height: 20px;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const StyledIconButton = styled(IconButton)`
  left: ${({ theme }) => theme.spacing(1)};
`;

const StyledTypography = styled(Typography)`
  overflow-x: hidden;
`;

export function DrawerHeader(props: DrawerHeaderProps): JSX.Element {
  const { children, onClose, marginBottom = 2, dataTest } = props;

  return (
    <StyledContainerDiv marginBottom={marginBottom} data-test={dataTest}>
      <StyledContentContainerDiv>
        <StyledTypography variant="h3" data-test="details-title">
          <Ellipsis width="100%" tooltip={true} detectTooltipResize={true}>
            {children}
          </Ellipsis>
        </StyledTypography>
        {onClose && (
          <StyledIconButton data-test="close-drawer" onClick={onClose}>
            <Close />
          </StyledIconButton>
        )}
      </StyledContentContainerDiv>
      <DrawerDivider />
    </StyledContainerDiv>
  );
}
