import { styled, Box } from '@mui/material';
import { omit } from 'lodash';
import { useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from '@openx/components/core';
import { AudienceData, AudienceOption, ComparisonType } from '@openx/types';

import { useTargetingContext } from '../../utils';
import { SelectedItems, FiltersTitle, Switch, DimensionChipLine } from '../shared';
import { TargetingItemsProps } from '../types';

import { AudienceItemWithStatus, OaAudienceItem } from './components';
import { EidsItems } from './components';
import { getAudienceTypeLabelsMap, getMatchedUsersLabelsMap } from './utils';

const StyledWrapper = styled(Box)`
  margin-left: ${({ theme }) => theme.spacing(3.5)};
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

export const Items = ({
  targetingParams,
  readonly,
  accountUid,
  onChange = () => {},
  onAllAnyOperatorChange = () => {},
}: TargetingItemsProps<AudienceData | null> & {
  accountUid: string;
  onAllAnyOperatorChange?: () => void;
}) => {
  const { t } = useTranslation();
  const { isOa, useAudienceOptionsDetailsFetch } = useTargetingContext();

  const selectedAudience = omit(targetingParams, [AudienceOption.INTENDED_AUDIENCE]);
  const selectedAudienceType = targetingParams?.[AudienceOption.INTENDED_AUDIENCE];
  const selectedTrafficFilterEids = selectedAudience[AudienceOption.TRAFFIC_FILTER_EIDS];
  const selectedTrafficFilterEidsArray = useMemo(
    () => [...(selectedTrafficFilterEids || [])],
    [selectedTrafficFilterEids]
  );

  const selectedAudienceOption = useMemo(() => {
    return selectedAudience && Object.keys(selectedAudience).length > 0
      ? (Object.keys(selectedAudience)[0] as AudienceOption)
      : null;
  }, [selectedAudience]);

  const getSelectedItems = () => {
    if (selectedAudienceOption && selectedAudience?.[selectedAudienceOption]) {
      const selectedOption = selectedAudience[selectedAudienceOption];
      const val = selectedOption?.val;

      if (val) {
        return val.split(',');
      }

      if (typeof selectedOption === 'string') {
        return [selectedOption];
      }
    }

    return [];
  };

  const { options, isLoading, refetch } = useAudienceOptionsDetailsFetch(
    accountUid,
    selectedAudienceOption,
    getSelectedItems()
  );

  const audienceTypeLabels = useMemo(() => getAudienceTypeLabelsMap(t), [t]);

  useEffect(() => {
    if (refetch && selectedAudienceOption !== AudienceOption.LIVEINTENT) {
      refetch();
    }
  }, [refetch, selectedAudienceOption]);

  const handleClearAudienceType = useCallback(() => {
    onChange(params => ({ ...params, [AudienceOption.INTENDED_AUDIENCE]: undefined }));
  }, [onChange]);

  const handleClearAudienceOptions = useCallback(() => {
    onChange(params => ({ [AudienceOption.INTENDED_AUDIENCE]: params?.[AudienceOption.INTENDED_AUDIENCE] }));
  }, [onChange]);

  const handleChange = useCallback(
    (values: string[]) => {
      if (selectedAudienceOption !== AudienceOption.DMP_SEGMENTS || values.length === 0) {
        handleClearAudienceOptions();
        return;
      }

      if (selectedAudience) {
        onChange({
          [selectedAudienceOption as AudienceOption]: {
            ...selectedAudience[selectedAudienceOption],
            val: values.join(','),
          },
        });
      }
    },
    [handleClearAudienceOptions, onChange, selectedAudience, selectedAudienceOption]
  );

  const title = useMemo(
    () => ({
      [AudienceOption.DMP_SEGMENTS]: t('3rd Party DMP'),
      [AudienceOption.INTENDED_AUDIENCE]: t('Intended Audience'),
      [AudienceOption.LIVEINTENT]: t('LiveIntent'),
      [AudienceOption.MATCHED_USERS]: t('Matched Users'),
      [AudienceOption.OPEN_AUDIENCES]: t('OpenAudience'),
      [AudienceOption.TRAFFIC_FILTER_EIDS]: t('Matched Users'),
    }),
    [t]
  );

  const optionsMap = useMemo(() => {
    if (selectedAudienceOption === AudienceOption.MATCHED_USERS) {
      const matchedUsersLabels = getMatchedUsersLabelsMap(t);
      return matchedUsersLabels;
    }
    if (selectedAudienceOption && selectedAudienceOption !== AudienceOption.LIVEINTENT) {
      return options;
    }

    return undefined;
  }, [options, t, selectedAudienceOption]);

  if (isLoading) {
    return <Loader />;
  }

  const filtersTitle = selectedAudienceOption !== AudienceOption.LIVEINTENT ? t('Included') : t('Equals');

  const allAnySwitchState = selectedAudienceOption
    ? selectedAudience?.[selectedAudienceOption]?.op !== ComparisonType.INVERSE_IN
    : false;

  let DefaultItemComponent = selectedAudienceOption === 'openaudience_custom' ? AudienceItemWithStatus : undefined;
  DefaultItemComponent = readonly && isOa ? OaAudienceItem : DefaultItemComponent;
  const isReadOnly = readonly ?? true;

  return (
    <div data-test="sub-section">
      {selectedAudienceOption && (
        <>
          <FiltersTitle
            onClear={handleClearAudienceOptions}
            title={title[selectedAudienceOption]}
            readonly={isReadOnly}
          />

          {selectedAudienceOption === AudienceOption.DMP_SEGMENTS && (
            <Switch
              switchState={allAnySwitchState}
              onSwitchChange={onAllAnyOperatorChange}
              switchOffLabel={t('ALL')}
              switchOnLabel={t('ANY')}
              groupTitle={t('THE FOLLOWING:')}
              disabled={isReadOnly}
            />
          )}

          <SelectedItems
            filtersTitle={filtersTitle}
            isAddIcon
            selectedItems={getSelectedItems()}
            ItemComponent={DefaultItemComponent}
            readonly={isReadOnly}
            handleChange={handleChange}
            options={optionsMap}
            dataTest={selectedAudienceOption.replace('_', '-')}
          />

          {!!selectedTrafficFilterEidsArray.length && (
            <StyledWrapper>
              <EidsItems
                selectedTrafficFilterEidsArray={selectedTrafficFilterEidsArray}
                readonly={isReadOnly}
                onChange={onChange}
              />
            </StyledWrapper>
          )}
        </>
      )}

      {!!selectedAudienceType && (
        <>
          {selectedAudienceOption && <DimensionChipLine />}

          <FiltersTitle
            onClear={handleClearAudienceType}
            title={title[AudienceOption.INTENDED_AUDIENCE]}
            readonly={true}
          />

          <SelectedItems
            filtersTitle={t('Included')}
            isAddIcon
            selectedItems={[selectedAudienceType.val]}
            readonly={true}
            options={audienceTypeLabels}
            handleChange={handleClearAudienceType}
            dataTest="selected-audience-type"
          />
        </>
      )}
    </div>
  );
};
