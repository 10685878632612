import { styled } from '@mui/material';

const StyledSvg = styled('div')`
  & > svg {
    margin-right: ${({ theme }) => theme.spacing(1)};

    & path {
      fill: ${({ theme }) => theme.palette.success.main};
    }
  }
`;

export const LeafIcon = (): JSX.Element => {
  return (
    <StyledSvg>
      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
        <path d="M450-89q-32.511 0-65.515-7.37-33.004-7.371-67.485-21.13 6-118 64.25-219.5T529-516q-106.5 55-184.25 144.5T239-166.5q-4.533-3.321-8.5-7.196L222-182q-45.531-45.702-69.266-102.101Q129-340.5 129-402.018q0-66.4 25.75-126.941T228-636q78.013-78.196 202.257-101.848Q554.5-761.5 785-743q17.5 232-5.844 354.467Q755.812-266.066 678-188q-48 48-106.865 73.5Q512.27-89 450-89Z" />
      </svg>
    </StyledSvg>
  );
};
