import { styled } from '@mui/material';

export const Container = styled('div')`
  position: absolute;
  top: 45%;
  left: 10%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-grow: 1;
`;
