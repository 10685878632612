import { Button } from '@mui/material';

import { Background } from 'components/Background';
import { Logo } from 'components/Logo';

import { Container, SubtitleTypography, TitleTypography } from './components';
import { useGetErrorProps } from './hooks';

type ErrorComponentProps = {
  errorType?: 404 | 500;
};

export const ErrorPage = ({ errorType = 500 }: ErrorComponentProps): JSX.Element => {
  const { title, subtitle, navigateTo, buttonLabel } = useGetErrorProps(errorType);

  return (
    <Background>
      <Logo />
      <Container data-test="error-container">
        <TitleTypography>{title}</TitleTypography>
        <SubtitleTypography>{subtitle}</SubtitleTypography>
        <Button data-test="error-action-button" variant="contained" onClick={navigateTo}>
          {buttonLabel}
        </Button>
      </Container>
    </Background>
  );
};
