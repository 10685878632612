import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from '@openx/components/core';
import { CategoryFormState, Intersect } from '@openx/types';

import { useTargetingContext } from '../../utils';
import { SelectedItems, Switch, FiltersTitle, EmptyFilterMessage } from '../shared';
import { TargetingItemsProps } from '../types';

export const CategoryItems = ({
  targetingParams,
  readonly = true,
  onChange = () => {},
}: TargetingItemsProps<CategoryFormState>) => {
  const { t } = useTranslation();
  const { useCategoriesFetch } = useTargetingContext();

  const { categories, refetchCategories, isCategoriesLoading } = useCategoriesFetch();

  const { op, val } = targetingParams;

  const typeLabel = op === Intersect.NOT_INTERSECTS ? t('blocked') : t('allowed');
  const filtersTitle = val.size ? typeLabel : '';

  const switchState = op === Intersect.NOT_INTERSECTS;

  useEffect(() => {
    refetchCategories();
  }, [refetchCategories]);

  const handleChange = useCallback(
    (values: string[]) => {
      const selected = new Set(values);

      onChange({
        op,
        val: selected,
      });
    },
    [onChange, op]
  );

  const selectedCategories = useMemo(() => [...val], [val]);

  if (isCategoriesLoading) {
    return <Loader />;
  }

  return val.size !== 0 ? (
    <div data-test="sub-section">
      <FiltersTitle
        onClear={() => handleChange([])}
        title={t('Category [ {count} ]', { count: val.size })}
        readonly={readonly}
      />
      {!readonly && (
        <Switch
          switchState={switchState}
          onSwitchChange={() =>
            onChange({
              op: op === Intersect.INTERSECTS ? Intersect.NOT_INTERSECTS : Intersect.INTERSECTS,
              val,
            })
          }
          switchOffLabel={t('allow')}
          switchOnLabel={t('block')}
          groupTitle={t('the following:')}
        />
      )}
      <SelectedItems
        filtersTitle={filtersTitle}
        isAddIcon={!switchState}
        selectedItems={selectedCategories}
        readonly={readonly}
        handleChange={handleChange}
        options={categories}
        dataTest="categories"
      />
    </div>
  ) : (
    <EmptyFilterMessage text={t('No filters applied.')} />
  );
};
