import { useTranslation } from 'react-i18next';

import { AutoComplete } from '@openx/components/core';
import { GeoLocationTypes } from '@openx/types';

import { geographicLabels } from '../constants';

type GeographicTypeProps = {
  onChange: (type: GeoLocationTypes) => void;
  value: GeoLocationTypes;
};

export const GeographicType = ({ onChange, value }: GeographicTypeProps) => {
  const { t } = useTranslation();

  return (
    <AutoComplete
      textFieldProps={{ label: t('Type') }}
      options={Object.keys(geographicLabels)}
      renderOptions={{
        dataTest: 'geographic-type-option',
      }}
      getOptionLabel={option => t(geographicLabels[option])}
      textFieldReadOnly
      disableClearable
      filterOptions={option => option}
      onChange={(_, value) => onChange(value as GeoLocationTypes)}
      value={value}
      data-test="geographic-type"
    />
  );
};
