import { Box } from '@mui/system';

interface IndentedSectionProps {
  children: React.ReactNode;
  customColor?: string;
  dataTest?: string;
  sx?: any;
}

const defaultStyles = customColor => ({
  '&:before': {
    bgcolor: customColor ?? 'action.disabled',
    content: '""',
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '2px',
  },
  marginBottom: 0,
  marginTop: 1,
  paddingLeft: 1.5,
  position: 'relative',
});

export const IndentedSections = ({ children, customColor, dataTest = '', ...props }: IndentedSectionProps) => {
  return (
    <Box sx={[defaultStyles(customColor), props.sx]} data-test={dataTest}>
      {children}
    </Box>
  );
};
