import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { FieldDetails, RHFCheckboxField } from '@openx/components/core';

import { TargetingFields } from '../../types';

const StyledGridCheckbox = styled(Grid)`
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
  padding-left: ${({ theme }) => theme.spacing(0.5)};
`;

type ExcludeMFAProps = {
  excludeMFAValue: boolean;
  onFieldUpdate?: (newData: boolean) => void;
  readonly?: boolean;
};

export const ExcludeMFA = ({
  excludeMFAValue,
  onFieldUpdate = () => {},
  readonly,
}: ExcludeMFAProps): JSX.Element | null => {
  const { t } = useTranslation();

  if (readonly) {
    return excludeMFAValue ? (
      <FieldDetails title={t('Exclude MFA Inventory')} checkbox value={excludeMFAValue} />
    ) : null;
  }

  return (
    <StyledGridCheckbox display={'block'} item xs={12}>
      <RHFCheckboxField
        name={`targeting.${TargetingFields.METACATEGORY}.${TargetingFields.EXCLUDE_MFA}`}
        label={t('Exclude MFA Inventory')}
        onChange={() => {
          onFieldUpdate(!excludeMFAValue);
        }}
        data-test="exclude-mfa-inventory"
      />
    </StyledGridCheckbox>
  );
};
