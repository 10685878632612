import axios from 'axios';
import qs from 'query-string';

import { OptionsList, ParameterizedOptionsType } from '@openx/types';

import { fetchApiResponseHandler } from '../../api/utils';

import { OptionsTypeWithParams } from './actions';

const maxAge = 24 * 60 * 60 * 1000;
const requestOptions = {
  headers: {
    'Cache-Control': `max-age=${maxAge}`,
  },
};

const optionsEndpoints = {
  [ParameterizedOptionsType.DEMAND_PARTNER_BUYER_SEATS]: '/options/demand_partner_buyer_seat_options',
  [ParameterizedOptionsType.DEMAND_PARTNER_BUYER]: '/options/demand_partner_buyer_options',
  [ParameterizedOptionsType.TARGETABLE_PACKAGE]: '/options/targetable_package_options',
  [ParameterizedOptionsType.THIRD_PARTY_PARTNERS]: '/options/third_party_partners_options',
  [ParameterizedOptionsType.PUBLISHERS]: '/options/publisher_options',
  [ParameterizedOptionsType.PUBLISHERS_BY_INSTANCE]: '/options/publisher_options',
  [ParameterizedOptionsType.DMP_SEGMENTS]: '/options/targetable_audiencesegment_options',
  [ParameterizedOptionsType.OPEN_AUDIENCES]: '/options/targetable_openaudience_custom_segment_options',
  [ParameterizedOptionsType.ACCOUNT_EXECUTIVE]: '/options/targetable_account_executive_options',
  [ParameterizedOptionsType.ACCOUNT_MANAGER]: '/options/targetable_account_manager_options',
  [ParameterizedOptionsType.ORDER]: '/options/order_options',
};

export function fetchOptions(optionsType: OptionsTypeWithParams): Promise<OptionsList> {
  const requestUrl = `${optionsEndpoints[optionsType.type]}?${qs.stringify(optionsType.params)}`;
  return fetchApiResponseHandler(axios.get(requestUrl, requestOptions));
}
