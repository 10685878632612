import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const StyledContainerDiv = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(7, 0)};
`;

interface PlaceholderProps {
  /** Optional title */
  title?: string;
  /** Optional subtitle */
  subtitle?: string;
}

export const Placeholder = ({ title, subtitle, ...restProps }: PlaceholderProps): JSX.Element => {
  return (
    <StyledContainerDiv {...restProps} data-test="placeholder">
      {title && <Typography variant="h3">{title}</Typography>}
      {subtitle && (
        <Typography variant="body1" color="textSecondary">
          {subtitle}
        </Typography>
      )}
    </StyledContainerDiv>
  );
};
