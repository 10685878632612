import { useTranslation } from 'react-i18next';

import { Criteria } from '@openx/types';

export function useGetDimensionLabels() {
  const { t } = useTranslation();

  const dimensionLabels = {
    [Criteria.ALL]: t('AND'),
    [Criteria.ANY]: t('OR'),
  };

  return { dimensionLabels };
}
