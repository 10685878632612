import { useTranslation } from 'react-i18next';

import { ComparisonType, TechnologyAndDevicesFormState, TechnologyAndDevicesOption } from '@openx/types';

import { SelectedItems } from '../../shared';
import { getCookiesLabelsMap, getDeviceIdLabelsMap, getHttpsLabelsMap } from '../utils';

type SelectedItemsSingleOptionProps = {
  filtersTitle: string;
  selectedItems: TechnologyAndDevicesFormState;
  readonly: boolean;
  handleChange: (values: string[]) => void;
  dataTest: string;
  selectedType: TechnologyAndDevicesOption;
};

export const SelectedItemsSingleOption = ({
  filtersTitle,
  selectedItems,
  readonly,
  handleChange,
  dataTest,
  selectedType,
}: SelectedItemsSingleOptionProps) => {
  const { t } = useTranslation();
  const cookiesOptionsLabels = getCookiesLabelsMap(t);
  const deviceIdOptionsLabels = getDeviceIdLabelsMap(t);
  const httpsOptionsLabels = getHttpsLabelsMap(t);
  const { op } = selectedItems[selectedType];

  const optionsMap = {
    [TechnologyAndDevicesOption.DEVICE_ID]: deviceIdOptionsLabels,
    [TechnologyAndDevicesOption.HTTPS]: httpsOptionsLabels,
    [TechnologyAndDevicesOption.MKT_COOKIES]: cookiesOptionsLabels,
  };

  return (
    <SelectedItems
      filtersTitle={filtersTitle}
      isAddIcon={op === ComparisonType.EQUALS}
      selectedItems={[op]}
      readonly={readonly}
      handleChange={handleChange}
      options={optionsMap[selectedType]}
      dataTest={dataTest}
    />
  );
};
