import { Alert, Box, Typography } from '@mui/material';
import { useCallback, useMemo, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from '@openx/components/core';
import { GeographicTargetingApi } from '@openx/types';

import { CustomOptionsProvider } from '../../utils/hooks/useCustomOptionsContext';
import { TargetingDrawer, useDrawer, EmptyFilterMessage } from '../shared';
import { TargetingProps } from '../types';

import { geographicDefaultOptions, getDefaultGeographicState } from './constants';
import { GeographicItems } from './GeographicItems';
import { GeographicReadOnly } from './GeographicReadOnly';
import { GeographicTopBox } from './GeographicTopBox';
import { useExtractAudienceExportType, useGetGeoState } from './hooks';
import { mapGeographicStateToApi, isGeoEmpty } from './utils';

export const Geographic = memo(function Geographic({
  targetingParams,
  onFieldUpdate = () => {},
  name,
  readonly,
  field,
  isDisabled,
  customOptions = geographicDefaultOptions,
}: TargetingProps<GeographicTargetingApi>) {
  const { t } = useTranslation();
  const { geographicBaseState, geographicState, isDataUpdated, setGeographicBaseState, setGeographicState } =
    useGetGeoState(targetingParams);
  const { isOpen, onDrawerClose, onDrawerOpen, onDrawerApply } = useDrawer({
    onApply: () => {
      onFieldUpdate(field, mapGeographicStateToApi(geographicState));
      setGeographicBaseState(geographicState);
    },
    restoreParams: () => geographicBaseState && setGeographicState(geographicBaseState),
  });

  const { useAudienceExportType } = useExtractAudienceExportType(readonly);

  const { isUsOnly: isAudienceUsOnly } = useAudienceExportType();

  const onRemoveAll = useCallback(() => {
    onFieldUpdate(field, null);
    setGeographicBaseState(getDefaultGeographicState());
    setGeographicState(getDefaultGeographicState());
  }, [onFieldUpdate, field, setGeographicBaseState, setGeographicState]);

  const isEmpty = useMemo(() => isGeoEmpty(geographicState), [geographicState]);

  if (!geographicBaseState) {
    return <Loader />;
  }

  return (
    <CustomOptionsProvider value={customOptions}>
      <GeographicReadOnly
        name={name}
        readonly={readonly}
        targetingParams={geographicBaseState}
        onOpenDrawerClick={onDrawerOpen}
        onRemoveClick={onRemoveAll}
        isDisabled={isDisabled}
      />
      {isOpen && (
        <TargetingDrawer name={name} onClose={onDrawerClose} onApply={onDrawerApply} isDataUpdated={isDataUpdated}>
          <GeographicTopBox targetingParams={geographicState} onChange={setGeographicState} />

          {isAudienceUsOnly && (
            <Box marginTop={2}>
              <Alert variant="standard" severity="info" style={{ width: '100%' }} data-test="location-alert">
                <Typography variant="body1">
                  {t('Audiences using OA Match or Bidstream Plus export types can only be targeted to US locations.')}
                </Typography>
              </Alert>
            </Box>
          )}

          {isEmpty ? (
            <EmptyFilterMessage text={t('No filters applied.')} />
          ) : (
            <GeographicItems targetingParams={geographicState} readonly={false} onChange={setGeographicState} />
          )}
        </TargetingDrawer>
      )}
    </CustomOptionsProvider>
  );
});
